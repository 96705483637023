import React from "react";
import PropTypes from "prop-types";

const CustomControl = ({ icon, setSelectedControl, toggleFunction }) => {
  return (
    <div
      onClick={() => {
        if (setSelectedControl) {
          setSelectedControl();
        }

        toggleFunction();
      }}
      className="custom-control"
    >
      <img src={icon} alt={icon}></img>
    </div>
  );
};

CustomControl.propTypes = {
  icon: PropTypes.string,
  setSelectedControl: PropTypes.func,
  toggleFunction: PropTypes.func,
};

export default CustomControl;
