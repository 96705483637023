import React from "react";

import { PropTypes } from "prop-types";

import raportElementIcon from "../../../assets/icons/Panel/Raports/raportElementIcon.svg";
import { FormattedMessage, useIntl } from "react-intl";

const RaportElement = ({
  id,
  text,
  setFormData,
  raportElementIndex,
  setRaportElementIndex,
  setDescriptionLngId,
}) => {
  const allMessages = useIntl().messages;
  const specificMessage = allMessages[id];

  const handleClick = () => {
    setDescriptionLngId(id);

    setFormData((prevValues) => ({
      ...prevValues,
      raportName: specificMessage,
    }));

    setRaportElementIndex(id);
  };

  return (
    <div
      onClick={handleClick}
      // w-fit
      className=" w-fit max-w-[49%] lg:w-full  xl:max-w-[301px]  h-[58px] xl:h-[75px] flex items-center justify-start gap-3 px-2 lg:px-5 border  rounded-md hover:opacity-75 cursor-pointer"
      style={{
        borderColor: raportElementIndex === id ? "#E60000" : "#EAF1F4",
      }}
    >
      <img src={raportElementIcon}></img>
      <h3 className=" text-blackPrimary leading-[14px] xs:leading-[18px] text-[11px] xs:text-extraSmall lg:text-text15">
        <FormattedMessage id={text}></FormattedMessage>
      </h3>
    </div>
  );
};

RaportElement.propTypes = {
  text: PropTypes.string,
  id: PropTypes.number,
  setFormData: PropTypes.func,
  raportElementIndex: PropTypes.number,
  setRaportElementIndex: PropTypes.func,
  setDescriptionLngId: PropTypes.func,
};

export default RaportElement;
