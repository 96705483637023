import React, { useState } from "react";
import dayjs from "dayjs";

import { IoIosArrowDown } from "react-icons/io";
import hamburgerIcon from "../../../assets/icons/Panel/maintenance/hamburgerIcon.svg";

import { useDispatch, useSelector } from "react-redux";
import {
  setMonthIndex,
  toggleSmallCalendar,
} from "../../../store/calendar/calendarSlice.js";

const monthsArray = [
  "January",
  "Feb",
  "Mars",
  "April",
  "May",
  "June",
  "July",
  "August",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const CalendarHeader = () => {
  const [toggleSelectMonth, setToggleSelectMonth] = useState(false);
  const [selectedMonthTitle, setSelectedMonthTitle] = useState("Month");
  const dispatch = useDispatch();
  const monthIndex = useSelector((state) => state.calendar.monthIndex);

  const toggleMonthSelection = () => {
    setToggleSelectMonth((prevValue) => !prevValue);
  };

  const handleMonthSelection = (index) => {
    dispatch(setMonthIndex(index));

    setSelectedMonthTitle(monthsArray[index]);
  };

  const handleSmallCalendarState = () => {
    dispatch(toggleSmallCalendar());
  };

  return (
    <header className="px-2 md:px-5 py-2 flex gap-2 items-center">
      <img
        src={hamburgerIcon}
        alt="hamburgerIcon"
        className="cursor-pointer active:opacity-70 hidden md:flex"
        onClick={handleSmallCalendarState}
      />

      <h2 className=" md:ml-4 text-h2 text-blackPrimary font-medium">
        {dayjs(new Date(dayjs().year(), monthIndex)).format("MMMM YYYY")}
      </h2>

      <div
        className="border rounded-md p-2 ml-3 flex items-center gap-1 relative"
        onClick={toggleMonthSelection}
      >
        <span>{selectedMonthTitle}</span>
        <IoIosArrowDown />

        {toggleSelectMonth && (
          <div className="flex flex-col absolute top-12 left-0 right-0 bg-white border z-[9999999999999999]">
            {monthsArray.map((item, index) => (
              <button
                type="button"
                key={index}
                className={`p-1 text-left hover:bg-blue-200 ${
                  monthIndex === index && "bg-red-500"
                }`}
                onClick={() => handleMonthSelection(index)}
              >
                {item}
              </button>
            ))}
          </div>
        )}
      </div>
    </header>
  );
};

export default CalendarHeader;
