import React from "react";
import { PropTypes } from "prop-types";
import { FormattedMessage } from "react-intl";

const DescriptionBox = ({ descriptionLngId, hidden, visibility, margin }) => {
  return (
    <div className={`raportDetailsContainer ${hidden} ${visibility} ${margin}`}>
      <h3 className=" text-text16 text-blackPrimary">
        <FormattedMessage id="raports.createGroup1textareaTitle"></FormattedMessage>
      </h3>
      <h3 className=" text-extraSmall xs:text-regularSize sm:text-text16 text-textSecondary leading-4">
        <FormattedMessage id={descriptionLngId}></FormattedMessage>
      </h3>
    </div>
  );
};

DescriptionBox.propTypes = {
  descriptionLngId: PropTypes.string,
  hidden: PropTypes.string,
  visibility: PropTypes.string,
  margin: PropTypes.string,
};

export default DescriptionBox;
