import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentLanguage: "en",
};

const languageSlice = createSlice({
  name: "language",
  initialState,

  reducers: {
    languageChange: (state, action) => {
      state.currentLanguage = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { languageChange } = languageSlice.actions;

export default languageSlice.reducer;
