import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { store, persistor } from "./store/store.js";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

// Import dayjs and the necessary plugins/locales
import dayjs from "dayjs";
import calendar from "dayjs/plugin/calendar";
import updateLocale from "dayjs/plugin/updateLocale";
import "dayjs/locale/en"; // English
import "dayjs/locale/sq"; // Albanian

// Extend dayjs with the plugin for time
dayjs.extend(calendar);

// Extend dayjs with the plugin for localize
dayjs.extend(updateLocale);

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>
);
