/* eslint-disable indent */
/* eslint-disable no-nested-ternary */
/* eslint-disable complexity */
/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";

import Table from "../Components/table/Table";
import ReusableBtn from "../../../components/ReusableBtn/ReusableBtn";
import PageTitle from "../../../components/Layout/PageTitle";

import { UserGroupDetailTitles, UserGroupTable } from "../../../constants";

import plusIcon from "../../../assets/icons/Panel/administration/Assets/plus-icon.svg";
import fileIcon from "../../../assets/icons/Panel/administration/Assets/file-icon.svg";
import funnelIcon from "../../../assets/icons/Panel/administration/Assets/funnel-icon.svg";
import discardIcon from "../../../assets/icons/Panel/administration/Assets/discardIcon.svg";
import editIconWhite from "../../../assets/icons/Panel/administration/AssetGroup/editIconWhite.svg";
import dotsIconWhite from "../../../assets/icons/Panel/administration/TableIcons/dotsIconWhite.svg";
import DotsWindow from "../Components/table/DotsWindow";
import CreateUserGroup from "./Components/CreateUserGroup";
import ViewAsset from "../Components/ViewAsset/ViewAsset";
import { useWindowDimensions } from "../../../utils/GetDimensions";

const bodyData = [
  {
    id: 0,
    data: [
      {
        id: 0,
        row: "Group 1",
      },
      {
        id: 1,
        row: "Ariol",
      },
      {
        id: 2,
        row: "Admin",
      },
      {
        id: 3,
        row: "lorela.parruca1@vodafone.com",
      },
    ],
  },
  {
    id: 1,
    data: [
      {
        id: 0,
        row: "Group 2",
      },
      {
        id: 1,
        row: "Joris",
      },
      {
        id: 2,
        row: "Super Admin",
      },
      {
        id: 3,
        row: "joris.shkurti@princesha.com",
      },
    ],
  },
];

const UserGroups = () => {
  const [view, setView] = useState("table");
  const [formData, setFormData] = useState({
    name: "",
    shortName: "",
    description: "",
  });
  const [isChecked, setIsChecked] = useState({
    all: false,
  });
  const [rowData, setRowData] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [showVersion3Window, setShowVersion3Window] = useState(false);
  const { width, height } = useWindowDimensions();

  const clearChecked = () => {
    setIsChecked({
      all: false,
    });

    setSelectedRows([]);
  };

  const onSubmit = () => {
    console.log("this is formData:", formData);
  };

  return (
    <div className="pageLayout">
      <div className="w-full flex flex-col gap-2 lg:gap-0 lg:flex-row lg:justify-between lg:items-center">
        <PageTitle
          titleLngId={
            view === "create"
              ? "userGroup.createNewUserTitle"
              : view === "view"
              ? "userGroup.viewUserDetails"
              : view === "edit"
              ? "userGroup.userDetailsEdit"
              : "userGroup.pageTitle"
          }
          subTitleLngId={"users.subtitle1"}
          subTitle2LngId={"userGroup.pageTitle"}
          state={view}
          setState={() => setView("table")}
        ></PageTitle>

        <div className=" flex items-center gap-2 mb-4 lg:mb-0">
          <div className="flex items-center gap-3 justify-between sm:justify-normal sm:px-2">
            {selectedRows.length > 0 && (
              <span className=" text-regularSize text-blackPrimary tracking-wide relative">
                {selectedRows.length}{" "}
                <FormattedMessage id="users.dotsText"></FormattedMessage>
                {showVersion3Window && (
                  <DotsWindow
                    version={3}
                    top={40}
                    left={-30}
                    setRowData={setRowData}
                    data={selectedRows}
                  ></DotsWindow>
                )}
              </span>
            )}

            <div className="flex gap-3">
              {view === "table" && selectedRows.length > 0 && (
                <ReusableBtn
                  icon={dotsIconWhite}
                  height={"45px"}
                  width={"45px"}
                  background={"bg-darkGray2"}
                  paddingHorizontal={"px-3"}
                  type={"button"}
                  handleClick={() =>
                    setShowVersion3Window((prevValue) => !prevValue)
                  }
                ></ReusableBtn>
              )}

              {view === "table" && selectedRows.length > 0 && (
                <ReusableBtn
                  icon={plusIcon}
                  height={"45px"}
                  width={"45px"}
                  background={"bg-darkGray2"}
                  paddingHorizontal={"px-3"}
                  type={"button"}
                  imgWidth={"15px"}
                  rotate={"45deg"}
                  handleClick={clearChecked}
                ></ReusableBtn>
              )}
            </div>
          </div>

          <div
            className="flex gap-2"
            style={{
              display: selectedRows.length > 0 && width < 1024 && "none",
            }}
          >
            {view === "create" && (
              <ReusableBtn
                icon={discardIcon}
                text={
                  <FormattedMessage id="assets.createAssetBtn1"></FormattedMessage>
                }
                textSize={"text-filterBtn"}
                textColor={"text-[#0D0D0D]"}
                width={"100px"}
                maxWidth={""}
                height={"40px"}
                background={"bg-transparent"}
                gap={7}
                paddingHorizontal={"px-1"}
                type={"button"}
                border={"1px solid #0D0D0D"}
                handleClick={() => setView("table")}
              ></ReusableBtn>
            )}

            <ReusableBtn
              icon={
                view === "create"
                  ? ""
                  : view === "view"
                  ? editIconWhite
                  : plusIcon
              }
              imgWidth={"14px"}
              text={
                <FormattedMessage
                  id={
                    view === "create"
                      ? "userGroup.btn1"
                      : view === "table"
                      ? "userGroup.btn1"
                      : "users.userEditBtn"
                  }
                ></FormattedMessage>
              }
              textSize={"text-filterBtn"}
              textColor={"text-[#FFFFFF]"}
              width={"fit-content"}
              maxWidth={""}
              height={view === "create" ? "40px" : "45px"}
              background={"bg-redPrimary"}
              gap={10}
              paddingHorizontal={"px-4"}
              type={"button"}
              handleClick={
                view === "create"
                  ? onSubmit
                  : view === "view"
                  ? () => console.log("edit page")
                  : () => setView("create")
              }
            ></ReusableBtn>

            {view === "table" && (
              <ReusableBtn
                icon={fileIcon}
                height={"45px"}
                background={"bg-white"}
                paddingHorizontal={"px-3"}
                type={"button"}
                handleClick={() => console.log("")}
              ></ReusableBtn>
            )}

            {view === "table" && (
              <ReusableBtn
                icon={funnelIcon}
                text={"(81)"}
                textColor={"text-textSecondary"}
                gap={4}
                height={"45px"}
                background={"bg-white"}
                paddingHorizontal={"px-3"}
                type={"button"}
                handleClick={() => console.log("")}
              ></ReusableBtn>
            )}
          </div>
        </div>
      </div>

      {view === "create" && (
        <CreateUserGroup
          formData={formData}
          setFormData={setFormData}
        ></CreateUserGroup>
      )}

      {view === "view" && (
        <ViewAsset
          titles={UserGroupDetailTitles}
          data={rowData}
          titleLngId={"userGroup.userDetailsText"}
        ></ViewAsset>
      )}

      {view === "edit" && <div>Edit</div>}

      {view === "table" && (
        <Table
          version={2}
          headerData={UserGroupTable}
          bodyData={bodyData}
          setState={setView}
          setRowData={setRowData}
          setSelectedRows={setSelectedRows}
          selectedRows={selectedRows}
          isChecked={isChecked}
          setIsChecked={setIsChecked}
        ></Table>
      )}
    </div>
  );
};

export default UserGroups;
