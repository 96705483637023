import React from "react";
import { PropTypes } from "prop-types";

import ReusableBtn from "../ReusableBtn/ReusableBtn";

import { FormattedMessage } from "react-intl";

import logoutIcon from "../../assets/icons/Panel/Logout/logoutIconsvg.svg";
import closeIcon from "../../assets/icons/Panel/Logout/close-icon.svg";

const Logout = ({ toggleLogout }) => {
  return (
    <div className="logout-container">
      <div className="logout-popup">
        <div className="logout-header">
          <h1 className=" flex-1 text-center">Log out</h1>
          <img
            src={closeIcon}
            className=" size-5 cursor-pointer opacity-70 active:opacity-50"
            onClick={toggleLogout}
          ></img>
        </div>

        <div className="logout-body">
          <img src={logoutIcon}></img>
          <p className=" text-[#333333]">
            <FormattedMessage id="logout.text2"></FormattedMessage>
          </p>
        </div>

        <div className="logout-footer">
          <ReusableBtn
            width={"100%"}
            maxWidth={"362px"}
            height={"48px"}
            text={<FormattedMessage id="logout.btn1"></FormattedMessage>}
            textSize={"text-btnTextSize"}
            textColor={"text-[#333333]"}
            background={"bg-transparent"}
            type={"button"}
            paddingHorizontal
            marginHorizontal
            margin
            border={"1px solid #707070"}
            handleClick={toggleLogout}
          ></ReusableBtn>
          <ReusableBtn
            width={"100%"}
            maxWidth={"362px"}
            height={"48px"}
            text={<FormattedMessage id="logout.btn2"></FormattedMessage>}
            textSize={"text-btnTextSize"}
            textColor={"text-[#FFFFFF]"}
            background={" bg-redPrimary"}
            type={"button"}
            handleClick={toggleLogout}
          ></ReusableBtn>
        </div>
      </div>
    </div>
  );
};

Logout.propTypes = {
  toggleLogout: PropTypes.func,
};

export default Logout;
