import React from "react";
import PropTypes from "prop-types";

const Details = ({ text, number, color }) => {
  return (
    <div className="card-1-details">
      <h1 className=" text-textSecondary text-extraSmall font-albertSansMedium">
        {text}
      </h1>
      <span
        style={{ color: `${color}` }}
        className={`${color} text-title font-albertSansMedium`}
      >
        {number}
      </span>
    </div>
  );
};

Details.propTypes = {
  text: PropTypes.object,
  number: PropTypes.number,
  color: PropTypes.string,
};

export default Details;
