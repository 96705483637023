/* eslint-disable prefer-const */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { PropTypes } from "prop-types";

import { FormattedMessage, useIntl } from "react-intl";

import { RaportTypes, GenerateRaportTable } from "../../../constants";

import SearchInput from "../../../components/Inputs/SearchInput";
import RaportElement from "./RaportElement";
import ReusableBtn from "../../../components/ReusableBtn/ReusableBtn";
import DescriptionBox from "./DescriptionBox";
import TextInput from "../../../components/Inputs/TextInput";
import Toggle from "../../../components/Toggle/Toggle";
import RaportGroup from "./RaportGroup";
import Table from "../../Administration/Components/table/Table";
import SelectedUserField from "./SelectedUserField";
import DateInput from "../../../components/Inputs/DateInput";

import rightArrowIcon from "../../../assets/icons/Panel/Raports/rightArrowIcon.svg";
import leftArrowIcon from "../../../assets/icons/Panel/Raports/leftArrowIcon.svg";
import searchIcon from "../../../assets/icons/Panel/searchIcon.svg";

const bodyData = [
  {
    id: 0,
    data: [
      {
        id: 0,
        row: "Ada Nakuci",
      },
      {
        id: 1,
        row: "ada.nakuci@adc.al",
      },
    ],
  },
  {
    id: 1,
    data: [
      {
        id: 0,
        row: "Daku Lu",
      },
      {
        id: 1,
        row: "daku.lu@adc.al",
      },
    ],
  },
];

const CreateNewRaport = ({ formData, setFormData }) => {
  const [selectedRows, setSelectedRows] = useState([]);
  const [isChecked, setIsChecked] = useState({
    all: false,
  });
  const [raportGroupIndex, setRaportGroupIndex] = useState(0);
  const [raportElementIndex, setRaportElementIndex] = useState(null);
  const [descriptionLngId, setDescriptionLngId] = useState(
    "raports.createGroup1textareaPlaceholder"
  );
  const [searchValue, setSearchValue] = useState("");
  const [searchValue2, setSearchValue2] = useState("");

  useEffect(() => {
    setFormData((prevValues) => ({
      ...prevValues,
      selectedUsers: selectedRows,
    }));
  }, [selectedRows]);

  //Get all messages from lng file
  const allMessages = useIntl().messages;

  //Get all used lngId for our report element
  let raportsKeys = RaportTypes.map((el) => el.id);

  //Reconstruct the array with id and text
  let raportMessages = raportsKeys.map((el) => ({
    id: el,
    text: allMessages[el],
  }));

  //Reconstruct the filtered array
  let filteredArray = raportMessages.filter((el) =>
    el.text.toLowerCase().includes(searchValue.toLowerCase())
  );

  let filteredUserArray = bodyData.filter((el) =>
    el.data[0].row.toLowerCase().includes(searchValue2.toLowerCase())
  );

  const handleRaportTypeChange = (value) => {
    setFormData((prevValues) => ({ ...prevValues, raportType: value }));
  };

  const handleSearchChange = (event) => {
    const { id } = event.target;

    if (id === "searchInput1") {
      setSearchValue(event.target.value);
    }

    if (id === "searchInput2") {
      setSearchValue2(event.target.value);
    }
  };

  return (
    <form id="createAssetForm" className="formContainer">
      <RaportGroup
        raportGroupIndex={raportGroupIndex}
        index={0}
        contentTitleLngId={"raports.createNewRaportTitle1"}
        readyToGo={raportElementIndex !== null && raportGroupIndex !== null}
      >
        <div className="flex flex-col xl:flex-row xl:justify-between gap-10">
          <div className="flex flex-col gap-5 w-full sm:max-w-[506px] ">
            <div className="raportsBtnContainer ">
              <ReusableBtn
                width={"fit-content"}
                height={"34px"}
                borderRadius={"15px"}
                type={"button"}
                text={
                  <FormattedMessage id="raports.createGroup1type1"></FormattedMessage>
                }
                background={
                  formData.raportType === "all"
                    ? "bg-redPrimary"
                    : "bg-[#EAF1F4]"
                }
                textSize={"text-extraSmall xs:text-regularSize"}
                textColor={
                  formData.raportType === "all"
                    ? "text-white"
                    : "text-[#252931]"
                }
                paddingHorizontal={"px-4 sm:px-5"}
                handleClick={() => handleRaportTypeChange("all")}
              ></ReusableBtn>

              <ReusableBtn
                width={"fit-content"}
                height={"34px"}
                borderRadius={"15px"}
                type={"button"}
                text={
                  <FormattedMessage id="raports.createGroup1type2"></FormattedMessage>
                }
                background={
                  formData.raportType === "fleet"
                    ? "bg-redPrimary"
                    : "bg-[#EAF1F4]"
                }
                textSize={"text-extraSmall xs:text-regularSize"}
                textColor={
                  formData.raportType === "fleet"
                    ? "text-white"
                    : "text-[#252931]"
                }
                paddingHorizontal={"px-4 sm:px-5 leading-3"}
                handleClick={() => handleRaportTypeChange("fleet")}
              ></ReusableBtn>

              <ReusableBtn
                width={"fit-content"}
                height={"34px"}
                borderRadius={"15px"}
                type={"button"}
                text={
                  <FormattedMessage id="raports.createGroup1type3"></FormattedMessage>
                }
                background={
                  formData.raportType === "generic"
                    ? "bg-redPrimary"
                    : "bg-[#EAF1F4]"
                }
                textSize={"text-extraSmall xs:text-regularSize"}
                textColor={
                  formData.raportType === "generic"
                    ? "text-white"
                    : "text-[#252931]"
                }
                paddingHorizontal={"px-4 sm:px-5 leading-3"}
                handleClick={() => handleRaportTypeChange("generic")}
              ></ReusableBtn>
            </div>

            <DescriptionBox
              descriptionLngId={descriptionLngId}
              hidden={"hidden"}
              visibility={"lg:block"}
            ></DescriptionBox>
          </div>

          <div className="search-elements-container">
            <SearchInput
              lngId={"raports.createGroup1SearchText"}
              id={"searchInput1"}
              searchValue={searchValue}
              handleChange={handleSearchChange}
            ></SearchInput>

            <div className=" flex flex-wrap gap-1 lg:gap-3">
              {filteredArray?.map((item) => (
                <RaportElement
                  key={item.id}
                  id={item.id}
                  text={item.text}
                  setFormData={setFormData}
                  raportElementIndex={raportElementIndex}
                  setRaportElementIndex={setRaportElementIndex}
                  setDescriptionLngId={setDescriptionLngId}
                ></RaportElement>
              ))}
            </div>
          </div>
        </div>
        <DescriptionBox
          descriptionLngId={descriptionLngId}
          hidden={"lg:hidden"}
          margin={"my-5"}
        ></DescriptionBox>

        <div className="next-btn">
          <ReusableBtn
            width={"130px"}
            height={"46px"}
            borderRadius={"30px"}
            type={"button"}
            text={<FormattedMessage id="raports.nextBtn"></FormattedMessage>}
            background={"bg-redPrimary"}
            textSize={"text-text16"}
            textColor={"text-[#F8F8F8]"}
            icon={rightArrowIcon}
            gap={"10px"}
            version={2}
            handleClick={() => setRaportGroupIndex((prev) => prev + 1)}
          ></ReusableBtn>
        </div>
      </RaportGroup>

      <RaportGroup
        raportGroupIndex={raportGroupIndex}
        index={1}
        contentTitleLngId={"raports.createNewRaportTitle2"}
        readyToGo={
          formData.selectedUsers.length > 0 &&
          formData.fromDate &&
          formData.toDate
        }
      >
        <div className="space-y-5">
          <Toggle
            title={"raportToggle.title"}
            titleColor={"#252931"}
            titleSize={"12px"}
            label={"raportToggle.label"}
            labelColor={"#9AA8B3"}
            labelSize={"12px"}
            background={"#9aa8b3"}
            borderColor={"#9aa8b3"}
            toggleId={"toggle-switch"}
            uppercase={true}
            toggle={isChecked.all}
            setToggle={() => setIsChecked({ all: !isChecked.all })}
            name={"all"}
            setSelectedRows={setSelectedRows}
            isChecked={isChecked}
            bodyData={bodyData}
          ></Toggle>

          <TextInput
            inputClassName={
              "flex w-full h-[50px] pl-4 text-regularSize border border-[#EBEBEB] rounded-md"
            }
            placeholderLngId={"raport.group2SearchPlaceholder"}
            inputId={"searchInput2"}
            formData={searchValue}
            handleChange={handleSearchChange}
            marginHorizontal={""}
            labelStyle={""}
            labelLngId={""}
            icon={searchIcon}
          ></TextInput>
        </div>

        {selectedRows.length === 1 && (
          <SelectedUserField
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
            setIsChecked={setIsChecked}
          ></SelectedUserField>
        )}

        <Table
          headerData={GenerateRaportTable}
          bodyData={filteredUserArray}
          setState
          setRowData
          version={4}
          setSelectedRows={setSelectedRows}
          selectedRows={selectedRows}
          isChecked={isChecked}
          setIsChecked={setIsChecked}
          shadow={true}
        ></Table>

        <div className=" flex flex-col">
          <h3 className=" text-extraSmall text-blackPrimary">
            <FormattedMessage id="raport.datesTitle"></FormattedMessage>
          </h3>
          <div className=" flex flex-col sm:flex-row items-center gap-3">
            <DateInput
              labelLngId={"raport.fromDate"}
              inputId={"fromDate"}
              value={formData.fromDate}
              setFormData={setFormData}
            ></DateInput>
            <DateInput
              labelLngId={"raport.toDate"}
              inputId={"toDate"}
              value={formData.toDate}
              setFormData={setFormData}
            ></DateInput>
          </div>
        </div>

        <div className="next-btn">
          <ReusableBtn
            width={"130px"}
            height={"46px"}
            borderRadius={"30px"}
            type={"button"}
            text={<FormattedMessage id="raports.backBtn"></FormattedMessage>}
            textSize={"text-text16"}
            textColor={"text-[#999999]"}
            icon={leftArrowIcon}
            border={"1px solid #999999"}
            gap={"10px"}
            handleClick={() => setRaportGroupIndex((prev) => prev - 1)}
          ></ReusableBtn>

          <ReusableBtn
            width={"130px"}
            height={"46px"}
            borderRadius={"30px"}
            type={"button"}
            text={<FormattedMessage id="raports.nextBtn"></FormattedMessage>}
            background={"bg-redPrimary"}
            textSize={"text-text16"}
            textColor={"text-[#F8F8F8]"}
            icon={rightArrowIcon}
            gap={"10px"}
            version={2}
            handleClick={() => setRaportGroupIndex((prev) => prev + 1)}
          ></ReusableBtn>
        </div>
      </RaportGroup>

      <div className="raportGroupContainer">
        <div className="raportsGroupHeader">
          <div className=" w-5 h-5 rounded-full bg-[#E60000] opacity-10"></div>
          <h3 className=" text-text16">
            <FormattedMessage id="raports.createNewRaportTitle3"></FormattedMessage>
          </h3>
        </div>

        {/* <div className="h-[500px] border-t border-[#EAF1F4]"></div> */}
      </div>
    </form>
  );
};

CreateNewRaport.propTypes = {
  formData: PropTypes.Object,
  setFormData: PropTypes.func,
};

export default CreateNewRaport;
