import React from "react";
import ReservationType from "./ReservationType";

import { ScheduleTypes } from "../../../../../../constants";

const TypeSection = () => {
  return (
    <div className="w-full grid grid-cols-3 grid-rows-2 gap-x-1 gap-y-4 p-4 md:p-0">
      {ScheduleTypes.map((item) => (
        <ReservationType
          key={item.type}
          icon={item.icon}
          text={item.text}
          type={item.type}
        ></ReservationType>
      ))}
    </div>
  );
};

export default TypeSection;
