import React from "react";
import { Link } from "react-router-dom";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination, Scrollbar } from "swiper/modules";

import { CardItems } from "../../constants";
import Card from "../../components/DbCards/Card";
import Details from "../../components/DbCards/card-1-elements/Details";

import arrow_icon_gray from "../../assets/icons/Panel/arrow_forward_icon_gray.svg";
import ProgressBar from "../../components/DbCards/card-1-elements/ProgressBar";
import AlertElement from "../../components/DbCards/card-2-elements/AlertElement";
import PageTitle from "../../components/Layout/PageTitle";
import StrechedCard from "../../components/DbCards/StrechedCard";

const Panel = () => {
  const data = [
    { name: "Moving", value: 8, color: "#66ce66 " },
    { name: "Stopped", value: 1, color: "#ffb100" },
    { name: "Parked", value: 41, color: "#e60000 " },
    { name: "Not Communicating", value: 21, color: "#252931" },
  ];

  const data2 = [
    { name: "score", value: 75, color: "#66CE66 " },
    { name: "empty", value: 15, color: "#F2F7FD " },
  ];

  return (
    <div className="pageLayout overflow-y-scroll ">
      <div className="w-full max-w-[400px] md:max-w-[820px] lg:max-w-[1240px]  xl:max-w-[1740px] mx-auto">
        <PageTitle
          titleLngId={"panel.title"}
          subTitleLngId={"panel.subtitle"}
        ></PageTitle>
      </div>

      <div className="  flex flex-wrap gap-5 justify-center ">
        <StrechedCard></StrechedCard>
        <Card generalProps={CardItems[0]} data={data} version={1}>
          <div className=" flex gap-5 sm:gap-3 justify-center items-center flex-wrap px-2">
            {CardItems[0].texts.map((item, index) => (
              <Details
                key={index}
                text={item.text}
                number={item.value}
                color={item.color}
              ></Details>
            ))}
          </div>

          <div className="p-4 flex flex-col gap-4 mt-2">
            <div className="flex justify-between items-center">
              <h1 className=" text-[13px] text-cardTitleColor sm:text-cardTitle font-bold">
                {CardItems[0].subtitle}
              </h1>
              <Link to={CardItems[0].watchMoreLinkTo} className="card-link">
                {CardItems[0].watchMoreText}
                <img src={arrow_icon_gray} className="w-[5px]" alt="icon"></img>
              </Link>
            </div>

            <ProgressBar
              title={CardItems[0].progressBarText1}
              value={0}
            ></ProgressBar>

            <ProgressBar
              title={CardItems[0].progressBarText2}
              value={0}
            ></ProgressBar>
          </div>
        </Card>
        <Card generalProps={CardItems[1]} data={data} version={1}>
          <div className="h-full px-4 ">
            <Swiper
              style={{
                "--swiper-pagination-color": "#ADB9C2",
                "--swiper-pagination-bullet-inactive-color": "#F4F4F4",
                "--swiper-pagination-bullet-inactive-opacity": "1",
              }}
              modules={[Pagination, Scrollbar]}
              scrollbar={{ draggable: true }}
              pagination={{ clickable: true }}
              grabCursor={true}
              allowTouchMove={true}
              simulateTouch={true}
              loop={false}
              className="mySwiper"
            >
              <SwiperSlide>
                <div className="w-full h-[90%] p-4 flex flex-wrap justify-between  items-center ">
                  {CardItems[1].elements.map((item, index) => (
                    <AlertElement
                      key={index}
                      // color={item.color}
                      text={item.text}
                      icon={item.icon}
                      value={item.value}
                    ></AlertElement>
                  ))}
                </div>
              </SwiperSlide>

              <SwiperSlide>Slide 2</SwiperSlide>
            </Swiper>
          </div>
        </Card>
        <Card generalProps={CardItems[2]} data={data} version={1}>
          <div className="w-full h-full  px-4">
            <div className="w-full h-full border-t-[3px] border-solid border-mainBg flex justify-center items-center">
              <div className=" flex flex-col gap-1 items-center font-albertSansMedium ">
                <span className="text-[12px] text-textSecondary">
                  {CardItems[2].text}
                </span>
                <span className=" text-[20px] text-[#EA6C68]">
                  {CardItems[2].value}
                </span>
              </div>
            </div>
          </div>
        </Card>
        <Card generalProps={CardItems[3]} data={data} version={1}>
          <div className="w-full h-full px-4">
            <div className="w-full h-full border-t-[3px] border-solid border-mainBg p-3 ">
              <div className="flex items-center gap-2 font-albertSansMedium text-[13px] text-cardLink mt-2">
                <span>{CardItems[3].text1}</span>
                <div className="w-full h-4 bg-[#F4F4F4]"></div>
                <span>{CardItems[3].text2}</span>
              </div>

              <div className=" flex flex-col gap-3 items-center translate-y-[150%]">
                <img
                  src={CardItems[3].contentIcon}
                  alt={CardItems[3].contentIcon}
                ></img>
                <span className=" font-albertSansMedium text-[12px] text-cardLink">
                  {CardItems[3].text3}
                </span>
              </div>
            </div>
          </div>
        </Card>
        <Card generalProps={CardItems[4]} data={data2} version={2}></Card>
        <Card generalProps={CardItems[5]} data={data2} version={3}></Card>
        <div className="w-full max-w-[420px] min-w-[420px]"></div>
        <div className="w-full max-w-[420px] min-w-[420px]"></div>
      </div>
    </div>
  );
};

export default Panel;
