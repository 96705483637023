import React from "react";
import PropTypes from "prop-types";

const CustomRadioBtn = ({ data, value }) => {
  return (
    <div
      className="customRadioContainer"
      style={{
        borderColor: data === value ? "#007C92" : "#ACACAC",
      }}
    >
      {data === value && <div></div>}
    </div>
  );
};

CustomRadioBtn.propTypes = {
  data: PropTypes.string,
  value: PropTypes.string,
};

export default CustomRadioBtn;
