import React, { useState } from "react";
import PropTypes from "prop-types";

import { FormattedMessage } from "react-intl";

import PinModalElement from "./PinModalElement";

import grayCarIcon from "../../../../assets/icons/Panel/map-icons/Pin-Modal-Icons/gray-car.svg";
import closeIcon from "../../../../assets/icons/Panel/map-icons/Pin-Modal-Icons/close-icon.svg";
import tab1IconRed from "../../../../assets/icons/Panel/map-icons/Pin-Modal-Icons/tab-1-icon-red.svg";
import tab1IconGray from "../../../../assets/icons/Panel/map-icons/Pin-Modal-Icons/tab-1-icon-gray.svg";
import tab2IconGray from "../../../../assets/icons/Panel/map-icons/Pin-Modal-Icons/tab-2-icon-gray.svg";
import tab2IconRed from "../../../../assets/icons/Panel/map-icons/Pin-Modal-Icons/tab-2-icon-red.svg";
import tab3IconGray from "../../../../assets/icons/Panel/map-icons/Pin-Modal-Icons/tab-3-icon-gray.svg";
import tab3IconRed from "../../../../assets/icons/Panel/map-icons/Pin-Modal-Icons/tab-3-icon-red.svg";
import tab4IconGray from "../../../../assets/icons/Panel/map-icons/Pin-Modal-Icons/tab-4-icon-gray.svg";
import tab4IconRed from "../../../../assets/icons/Panel/map-icons/Pin-Modal-Icons/tab-4-icon-red.svg";
import locationGrayIcon from "../../../../assets/icons/Panel/map-icons/Pin-Modal-Icons/location-gray.svg";

// eslint-disable-next-line no-unused-vars
const PinModal = ({ handleClose, details }) => {
  const [tabIndex, setTabIndex] = useState(0);
  const [subTabIndex, setSubTabIndex] = useState(0);

  return (
    <div className="pinModal">
      <div className="pinModalHeader">
        <div className="w-[40px] h-[40px] flex justify-center items-center rounded-full bg-[#666666]">
          <img src={grayCarIcon}></img>
        </div>

        <div className=" flex-1 leading-5">
          <h2 className="text-[18px] text-blackPrimary font-albertSansBold">
            AA 394 LK
          </h2>
          <h3 className="text-small font-albertSansMedium">08/02/24 9:37</h3>
          <img
            className="!w-[12px] absolute top-[8px] right-[8px] cursor-pointer active:opacity-75"
            src={closeIcon}
            onClick={handleClose}
          />
        </div>
      </div>

      <div className="tab-container">
        <div
          className="modal-tab"
          onClick={() => setTabIndex(0)}
          style={{
            borderBottom: tabIndex === 0 && "2px solid #E60000",
          }}
        >
          <img src={tabIndex === 0 ? tab1IconRed : tab1IconGray}></img>
        </div>

        <div
          className="modal-tab"
          onClick={() => setTabIndex(1)}
          style={{
            borderBottom: tabIndex === 1 && "2px solid #E60000",
          }}
        >
          <img src={tabIndex === 1 ? tab2IconRed : tab2IconGray}></img>
        </div>
        <div
          className="modal-tab"
          onClick={() => setTabIndex(2)}
          style={{
            borderBottom: tabIndex === 2 && "2px solid #E60000",
          }}
        >
          <img src={tabIndex === 2 ? tab3IconRed : tab3IconGray}></img>
        </div>
        <div
          className="modal-tab"
          onClick={() => setTabIndex(3)}
          style={{
            borderBottom: tabIndex === 3 && "2px solid #E60000",
          }}
        >
          <img src={tabIndex === 3 ? tab4IconRed : tab4IconGray}></img>
        </div>
      </div>

      <div className="p-[10px] pb-[25px]">
        {tabIndex === 0 && (
          <div className="content-container">
            <PinModalElement
              icon={tab1IconGray}
              title={"pinModalElement.text1"}
              text={"Moved"}
            ></PinModalElement>
            <PinModalElement
              icon={tab1IconGray}
              title={"pinModalElement.text2"}
              text={"OFF"}
            ></PinModalElement>
            <PinModalElement
              icon={tab1IconGray}
              title={"pinModalElement.text3"}
              text={"17m"}
            ></PinModalElement>
            <PinModalElement
              icon={tab1IconGray}
              title={"pinModalElement.text4"}
              text={"175"}
            ></PinModalElement>
            <PinModalElement
              icon={tab1IconGray}
              title={"pinModalElement.text5"}
              text={0}
            ></PinModalElement>
            <PinModalElement
              icon={tab1IconGray}
              title={"pinModalElement.text6"}
              text={"12.730 mV"}
            ></PinModalElement>
            <PinModalElement
              icon={tab1IconGray}
              title={"pinModalElement.text7"}
              text={"Moved"}
            ></PinModalElement>
            <PinModalElement
              icon={tab1IconGray}
              title={"pinModalElement.text8"}
              text={"Moved"}
            ></PinModalElement>
            <PinModalElement
              icon={tab1IconGray}
              title={"pinModalElement.text9"}
            ></PinModalElement>
            <PinModalElement
              icon={locationGrayIcon}
              title={"pinModalElement.text10"}
            ></PinModalElement>
          </div>
        )}
        {tabIndex === 1 && (
          <div className="infoTabContainer">
            <div className="infoTabElementContainer">
              <span className="!text-blackPrimary">
                <FormattedMessage id="pinModalInfo.text1"></FormattedMessage>
              </span>
              <span>Vehicle / Car</span>
            </div>

            <div className="infoTabElementContainer">
              <span>
                <FormattedMessage id="pinModalInfo.text2"></FormattedMessage>
              </span>
              <span>AA 091 OH</span>
            </div>

            <div className="infoTabElementContainer">
              <span>
                <FormattedMessage id="pinModalInfo.text3"></FormattedMessage>
              </span>
              <span>FBM130</span>
            </div>

            <div className="infoTabElementContainer">
              <span>
                <FormattedMessage id="pinModalInfo.text4"></FormattedMessage>
              </span>
              <span>23545131561651616</span>
            </div>
            <div className="infoTabElementContainer">
              <span>
                <FormattedMessage id="pinModalInfo.text5"></FormattedMessage>
              </span>
              <span>Tirana, Tirana, 1044,ALB</span>
            </div>

            <div className="flex justify-end">
              <span className="!text-blackPrimary text-regularSize font-albertSansBold">
                ADC DataCenter Hub10
              </span>
            </div>
          </div>
        )}
        {tabIndex === 2 && (
          <div className="notificationTab">
            <div className=" text-black text-regularSize text-center ">
              No alerts found
            </div>
          </div>
        )}
        {tabIndex === 3 && (
          <div className="locationTab !p-0">
            <div className="tab-container !border-t-0">
              <div
                className="modal-subTab"
                onClick={() => setSubTabIndex(0)}
                style={{
                  borderBottom: subTabIndex === 0 && "2px solid #E60000",
                }}
              >
                <FormattedMessage id="pinModalLocationDetails.tab1"></FormattedMessage>
              </div>

              <div
                className="modal-subTab"
                onClick={() => setSubTabIndex(1)}
                style={{
                  borderBottom: subTabIndex === 1 && "2px solid #E60000",
                }}
              >
                <FormattedMessage id="pinModalLocationDetails.tab2"></FormattedMessage>
              </div>
            </div>
            {subTabIndex === 0 && (
              <div className="flex flex-col space-y-2 p-4">
                <div className="locationTabElementContainer">
                  <span>
                    <FormattedMessage id="pinModalLocationDetails.text1"></FormattedMessage>
                  </span>
                  <span>ADC DataCenter Hub10</span>
                </div>
                <div className="locationTabElementContainer">
                  <span>
                    <FormattedMessage id="pinModalLocationDetails.text2"></FormattedMessage>
                  </span>
                  <span>41.4808668, 19.8614988</span>
                </div>
                <div className="locationTabElementContainer">
                  <span>
                    <FormattedMessage id="pinModalLocationDetails.text3"></FormattedMessage>
                  </span>
                  <span>-</span>
                </div>
                <div className="locationTabElementContainer">
                  <span>
                    <FormattedMessage id="pinModalLocationDetails.text4"></FormattedMessage>
                  </span>
                  <span>-</span>
                </div>
                <div className="locationTabElementContainer">
                  <span>
                    <FormattedMessage id="pinModalLocationDetails.text5"></FormattedMessage>
                  </span>
                  <span>-</span>
                </div>
                <div className="locationTabElementContainer">
                  <span>
                    <FormattedMessage id="pinModalLocationDetails.text6"></FormattedMessage>
                  </span>
                  <span>-</span>
                </div>
                <div className="locationTabElementContainer">
                  <span>
                    <FormattedMessage id="pinModalLocationDetails.text7"></FormattedMessage>
                  </span>
                  <span>-</span>
                </div>
                <div className="locationTabElementContainer">
                  <span>
                    <FormattedMessage id="pinModalLocationDetails.text8"></FormattedMessage>
                  </span>
                  <span>-</span>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

PinModal.propTypes = {
  handleClose: PropTypes.func,
  details: PropTypes.object,
};

export default PinModal;
