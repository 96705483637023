import React from "react";
import PropTypes from "prop-types";
import { InfoWindowDetails } from "../../../../constants";

const CustomControlInfoWindow = ({
  top,
  identifier,
  handleClick,
  isTrafficVisible,
  mapType,
}) => {
  const handleMultipleActions = (item) => {
    handleClick(item.type);
  };

  return (
    <div className="custom-control-info-window" style={{ top }}>
      <div className="custom-triangle"></div>

      {InfoWindowDetails[identifier].map((item, index) => (
        <div
          className="flex items-center gap-2 cursor-pointer"
          key={index}
          onClick={() => handleMultipleActions(item, index)}
        >
          {isTrafficVisible !== undefined ? (
            <img
              src={item.icon}
              className="border border-solid rounded-full"
              style={{
                borderColor: isTrafficVisible && "#E60000",
              }}
            ></img>
          ) : (
            <img
              src={item.icon}
              className="border border-solid rounded-full"
              style={{ borderColor: mapType === item.type && "#E60000" }}
            ></img>
          )}

          {isTrafficVisible !== undefined ? (
            <div
              className="text-[13px] font-albertSansMedium"
              style={{
                color: isTrafficVisible ? "#E60000" : "#8A98A8",
              }}
            >
              {item.text}
            </div>
          ) : (
            <div
              className="text-[13px] font-albertSansMedium"
              style={{
                color: mapType === item.type ? "#E60000" : "#8A98A8",
              }}
            >
              {item.text}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

CustomControlInfoWindow.propTypes = {
  top: PropTypes.string,
  isTrafficVisible: PropTypes.bool,
  handleClick: PropTypes.func,
  identifier: PropTypes.string,
  mapType: PropTypes.string,
};

export default CustomControlInfoWindow;
