import React, { useEffect } from "react";

import { PropTypes } from "prop-types";

import { FormattedMessage } from "react-intl";

import Checkbox from "../../../../components/Inputs/Checkbox";
import ReusableBtn from "../../../../components/ReusableBtn/ReusableBtn";
import InputWithLabel from "../../../../components/Inputs/InputWithLabel";

import redBinIcon from "../../../../assets/icons/Panel/administration/AssetGroup/deleteBinIconRed.svg";
import plusIconBlack from "../../../../assets/icons/Panel/administration/AssetGroup/plusIconBlack.svg";

const CreateAssetGroup = ({ formData, setFormData }) => {
  useEffect(() => {
    return () => {
      setFormData({
        serialNumber: "",
        allowSelection: false,
        subgroups: "default",
        assetType: "",
      });
    };
  }, []);

  const handleChange = (event) => {
    const { name } = event.target;

    if (name === "serialNumber") {
      setFormData((prevValues) => ({
        ...prevValues,
        [name]: event.target.value,
      }));
    }
    if (name === "allowSelection") {
      setFormData((prevValues) => ({
        ...prevValues,
        allowSelection: !formData.allowSelection,
      }));
    }
  };

  return (
    <form id="createAssetForm" className="formContainer">
      <div className="groupContainer">
        <h1 className=" font-bold text-inputsGroupTitle text-pageTitle">
          <FormattedMessage id="assetGroup.group1Text"></FormattedMessage>
        </h1>
        <InputWithLabel
          id={"serialNumber"}
          name={"serialNumber"}
          labelLngId={"assetGroup.inputLabel1"}
          labelSize={"text-extraSmall"}
          labelColor={"text-textSecondary"}
          handleChange={handleChange}
          value={formData.serialNumber}
        ></InputWithLabel>
      </div>

      <div className="groupContainer">
        <h1 className=" font-bold text-inputsGroupTitle text-pageTitle">
          <FormattedMessage id="assetGroup.group2Text"></FormattedMessage>
        </h1>
        <div className=" flex gap-4 items-center">
          <Checkbox
            labelLngId={"assetGroup.inputLabel2"}
            name={"allowSelection"}
            checked={formData.allowSelection === true}
            handleChange={handleChange}
            labelSize={"text-[16px]"}
            labelColor={"text-pageTitle"}
            disabled={false}
          ></Checkbox>
        </div>
      </div>

      <div className="groupContainer">
        <h1 className=" font-bold text-inputsGroupTitle text-pageTitle">
          <FormattedMessage id="assetGroup.group3Text"></FormattedMessage>
        </h1>

        <div className="flex items-center gap-4">
          <ReusableBtn
            width={"100%"}
            maxWidth={"84px"}
            height={"35px"}
            text={
              <FormattedMessage id="assetGroup.inputLabelBtn1Text"></FormattedMessage>
            }
            textSize={"text-regularSize"}
            textColor={"text-white"}
            background={"bg-redPrimary"}
            borderRadius={"20px"}
            // handleClick={""}
          ></ReusableBtn>
          <ReusableBtn
            width={"100%"}
            maxWidth={"84px"}
            height={"35px"}
            text={
              <FormattedMessage id="assetGroup.inputLabelBtn2Text"></FormattedMessage>
            }
            textSize={"text-regularSize"}
            textColor={"text-black"}
            background={"bg-white"}
            borderRadius={"20px"}
            // handleClick={""}
          ></ReusableBtn>
        </div>

        <div className="w-full bg-white rounded-md  py-1 px-3 mt-4">
          <label
            // htmlFor={inputId}
            className="text-extraSmall text-textSecondary"
          >
            <FormattedMessage id={"assetGroup.inputLabel3"}></FormattedMessage>
          </label>

          <div className="flex items-center justify-between py-2 border-b border-[rgba(0,0,0,0.1)]">
            <span className="text-[16px]">1st Subgroup name</span>
            <span className=" cursor-pointer">
              <img src={redBinIcon}></img>
            </span>
          </div>

          <div className="flex items-center gap-2 py-4 cursor-pointer">
            <span className=" opacity-30">
              <img src={plusIconBlack} width={"10px"}></img>
            </span>
            <span className="text-[16px] text-[#EBEBEB]">Add Subgroub</span>
          </div>
        </div>
      </div>
    </form>
  );
};

CreateAssetGroup.propTypes = {
  formData: PropTypes.Object,
  setFormData: PropTypes.Func,
};

export default CreateAssetGroup;
