/* eslint-disable indent */
/* eslint-disable no-nested-ternary */
/* eslint-disable complexity */
/* eslint-disable no-unused-vars */

import React, { useState } from "react";
import { FormattedMessage } from "react-intl";

import Table from "../Administration/Components/table/Table";
import ReusableBtn from "../../components/ReusableBtn/ReusableBtn";
import PageTitle from "../../components/Layout/PageTitle";

import { RaportsTable } from "../../constants";

import plusIcon from "../../assets/icons/Panel/administration/Assets/plus-icon.svg";
import discardIcon from "../../assets/icons/Panel/administration/Assets/discardIcon.svg";
import CreateNewRaport from "./Components/CreateNewRaport";

const Raports = () => {
  const [formData, setFormData] = useState({
    raportType: "all",
    raportName: "",
    users: "default",
    selectedUsers: [],
    fromDate: "",
    toDate: "",
  });
  const [view, setView] = useState("table");

  const bodyData = [
    {
      id: 0,
      data: [
        {
          id: 0,
          row: "Sensor Value History",
        },
        {
          id: 1,
          row: "09/04/24 11:24",
        },
        {
          id: 2,
          row: "09/04/24 11:24",
        },
        {
          id: 3,
          row: "operations@adc.al",
        },
        {
          id: 4,
          row: "",
        },
        {
          id: 5,
          row: "Ready",
        },
        {
          id: 6,
          row: "in 7 days",
        },
      ],
    },
  ];

  const handleChange = (event) => {
    const { id, name } = event.target;
  };

  const onSubmit = () => {
    // console.log("this is formData:", formData);
  };

  return (
    <div className="pageLayout">
      <div className="w-full flex flex-col gap-2 lg:gap-0 lg:flex-row lg:justify-between lg:items-center">
        <PageTitle
          titleLngId={
            view === "create"
              ? "raports.createRaportTitle"
              : "raports.pageTitle"
          }
          subTitleLngId={"raports.subtitle1"}
          subTitle2LngId={"raports.subtitle2"}
          state={view}
          setState={() => setView("table")}
        ></PageTitle>

        <div className=" flex items-center gap-2 mb-4 lg:mb-0">
          {view === "create" && (
            <ReusableBtn
              icon={discardIcon}
              text={
                <FormattedMessage id="raports.discardBtn"></FormattedMessage>
              }
              textSize={"text-filterBtn"}
              textColor={"text-[#0D0D0D]"}
              width={"100px"}
              maxWidth={""}
              height={"40px"}
              background={"bg-transparent"}
              gap={7}
              paddingHorizontal={"px-1"}
              type={"button"}
              border={"1px solid #0D0D0D"}
              handleClick={() => setView("table")}
            ></ReusableBtn>
          )}

          <ReusableBtn
            icon={view === "create" ? "" : plusIcon}
            text={
              <FormattedMessage
                id={view === "create" ? "raports.generateBtn" : "raports.btn1"}
              ></FormattedMessage>
            }
            imgWidth={"14px"}
            textSize={"text-filterBtn"}
            textColor={"text-[#FFFFFF]"}
            width={"fit-content"}
            maxWidth={""}
            height={view === "create" ? "40px" : "45px"}
            background={"bg-redPrimary"}
            gap={10}
            paddingHorizontal={"px-4"}
            type={"button"}
            handleClick={
              view === "create"
                ? onSubmit
                : view === "view"
                ? () => console.log("edit page")
                : () => setView("create")
            }
          ></ReusableBtn>
        </div>
      </div>

      {view === "create" && (
        <CreateNewRaport
          formData={formData}
          setFormData={setFormData}
        ></CreateNewRaport>
      )}

      {view === "table" && (
        <Table
          version={3}
          headerData={RaportsTable}
          bodyData={bodyData}
          setState={setView}
        ></Table>
      )}
    </div>
  );
};

export default Raports;
