import React, { useState } from "react";

import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import { languageChange } from "../../store/language/languageSlice";

import languageIcon from "../../assets/icons/Panel/Settings/language-icon.svg";
import arrowIconForward from "../../assets/icons/Panel/arrow_forward_icon_red.svg";
// import arrowIconBackward from "../../assets/icons/Panel/arrow_backward-red.svg";
import albaniaIcon from "../../assets/icons/Panel/Settings/albaniaFlag.svg";
import englandIcon from "../../assets/icons/Panel/Settings/englandFlag.svg";
import PageTitle from "../../components/Layout/PageTitle";

const Settings = () => {
  const [showLanguages, setShowLanguages] = useState(false);
  const dispatch = useDispatch();
  const { currentLanguage } = useSelector((state) => state.language);

  const change = (value) => {
    dispatch(languageChange(value));
  };

  const handleStateChange = () => {
    setShowLanguages(false);
  };

  return (
    <div className="pageLayout">
      <PageTitle
        titleLngId={"settings.pageTitle"}
        subTitleLngId={"settings.subtitle"}
        subTitle2LngId={"settings.text1"}
        state={showLanguages}
        setState={handleStateChange}
      ></PageTitle>

      {showLanguages ? (
        <div className="flex flex-col my-5">
          <div
            className="selectLanguageDivStep2 rounded-t-md"
            onClick={() => change("sq")}
          >
            <div className="flex items-center gap-4">
              <img src={albaniaIcon}></img>
              <span>Albania</span>
            </div>

            <div
              className="customRadioContainer"
              style={{
                borderColor: currentLanguage === "sq" ? "#007C92" : "#ACACAC",
              }}
            >
              {currentLanguage === "sq" && <div></div>}
            </div>
          </div>

          <div
            className="selectLanguageDivStep2 rounded-b-md"
            onClick={() => change("en")}
          >
            <div className="flex items-center gap-4">
              <img src={englandIcon}></img>
              <span>English</span>
            </div>

            <div
              className="customRadioContainer"
              style={{
                borderColor: currentLanguage === "sq" ? "#007C92" : "#ACACAC",
              }}
            >
              {currentLanguage === "en" && <div></div>}
            </div>
          </div>
        </div>
      ) : (
        <div
          className="selectLanguageDiv"
          onClick={() => setShowLanguages(true)}
        >
          <img src={languageIcon} alt="language-icon" className="px-5"></img>
          <div className="h-full flex flex-1 justify-between items-center">
            <div className="flex flex-col">
              <span>
                <FormattedMessage id="settings.text1"></FormattedMessage>
              </span>
              <span className="pageSubtitle">
                <FormattedMessage id="settings.text2"></FormattedMessage>
              </span>
            </div>
            <img
              src={arrowIconForward}
              width={9}
              alt="goTo-icon"
              className="mx-8"
            ></img>
          </div>
        </div>
      )}
    </div>
  );
};

export default Settings;
