import React from "react";
import PropTypes from "prop-types";

import { FormattedMessage } from "react-intl";

const Accordation = ({
  textLngId,
  onClick,
  icon,
  index,
  identifier,
  children,
}) => {
  return (
    <div className="accordationContainer">
      <div className="accordationHeader" onClick={onClick}>
        <h2>
          <FormattedMessage id={textLngId}></FormattedMessage>
        </h2>
        <img
          src={icon}
          style={{
            transform:
              index === identifier ? "rotate(-90deg)" : "rotate(90deg)",
            transition: "transform",
            transitionDelay: ".1s",
            transitionTimingFunction: "ease-in-out",
            transitionDuration: ".1s",
          }}
        ></img>
      </div>

      <div
        style={{
          maxHeight: 180,
          height: index === identifier ? 180 : 0,
          overflowY: index === identifier ? "auto" : "hidden",
          padding: index === identifier && "12px",
          transition: "height padding",
          transitionDelay: ".1s",
          transitionTimingFunction: "ease-in-out",
          transitionDuration: "0.3s",
        }}
      >
        {children}
      </div>
    </div>
  );
};

Accordation.propTypes = {
  children: PropTypes.object,
  identifier: PropTypes.number,
  textLngId: PropTypes.string,
  onClick: PropTypes.func,
  icon: PropTypes.string,
  index: PropTypes.number,
};

export default Accordation;
