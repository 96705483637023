import React, { forwardRef, useCallback, useState } from "react";
import PropTypes from "prop-types";
import { AdvancedMarker } from "@vis.gl/react-google-maps";
import PinModal from "../Pin/PinModal";

const MarkerWithRef = forwardRef(function MarkerWithInfoWindow(
  { position, icon, onClick, details },
  ref
) {
  const [infoWindowShown, setInfoWindowShown] = useState(false);
  const handleMarkerClick = useCallback((event) => {
    setInfoWindowShown((isShown) => !isShown);

    onClick(event);
  }, []);
  const handleClose = useCallback(() => setInfoWindowShown(false), []);

  // console.log("Details:", details);

  return (
    <>
      <AdvancedMarker position={position} onClick={handleMarkerClick} ref={ref}>
        <img src={icon} width={32} height={32} alt="pin-icon" />
      </AdvancedMarker>
      {infoWindowShown && (
        <PinModal handleClose={handleClose} details={details}></PinModal>
      )}
    </>
  );
});

MarkerWithRef.propTypes = {
  details: PropTypes.object,
  position: PropTypes.object,
  icon: PropTypes.string,
  onClick: PropTypes.func,
};

export default MarkerWithRef;
