/* eslint-disable consistent-return */
import { useMap } from "@vis.gl/react-google-maps";
import React, { useEffect } from "react";

const TrafficLayer = () => {
  const map = useMap();

  useEffect(() => {
    if (!map) return;

    // eslint-disable-next-line no-undef
    const trafficLayer = new google.maps.TrafficLayer({
      map,
      autoRefresh: true,
    });

    return () => {
      trafficLayer.setMap(null);
    };
  }, [map]);

  return <></>;
};

export default TrafficLayer;
