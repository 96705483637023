import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import PieChartBox from "./PieChartBox";

import arrow_icon_gray from "../../assets/icons/Panel/arrow_forward_icon_gray.svg";
import download_icon from "../../assets/icons/Panel/Card/download_icon.svg";

const Card = ({ data, children, generalProps, version }) => {
  return (
    <div
      className="card-container"
      style={{ height: version === 1 ? "730px" : "fit-content" }}
    >
      <div className="card-header">
        {version === 1 && <img src={generalProps.headerIcon} alt="icon"></img>}
        <h1 className=" uppercase text-cardTitle text-cardTitleColor font-bold">
          {generalProps.title}
        </h1>
      </div>

      <div className="card-body">
        <div className=" relative">
          <PieChartBox data={data} version={version}></PieChartBox>
          {version === 1 && (
            <button type="button" className="chart-download-button">
              <img src={download_icon} alt="download"></img>
            </button>
          )}

          {version !== 1 && (
            <div className=" text-[#66CE66] text-text18 py-2 text-center">
              {generalProps.pieChartText}
            </div>
          )}
        </div>

        <div
          className="chart-text"
          style={{ top: version === 1 ? "12%" : "24.5%" }}
        >
          {version === 1 && (
            <>
              <span className=" text-cardTotalText text-[12px]">
                {generalProps.pieChartText}
              </span>
              <h2 className=" text-[34px] font-albertSansSemiBold text-cardTotalNumber">
                71
              </h2>
            </>
          )}

          {version === 2 && (
            <div>
              <span className="absolute left-0 right-0 mx-auto top-10 text-[26px] font-bold text-white pr-1">
                {data[0].value}
              </span>
              <img
                src={generalProps.pieChartIcon}
                width={60}
                className="pr-1"
              ></img>
            </div>
          )}

          {version === 3 && (
            <div className=" bg-[#66CE66] size-[90px] rounded-full mr-1 flex flex-col gap-1 items-center justify-end pb-2">
              <span className="text-[26px] font-bold text-white">
                {data[0].value}
              </span>
              <img src={generalProps.pieChartIcon} width={28}></img>
            </div>
          )}
        </div>

        {children}
      </div>

      <div className="card-footer">
        <Link to={generalProps.footerLinkTo} className="card-link">
          {generalProps.footerText}
          <img src={arrow_icon_gray} className="w-[7px]" alt="icon"></img>
        </Link>
      </div>
    </div>
  );
};

Card.propTypes = {
  generalProps: PropTypes.object,
  data: PropTypes.object,
  version: PropTypes.number,
  children: PropTypes.object,
};

export default Card;
