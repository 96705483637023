import React from "react";

import { PropTypes } from "prop-types";

import { FormattedMessage } from "react-intl";

const SearchInput = ({
  lngId,
  id,
  searchValue,
  handleChange,
  labelLngId,
  labelStyle,
  margin,
  width,
}) => {
  return (
    <div className="flex flex-col">
      {labelLngId && (
        <span className={`${labelStyle}`}>
          <FormattedMessage id={labelLngId}></FormattedMessage>
        </span>
      )}

      <FormattedMessage id={lngId} defaultMessage="search">
        {(placeholder) => (
          <input
            type="text"
            value={searchValue}
            // onChange={(event) => handleChange(event)}
            onChange={handleChange}
            id={id}
            placeholder={placeholder}
            className={`${width} outline-none py-2 px-2 lg:px-0 border-b-[1px] border-[#EAF1F4] text-text16 text-[#606A70] bg-transparent`}
            style={{ margin }}
          />
        )}
      </FormattedMessage>
    </div>
  );
};

SearchInput.propTypes = {
  lngId: PropTypes.string,
  id: PropTypes.string,
  margin: PropTypes.string,
  searchValue: PropTypes.string,
  handleChange: PropTypes.func,
  labelLngId: PropTypes.string,
  labelStyle: PropTypes.string,
  width: PropTypes.string,
};

export default SearchInput;
