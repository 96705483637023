import React, { useState, useEffect } from "react";
import dayjs from "dayjs";

import { getMonth } from "../../../../../utils/getMonth";
import useDayjsLocale from "../../../../../utils/hooks/useDayjsLocale";

import { useSelector, useDispatch } from "react-redux";
import { setMonthIndex } from "../../../../../store/calendar/calendarSlice";

import SmallCalendarDay from "./SmallCalendarDay";

const SmallCalendar = () => {
  useDayjsLocale();

  const dispatch = useDispatch();
  const monthIndex = useSelector((state) => state.calendar.monthIndex);
  const smallCalendarMonth = useSelector(
    (state) => state.calendar.smallCalendarMonth
  );

  const [currentMonthIndex, setCurrentMonthIndex] = useState(dayjs().month());
  const [currentMonth, setCurrentMonth] = useState(getMonth());

  useEffect(() => {
    setCurrentMonth(getMonth(currentMonthIndex));
  }, [currentMonthIndex]);

  useEffect(() => {
    setCurrentMonthIndex(monthIndex);
  }, [monthIndex]);

  useEffect(() => {
    if (smallCalendarMonth !== null) {
      dispatch(setMonthIndex(smallCalendarMonth));
    }
  }, [smallCalendarMonth]);

  return (
    <div className="w-full hidden md:grid grid-cols-7 grid-rows-6 gap-x-1 items-center text-xxs font-medium ">
      {currentMonth[0].map((day, index) => (
        <span key={index} className="py-1 text-center">
          {day.format("dd").toLowerCase().charAt(0)}
        </span>
      ))}
      {currentMonth.map((row, i) => (
        <React.Fragment key={i}>
          {row.map((day, j) => (
            <SmallCalendarDay
              key={j}
              day={day}
              currentMonthIndex={currentMonthIndex}
            ></SmallCalendarDay>
          ))}
        </React.Fragment>
      ))}
    </div>
    // </div>
  );
};

export default SmallCalendar;
