/* eslint-disable indent */
import React, { useRef } from "react";
import PropTypes from "prop-types";

import { useDispatch, useSelector } from "react-redux";
import {
  openModal,
  setDaySelected,
  setSelectedEvent,
} from "../../../store/calendar/calendarSlice.js";
import useDayClassHook from "../../../utils/hooks/useDayClassHook.js";
import { useWindowDimensions } from "../../../utils/GetDimensions.js";

import dayjs from "dayjs";

const Day = ({ day, rowIndex, colIndex }) => {
  const useDayClass = useDayClassHook(day);
  const { width } = useWindowDimensions();

  const dispatch = useDispatch();
  const monthIndex = useSelector((state) => state.calendar.monthIndex);
  const allEvents = useSelector((state) => state.calendar.allEvents);
  const selectedType = useSelector((state) => state.calendar.selectedType);
  const elementRef = useRef();

  const events = selectedType
    ? allEvents.filter(
        (event) =>
          event.selectedDay === day.valueOf() && event.category === selectedType
      )
    : allEvents.filter((event) => event.selectedDay === day.valueOf());

  const firstTwoEvents = events.slice(0, 2);

  const handleDayClick = () => {
    dispatch(setSelectedEvent(null));

    dispatch(setDaySelected(day.valueOf()));

    dispatch(openModal());
  };

  const handleMouseOverHeader = () => {
    elementRef?.current?.classList.add("bg-white");
  };

  const handleSpecificEventClick = (event, item) => {
    event.stopPropagation();

    dispatch(setSelectedEvent(item));

    dispatch(openModal());
  };

  const formatTimeToDisplay = (time) => dayjs(time).format("HH:mm");

  return (
    <div
      onMouseOver={handleMouseOverHeader}
      className=" text-xxs h-full border-b border-r flex flex-col justify-between cursor-pointer hover:bg-calendarDayHover"
      onClick={handleDayClick}
      style={{
        backgroundColor: day.month() !== monthIndex && "#F2F2F2",
        borderLeft:
          (rowIndex === 3 || rowIndex === 4) &&
          colIndex === 0 &&
          "0.5px solid #e5e7eb",
      }}
    >
      <div>
        {rowIndex === 0 && (
          <p
            className="text-center border-y text-extraSmall relative"
            ref={elementRef}
          >
            {day.format("ddd")}
            <div className="h-full w-[1px] absolute top-0 right-[-1px] bg-white"></div>
          </p>
        )}

        <div className="flex items-center p-2 h-[45px]">
          <p className={`flex justify-center items-center ${useDayClass}`}>
            {day.format("DD")}
          </p>
        </div>
      </div>

      <div className="flex flex-col justify-end h-[80px] p-1">
        {firstTwoEvents.map((item) => (
          <div
            key={item.id}
            className="flex justify-between items-center hover:bg-blue-100 hover:p-1 rounded-md"
            onClick={(event) => handleSpecificEventClick(event, item)}
          >
            <div
              // key={item.id}
              className="flex items-center gap-1 truncate flex-nowrap"
            >
              <span
                className="size-3 min-w-[12px] min-h-[12px] rounded-full"
                style={{ backgroundColor: item.labelColor }}
              ></span>
              {/* <h1 className="truncate">{item.title}</h1> */}
              <h1 className="truncate capitalize">{item.category}</h1>
            </div>

            {/* {width > 1024 && <span>time</span>} */}
            {width > 1024 && (
              // <span className=" text-black">{item.timeCreated}</span>
              <span className=" text-black">
                {formatTimeToDisplay(item.timeCreated)}
              </span>
            )}
          </div>
        ))}

        {events.length > firstTwoEvents.length && (
          <span
            onClick={() => {
              console.log("event clicked");
            }}
            className=" text-darkGray"
          >
            +{events.length - firstTwoEvents.length} More
          </span>
        )}
      </div>
    </div>
  );
};

Day.propTypes = {
  day: PropTypes.object,
  rowIndex: PropTypes.number,
  colIndex: PropTypes.number,
};

export default Day;
