import React, { useEffect } from "react";
import { PropTypes } from "prop-types";

import SelectInput from "../../../../components/Inputs/SelectInput";
import Checkbox from "../../../../components/Inputs/Checkbox";
import InputWithLabel from "../../../../components/Inputs/InputWithLabel";

import { FormattedMessage } from "react-intl";
import { options3 } from "../../../../constants";

const CreateUser = ({ formData, setFormData }) => {
  useEffect(() => {
    return () => {
      setFormData({
        firstName: "",
        lastName: "",
        fixedPhone: "",
        role: "",
        userGroups: "",
        commandGroups: "",
        authorizedMobileApps: "",
        userTypes: "",
        email: "",
        timezone: "",
        language: "",
        country: "",
        isDriver: false,
        driverTag: "",
        sendEmail: false,
      });
    };
  }, []);

  const handleChange = (event) => {
    const { name } = event.target;

    if (name === "isDriver" || name === "sendEmail") {
      setFormData((prevValues) => ({
        ...prevValues,
        [name]: !formData[name],
        //   event.target.checked || event.target.checked === "true"
        //     ? true
        //     : false,
      }));
    } else {
      setFormData((prevValues) => ({
        ...prevValues,
        [name]: event.target.value,
      }));
    }
  };

  return (
    <form id="createAssetForm" className="formContainer">
      <div className="groupContainer">
        <h1 className=" font-bold text-inputsGroupTitle text-pageTitle">
          <FormattedMessage id="users.userDetailsText"></FormattedMessage>
        </h1>
        <InputWithLabel
          id={"firstName"}
          name={"firstName"}
          labelLngId={"users.detailsField1"}
          labelSize={"text-extraSmall"}
          labelColor={"text-textSecondary"}
          handleChange={handleChange}
          value={formData.firstName}
        ></InputWithLabel>
        <InputWithLabel
          id={"lastName"}
          name={"lastName"}
          labelLngId={"users.detailsField2"}
          labelSize={"text-extraSmall"}
          labelColor={"text-textSecondary"}
          handleChange={handleChange}
          value={formData.lastName}
        ></InputWithLabel>
        <InputWithLabel
          id={"fixedPhone"}
          name={"fixedPhone"}
          labelLngId={"users.detailsField3"}
          labelSize={"text-extraSmall"}
          labelColor={"text-textSecondary"}
          handleChange={handleChange}
          value={formData.fixedPhone}
        ></InputWithLabel>
        <InputWithLabel
          id={"mobilePhone"}
          name={"mobilePhone"}
          labelLngId={"users.detailsField4"}
          labelSize={"text-extraSmall"}
          labelColor={"text-textSecondary"}
          handleChange={handleChange}
          value={formData.mobilePhone}
        ></InputWithLabel>

        <div className="w-full bg-white rounded-md h-[55px] flex flex-col py-1 px-3">
          <label
            htmlFor={"role"}
            className={"text-extraSmall text-textSecondary"}
          >
            <FormattedMessage id="devices.inputLabel2"></FormattedMessage>
          </label>
          <SelectInput
            id="role"
            name="role"
            className=" outline-none text-createAssetInputText text-blackPrimary"
            options={options3}
            onChange={handleChange}
          ></SelectInput>
        </div>

        <div className="w-full bg-white rounded-md h-[55px] flex flex-col py-1 px-3">
          <label
            htmlFor={"userGroups"}
            className={"text-extraSmall text-textSecondary"}
          >
            <FormattedMessage id="users.detailsField6"></FormattedMessage>
          </label>
          <SelectInput
            id="userGroups"
            name="userGroups"
            className=" outline-none text-createAssetInputText text-blackPrimary"
            options={options3}
            onChange={handleChange}
          ></SelectInput>
        </div>

        <div className="w-full bg-white rounded-md h-[55px] flex flex-col py-1 px-3">
          <label
            htmlFor={"commandGroups"}
            className={"text-extraSmall text-textSecondary"}
          >
            <FormattedMessage id="users.detailsField7"></FormattedMessage>
          </label>
          <SelectInput
            id="commandGroups"
            name="commandGroups"
            className=" outline-none text-createAssetInputText text-blackPrimary"
            options={options3}
            onChange={handleChange}
          ></SelectInput>
        </div>

        <div className="w-full bg-white rounded-md h-[55px] flex flex-col py-1 px-3">
          <label
            htmlFor={"authorizedMobileApps"}
            className={"text-extraSmall text-textSecondary"}
          >
            <FormattedMessage id="users.createField8"></FormattedMessage>
          </label>
          <SelectInput
            id="authorizedMobileApps"
            name="authorizedMobileApps"
            className=" outline-none text-createAssetInputText text-blackPrimary"
            options={options3}
            onChange={handleChange}
          ></SelectInput>
        </div>

        <div className="w-full bg-white rounded-md h-[55px] flex flex-col py-1 px-3">
          <label
            htmlFor={"userTypes"}
            className={"text-extraSmall text-textSecondary"}
          >
            <FormattedMessage id="users.createField9"></FormattedMessage>
          </label>
          <SelectInput
            id="userTypes"
            name="userTypes"
            className=" outline-none text-createAssetInputText text-blackPrimary"
            options={options3}
            onChange={handleChange}
          ></SelectInput>
        </div>
      </div>

      <div className="groupContainer">
        <h1 className=" font-bold text-inputsGroupTitle text-pageTitle">
          <FormattedMessage id="users.userDetailsText2"></FormattedMessage>
        </h1>
        <InputWithLabel
          id={"email"}
          name={"email"}
          labelLngId={"users.detailsField9"}
          labelSize={"text-extraSmall"}
          labelColor={"text-textSecondary"}
          handleChange={handleChange}
          value={formData.email}
        ></InputWithLabel>
      </div>

      <div className="groupContainer">
        <h1 className=" font-bold text-inputsGroupTitle text-pageTitle">
          <FormattedMessage id="users.userDetailsText3"></FormattedMessage>
        </h1>

        <div className="w-full bg-white rounded-md h-[55px] flex flex-col py-1 px-3">
          <label
            htmlFor={"timezone"}
            className={"text-extraSmall text-textSecondary"}
          >
            <FormattedMessage id="users.detailsField11"></FormattedMessage>
          </label>
          <SelectInput
            id="timezone"
            name="timezone"
            className=" outline-none text-createAssetInputText text-blackPrimary"
            options={options3}
            onChange={handleChange}
          ></SelectInput>
        </div>

        <div className="w-full bg-white rounded-md h-[55px] flex flex-col py-1 px-3">
          <label
            htmlFor={"language"}
            className={"text-extraSmall text-textSecondary"}
          >
            <FormattedMessage id="users.detailsField12"></FormattedMessage>
          </label>
          <SelectInput
            id="language"
            name="language"
            className=" outline-none text-createAssetInputText text-blackPrimary"
            options={options3}
            onChange={handleChange}
          ></SelectInput>
        </div>

        <div className="w-full bg-white rounded-md h-[55px] flex flex-col py-1 px-3">
          <label
            htmlFor={"country"}
            className={"text-extraSmall text-textSecondary"}
          >
            <FormattedMessage id="users.detailsField13"></FormattedMessage>
          </label>
          <SelectInput
            id="country"
            name="country"
            className=" outline-none text-createAssetInputText text-blackPrimary"
            options={options3}
            onChange={handleChange}
          ></SelectInput>
        </div>
      </div>

      <div className="groupContainer">
        <h1 className=" font-bold text-inputsGroupTitle text-pageTitle">
          <FormattedMessage id="users.userDetailsText4"></FormattedMessage>
        </h1>

        <Checkbox
          labelLngId={"users.createField14Label"}
          labelSize={"text-text16"}
          labelColor={"text-textSecondary"}
          name={"isDriver"}
          checked={formData.isDriver === true}
          handleChange={handleChange}
        ></Checkbox>

        <div className="w-full bg-white rounded-md h-[55px] flex flex-col py-1 px-3 mb-2">
          <label
            htmlFor={"driverTag"}
            className={"text-extraSmall text-textSecondary"}
          >
            <FormattedMessage id="users.createField15Label"></FormattedMessage>
          </label>
          <SelectInput
            id="driverTag"
            name="driverTag"
            className=" outline-none text-createAssetInputText text-blackPrimary"
            options={options3}
            onChange={handleChange}
          ></SelectInput>
        </div>

        <Checkbox
          labelLngId={"users.createField16Label"}
          labelSize={"text-text16"}
          labelColor={"text-textSecondary"}
          name={"sendEmail"}
          checked={formData.sendEmail === true}
          handleChange={handleChange}
        ></Checkbox>
      </div>
    </form>
  );
};

CreateUser.propTypes = {
  formData: PropTypes.object,
  setFormData: PropTypes.func,
};

export default CreateUser;
