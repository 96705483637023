import React, { useEffect, useRef, useState, useCallback } from "react";
import { PropTypes } from "prop-types";
import { MarkerClusterer } from "@googlemaps/markerclusterer";
import { useMap } from "@vis.gl/react-google-maps";

import MarkerWithRef from "./MarkerWithRef";
import customIcon from "../../../../assets/icons/Panel/map-icons/vehicle-moving.svg";

const Markers = ({ points }) => {
  const map = useMap();
  const [markers, setMarkers] = useState([]);
  const clusterer = useRef();

  useEffect(() => {
    if (!map) return;
    if (!clusterer.current) {
      clusterer.current = new MarkerClusterer({
        map,
        onClusterClick: (event) => {
          map.panTo(event.latLng);

          map.setZoom(map.getZoom() + 3);
        },
      });

      // console.log("THis is clusterer:", clusterer);
    }
  }, [map]);

  // Update markers
  useEffect(() => {
    clusterer.current?.clearMarkers();

    clusterer.current?.addMarkers(Object.values(markers));
  }, [markers]);

  const setMarkerRef = (marker, key) => {
    if (marker && markers[key]) return;
    if (!marker && !markers[key]) return;

    setMarkers((prev) => {
      if (marker) {
        return { ...prev, [key]: marker };
      } else {
        const newMarkers = { ...prev };

        delete newMarkers[key];

        return newMarkers;
      }
    });
  };

  const handleClick = useCallback((event) => {
    if (!map) return;
    if (!event.latLng) return;
    // console.log("marker clicked:", event.latLng.toString());

    // console.log("This is event.latLng:", event.latLng);

    map.panTo(event.latLng);
  });

  return (
    <>
      {points.map((point, index) => (
        <MarkerWithRef
          ref={(marker) => setMarkerRef(marker, index)}
          key={index}
          icon={customIcon}
          position={{ lat: point.lat, lng: point.lng }}
          details={point}
          onClick={handleClick}
        ></MarkerWithRef>
      ))}
    </>
  );
};

Markers.propTypes = {
  points: PropTypes.Object,
};

export default Markers;
