import React from "react";
import PropTypes from "prop-types";

const HorizontalLine = ({
  openSideMenu,
  marginVertical,
  paddingHorizontal,
}) => {
  console.log("This is openSideMenu: ", openSideMenu);

  return (
    openSideMenu === true && (
      <div className={` ${marginVertical} ${paddingHorizontal}`}>
        <hr className=" h-[2px] bg-white opacity-20 "></hr>
      </div>
    )
  );
};

HorizontalLine.propTypes = {
  openSideMenu: PropTypes.bool,
  marginVertical: PropTypes.string,
  paddingHorizontal: PropTypes.string,
};

export default HorizontalLine;
