import React from "react";
import PropTypes from "prop-types";

const ProgressBar = ({ title, value }) => {
  return (
    <div className=" space-y-[6px] bg-[#EDF9FB] p-3 rounded-md">
      <div className="flex justify-between items-center text-[14px] font-albertSansMedium">
        <h1 className="  text-textSecondary">{title}</h1>
        <span className="  text-[#484C57]">{value}</span>
      </div>
      <div className="w-full h-3 bg-progressBarBg rounded-full overflow-hidden">
        <div className="w-1/2 h-full bg-[#51B3B9] rounded-full"></div>
      </div>
    </div>
  );
};

ProgressBar.propTypes = {
  title: PropTypes.object,
  value: PropTypes.number,
};

export default ProgressBar;
