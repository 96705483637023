import React, { useState, useEffect } from "react";

import { FormattedMessage } from "react-intl";

import { useDispatch, useSelector } from "react-redux";
import {
  closeModal,
  setAllEvents,
} from "../../../store/calendar/calendarSlice.js";

import SelectInput from "../../../components/Inputs/SelectInput.js";
import TextInput from "../../../components/Inputs/TextInput.js";
import {
  CategoryOptions,
  ReminderType,
  ScheduleTypes,
  TypeOfMaintenance,
  Tyres,
} from "../../../constants/index.js";

import closeIcon from "../../../assets/icons/Panel/Logout/close-icon.svg";
import searchIcon from "../../../assets/icons/Panel/maintenance/searchIcon.svg";
import DateInput from "../../../components/Inputs/DateInput.js";
import Checkbox from "../../../components/Inputs/Checkbox.js";

import dayjs from "dayjs";

const CreateEvent = () => {
  const dispatch = useDispatch();
  const daySelected = useSelector((state) => state.calendar.daySelected);
  const selectedEvent = useSelector((state) => state.calendar.selectedEvent);
  const allEvents = useSelector((state) => state.calendar.allEvents);

  const [formData, setFormData] = useState({
    category: selectedEvent ? selectedEvent.category : "maintenance",
    vehicle: selectedEvent ? selectedEvent.vehicle : "",
    typeOfMaintenance: selectedEvent ? selectedEvent.typeOfMaintenance : "",
    tyres: selectedEvent ? selectedEvent.tyres : "",
    firm: selectedEvent ? selectedEvent.firm : "",
    driver: selectedEvent ? selectedEvent.driver : "",
    numberOfInvoice: selectedEvent ? selectedEvent.numberOfInvoice : "",
    amountOfInvoice: selectedEvent ? selectedEvent.amountOfInvoice : "",
    notes: selectedEvent ? selectedEvent.notes : "",
    reminderType: selectedEvent ? selectedEvent.reminderType : "Date",
    datePlanned: selectedEvent ? selectedEvent.datePlanned : "",
    startDate: selectedEvent ? selectedEvent.startDate : "",
    endDate: selectedEvent ? selectedEvent.endDate : "",
    kmOfVehicle: selectedEvent ? selectedEvent.kmOfVehicle : "",
    plannedKm: selectedEvent ? selectedEvent.plannedKm : "",
    periodic: selectedEvent ? selectedEvent.periodic : false,
    labelColor: selectedEvent ? selectedEvent.labelColor : "#00FFFF",
    selectedDay: daySelected,
    // timeCreated: selectedEvent ? selectedEvent.timeCreated : dayjs().calendar(),
    timeCreated: selectedEvent
      ? selectedEvent.timeCreated
      : dayjs().toISOString(),
    // Converts milliseconds back to a date string
    // const readableDate = new Date(selectedDay).toLocaleDateString("en-US");
    id: selectedEvent ? selectedEvent.id : Date.now(),
  });

  const hideModal = () => {
    dispatch(closeModal());
  };

  const handleChange = (event) => {
    const { id, name, value } = event.target;

    if (id === "category") {
      setFormData((prevValue) => ({
        ...prevValue,
        [id]: event.target.value,
        labelColor: ScheduleTypes.find((item) => item.type === value).color,
      }));
    }

    if (name === "periodic") {
      setFormData((prevValue) => ({
        ...prevValue,
        [name]: !formData[name],
      }));
    } else {
      setFormData((prevValue) => ({
        ...prevValue,
        [id]: event.target.value,
      }));
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    console.log("This is formdate after save:", formData);

    const newEvents = [...allEvents, formData];

    try {
      dispatch(setAllEvents(newEvents));

      localStorage.setItem("allEvents", JSON.stringify(newEvents));

      dispatch(closeModal());
    } catch (error) {
      console.error("Failed to save events:", error);
    }
  };

  // const handleDelete = async () => {
  //   try {
  //     const updatedAfterDelete = allEvents.filter(
  //       (event) => event.id !== selectedEvent.id
  //     );

  //     dispatch(setAllEvents(updatedAfterDelete));

  //     localStorage.setItem("allEvents", JSON.stringify(updatedAfterDelete));

  //     dispatch(closeModal());
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  // const handleEdit = () => {
  //   const indexOfItemToEdit = allEvents.indexOf(selectedEvent);

  //   console.log(indexOfItemToEdit);

  //   const ourEvents = [...allEvents];

  //   ourEvents.splice(indexOfItemToEdit, 1, formData);

  //   console.log(ourEvents);

  //   dispatch(setAllEvents(ourEvents));

  //   localStorage.setItem("allEvents", JSON.stringify(ourEvents));

  //   dispatch(closeModal());
  // };

  useEffect(() => {
    return () => {
      hideModal();
    };
  }, []);

  return (
    <div className="createEventModal">
      <form
        className="bg-white rounded-lg shadow-2xl w-full max-w-[1000px] h-full max-h-[700px] py-5 px-6 overflow-y-scroll"
        onSubmit={handleSubmit}
      >
        <header className="flex justify-between items-center pb-4 border-b-2 border-gray-100 gap-5">
          <div className="flex flex-1 items-center gap-5">
            <h2 className=" text-text18 font-bold">
              <FormattedMessage id="scheduleModal.category"></FormattedMessage>
            </h2>
            <div className="w-full max-w-[280px] h-10 px-2 text-extraSmall text-blackPrimary border rounded-md">
              <SelectInput
                id="category"
                name="category"
                className="w-full h-full outline-none"
                options={CategoryOptions}
                onChange={handleChange}
              ></SelectInput>
            </div>
          </div>
          <img
            src={closeIcon}
            onClick={hideModal}
            className=" cursor-pointer size-4 active:opacity-75 hover:opacity-85"
          />
        </header>

        <div className="flex w-full justify-between gap-14 ">
          <div className="w-full md:w-1/2">
            <TextInput
              inputClassName={
                "w-full text-extraSmall flex pl-2 h-10 border border-slate-100 rounded-md my-5"
              }
              placeholderLngId={"scheduleModal.input1Placeholder"}
              inputId={"vehicle"}
              formData={formData.vehicle}
              handleChange={handleChange}
              icon={searchIcon}
            />
          </div>

          <div className="hidden md:flex w-1/2"></div>
        </div>

        <div className=" flex flex-col md:flex-row gap-14">
          <div className="flex flex-1 flex-col gap-4 w-full md:w-1/2">
            <div className="flex flex-1 gap-4 items-center justify-between">
              <h3>
                <FormattedMessage id="scheduleModal.input2Label"></FormattedMessage>
              </h3>

              <div className="w-full max-w-[280px] h-10 px-2 text-extraSmall text-blackPrimary border rounded-md">
                <SelectInput
                  id="typeOfMaintenance"
                  name="typeOfMaintenance"
                  className="w-full h-full outline-none"
                  options={TypeOfMaintenance}
                  onChange={handleChange}
                ></SelectInput>
              </div>
            </div>

            <div className="flex flex-1 gap-4 items-center justify-between">
              <h3>
                <FormattedMessage id="scheduleModal.input3Label"></FormattedMessage>
              </h3>

              <div className="w-full max-w-[280px] h-10 px-2 text-extraSmall text-blackPrimary border rounded-md">
                <SelectInput
                  id="tyres"
                  name="tyres"
                  className="w-full h-full outline-none"
                  options={Tyres}
                  onChange={handleChange}
                ></SelectInput>
              </div>
            </div>

            <div className="flex flex-1 gap-4 items-center justify-between">
              <h3>
                <FormattedMessage id="scheduleModal.input4"></FormattedMessage>
              </h3>

              <div className="w-full max-w-[280px] h-10 text-extraSmall text-blackPrimary border rounded-md">
                <TextInput
                  inputClassName={"w-full h-10 flex pl-3 rounded-md"}
                  placeholderLngId={"scheduleModal.input4"}
                  inputId={"firm"}
                  formData={formData.firm}
                  handleChange={handleChange}
                  icon={searchIcon}
                />
              </div>
            </div>

            <div className="flex flex-1 gap-4 items-center justify-between">
              <h3>
                <FormattedMessage id="scheduleModal.input5"></FormattedMessage>
              </h3>

              <div className="w-full max-w-[280px] h-10 text-extraSmall text-blackPrimary border rounded-md">
                <TextInput
                  inputClassName={"w-full h-10 flex pl-3 rounded-md"}
                  placeholderLngId={"scheduleModal.input5"}
                  inputId={"driver"}
                  formData={formData.driver}
                  handleChange={handleChange}
                  icon={searchIcon}
                />
              </div>
            </div>

            <div className="flex flex-1 gap-4 items-center justify-between">
              <h3>
                <FormattedMessage id="scheduleModal.input6Label"></FormattedMessage>
              </h3>

              <div className="w-full max-w-[280px] h-10 text-extraSmall text-blackPrimary border rounded-md">
                <TextInput
                  inputClassName={"w-full h-10 flex pl-3 rounded-md"}
                  placeholderLngId={"app.emptyLabel"}
                  inputId={"numberOfInvoice"}
                  formData={formData.numberOfInvoice}
                  handleChange={handleChange}
                />
              </div>
            </div>

            <div className="flex flex-1 gap-4 items-center justify-between">
              <h3>
                <FormattedMessage id="scheduleModal.input7Label"></FormattedMessage>
              </h3>

              <div className="w-full max-w-[280px] h-10 text-extraSmall text-blackPrimary border rounded-md">
                <TextInput
                  inputClassName={"w-full h-10 flex pl-3 rounded-md"}
                  placeholderLngId={"app.emptyLabel"}
                  inputId={"amountOfInvoice"}
                  formData={formData.amountOfInvoice}
                  handleChange={handleChange}
                />
              </div>
            </div>

            <div className="flex flex-1 gap-4 justify-between">
              <h3>
                <FormattedMessage id="scheduleModal.input8Label"></FormattedMessage>
              </h3>

              <div className="w-full max-w-[280px] text-extraSmall text-blackPrimary border rounded-md overflow-hidden p-2">
                <textarea
                  rows={4}
                  value={formData.notes}
                  id="notes"
                  onChange={handleChange}
                  className=" w-full outline-none"
                />
              </div>
            </div>
          </div>

          <div className="flex flex-1 flex-col gap-4 w-full md:w-1/2">
            <div className="flex flex-1 gap-4 items-center justify-between">
              <h3>
                <FormattedMessage id="scheduleModal.input9Label"></FormattedMessage>
              </h3>

              <div className="w-full max-w-[280px] h-10 px-2 text-extraSmall text-blackPrimary border rounded-md">
                <SelectInput
                  id="reminderType"
                  name="reminderType"
                  className="w-full h-full outline-none"
                  options={ReminderType}
                  onChange={handleChange}
                ></SelectInput>
              </div>
            </div>

            <div className="flex flex-1 gap-4 items-center justify-between">
              <h3>
                <FormattedMessage id="scheduleModal.input10Label"></FormattedMessage>
              </h3>

              <DateInput
                parentClassName={"w-full max-w-[280px]"}
                className={
                  "h-10 px-2 text-extraSmall text-gray-400 border rounded-md"
                }
                inputId={"datePlanned"}
                value={formData.datePlanned}
                handleChange={handleChange}
              />
            </div>

            <div className="flex flex-1 gap-4 items-center justify-between">
              <h3>
                <FormattedMessage id="scheduleModal.input11Label"></FormattedMessage>
              </h3>

              <DateInput
                parentClassName={"w-full max-w-[280px]"}
                className={
                  "h-10 px-2 text-extraSmall border rounded-md text-gray-400"
                }
                inputId={"startDate"}
                value={formData.startDate}
                handleChange={handleChange}
              />
            </div>

            <div className="flex flex-1 gap-4 items-center justify-between">
              <h3>
                <FormattedMessage id="scheduleModal.input12Label"></FormattedMessage>
              </h3>

              <DateInput
                parentClassName={"w-full max-w-[280px]"}
                className={
                  "h-10 px-2 text-extraSmall border rounded-md text-gray-400"
                }
                inputId={"endDate"}
                value={formData.endDate}
                handleChange={handleChange}
              />
            </div>

            <div className="flex flex-1 gap-4 items-center justify-between">
              <h3>
                <FormattedMessage id="scheduleModal.input13Label"></FormattedMessage>
              </h3>

              <div className="w-full max-w-[280px] h-10 text-extraSmall text-blackPrimary border rounded-md">
                <TextInput
                  inputClassName={"w-full h-10 flex pl-3 rounded-md"}
                  placeholderLngId={"app.emptyLabel"}
                  inputId={"kmOfVehicle"}
                  formData={formData.kmOfVehicle}
                  handleChange={handleChange}
                />
              </div>
            </div>

            <div className="flex flex-1 gap-4 items-center justify-between">
              <h3>
                <FormattedMessage id="scheduleModal.input14Label"></FormattedMessage>
              </h3>

              <div className="w-full max-w-[280px] h-10 text-extraSmall text-blackPrimary border rounded-md">
                <TextInput
                  inputClassName={"w-full h-10 flex pl-3 rounded-md"}
                  placeholderLngId={"app.emptyLabel"}
                  inputId={"plannedKm"}
                  formData={formData.plannedKm}
                  handleChange={handleChange}
                />
              </div>
            </div>

            <div className="flex flex-1 gap-4 justify-between items-center">
              <h3>
                <FormattedMessage id="scheduleModal.input15Label"></FormattedMessage>
              </h3>

              <div className="w-full max-w-[280px]">
                <Checkbox
                  id={"periodic"}
                  name={"periodic"}
                  checked={formData.periodic === true}
                  handleChange={handleChange}
                  disabled={false}
                />
              </div>
            </div>
          </div>
        </div>

        <footer className="flex justify-end w-full p-3 mt-5">
          {/* {selectedEvent ? (
            <div className="flex gap-2 items-center">
              <button
                type="button"
                className="bg-blue-500 hover:bg-blue-600 px-6 py-2 rounded-md text-white"
                onClick={handleDelete}
              >
                Delete
              </button>
              <button
                type="button"
                className="bg-blue-500 hover:bg-blue-600 px-6 py-2 rounded-md text-white"
                onClick={handleEdit}
              >
                Save changes
              </button>
            </div>
          ) : (
            <button
              type="submit"
              className="bg-blue-500 hover:bg-blue-600 px-6 py-2 rounded-md text-white"
            >
              Save
            </button>
          )} */}

          <button
            type="submit"
            className=" bg-redPrimary w-full max-w-[116px] py-2 rounded-md text-white"
          >
            Save
          </button>
        </footer>
      </form>
    </div>
  );
};

export default CreateEvent;
