import React from "react";
import { PropTypes } from "prop-types";

import closeIcon from "../../../assets/icons/Panel/Raports/close.svg";

const SelectedUserField = ({ selectedRows, setSelectedRows, setIsChecked }) => {
  const removeSelectedUser = () => {
    setSelectedRows([]);

    setIsChecked((prevValues) => ({
      ...prevValues,
      [selectedRows[0].id]: false,
    }));
  };

  return (
    <div className="bg-textSecondary text-extraSmall text-white rounded-md flex justify-between items-center h-[35px] px-4 -mb-[10px] mt-[8px]">
      {selectedRows[0].data[0].row}
      <img
        src={closeIcon}
        className=" cursor-pointer active:opacity-75"
        onClick={removeSelectedUser}
      ></img>
    </div>
  );
};

SelectedUserField.propTypes = {
  selectedRows: PropTypes.Object,
  setSelectedRows: PropTypes.func,
  setIsChecked: PropTypes.func,
};

export default SelectedUserField;
