import React from "react";
import PropTypes from "prop-types";

import { useDispatch, useSelector } from "react-redux";
import useDayClassHook from "../../../../../utils/hooks/useDayClassHook";
import {
  setDaySelected,
  setSmallCalendarMonth,
} from "../../../../../store/calendar/calendarSlice";

const SmallCalendarDay = ({ day, currentMonthIndex }) => {
  const useDayClass = useDayClassHook(day);

  const dispatch = useDispatch();
  const monthIndex = useSelector((state) => state.calendar.monthIndex);

  const handleClick = () => {
    dispatch(setSmallCalendarMonth(currentMonthIndex));

    dispatch(setDaySelected(day.valueOf()));
  };

  return (
    <button
      onClick={handleClick}
      className={`my-1 mx-auto ${useDayClass} hover:bg-calendarDayHover rounded-full`}
    >
      <span
        style={{
          color: day.month() !== monthIndex && "rgba(0,0,0,0.4)",
        }}
      >
        {day.format("D")}
      </span>
    </button>
  );
};

SmallCalendarDay.propTypes = {
  day: PropTypes.object,
  currentMonthIndex: PropTypes.number,
};

export default SmallCalendarDay;
