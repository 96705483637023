import React from "react";
import PropTypes from "prop-types";

const AlertElement = ({ color, icon, text, value }) => {
  return (
    <div className="min-w-[30%] flex flex-col gap-3  items-center justify-center ">
      <div
        // className="w-11 h-11 flex justify-center items-center rounded-xl rotate-45"
        className="w-[80px] h-[56px] flex justify-center items-center rounded-lg bg-[#00B0CA12]"
        style={{ backgroundColor: color }}
      >
        {/* <img src={icon} alt="icon" className=" -rotate-45"></img> */}
        <img src={icon} alt="icon"></img>
      </div>
      <div className="flex flex-col items-center font-albertSansMedium ">
        <span className="text-[12px] text-cardLink">{text}</span>
        <span className=" text-[#464A55] text-cardTitle"> {value} </span>
      </div>
    </div>
  );
};

AlertElement.propTypes = {
  text: PropTypes.string,
  icon: PropTypes.string,
  color: PropTypes.string,
  value: PropTypes.number,
};

export default AlertElement;
