import { useEffect } from "react";
import dayjs from "dayjs";
import { useSelector } from "react-redux";

const useDayjsLocale = () => {
  const { currentLanguage } = useSelector((state) => state.language);

  useEffect(() => {
    dayjs.locale(currentLanguage), [currentLanguage];
  }, [currentLanguage]);
};

export default useDayjsLocale;
