import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

const PinModalElement = ({ icon, title, text }) => {
  console.log(icon, title, text);

  return (
    <div
      className="pin-modal-element"
      style={{ backgroundColor: text === undefined ? "#F4F8F8" : "white" }}
    >
      <img src={icon} width={"20px"} height={"20px"}></img>
      <div className=" flex flex-col leading-5">
        <span className="text-small text-[#999999]">
          <FormattedMessage id={title}></FormattedMessage>
        </span>
        <span className="text-text16 text-blackPrimary font-albertSansBold">
          {text === undefined ? "-" : text}
        </span>
      </div>
    </div>
  );
};

PinModalElement.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
};

export default PinModalElement;
