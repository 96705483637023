import React, { useState, useCallback, useRef } from "react";

import { APIProvider, Map } from "@vis.gl/react-google-maps";

import CircularProgress from "@mui/material/CircularProgress";

import Markers from "./components/Markers/Markers";
import CustomControl from "./components/controls/CustomControl";
import CustomControlInfoWindow from "./components/controls/CustomControl-InfoWindow";
import MapFilter from "./components/Filter/MapFilter";
import TrafficLayer from "./components/TrafficLayer";
import Autocomplete from "./components/autocomplete/Autocomplete";
import MapHandler from "./components/autocomplete/MapHandler";
import { useWindowDimensions } from "../../utils/GetDimensions";

import funnelIcon from "../../assets/icons/Panel/map-icons/CustomControl-Icons/funnel-icon.svg";
import funnelIconInactive from "../../assets/icons/Panel/map-icons/CustomControl-Icons/funnel-icon-inactive.svg";
import mapTypeIconActive from "../../assets/icons/Panel/map-icons/CustomControl-Icons/map-type-icon-active.svg";
import mapTypeIconInactive from "../../assets/icons/Panel/map-icons/CustomControl-Icons/map-type-icon-inactive.svg";
import layerIconInactive from "../../assets/icons/Panel/map-icons/CustomControl-Icons/layer-icon-inactive.svg";
import layerIconActive from "../../assets/icons/Panel/map-icons/CustomControl-Icons/layer-icon-active.svg";

const MapView = () => {
  const { width } = useWindowDimensions();
  const [mapType, setMapType] = useState("roadmap");
  const mapRef = useRef();
  const [isTrafficVisible, setIsTrafficVisible] = useState(false);
  const [selectedControl, setSelectedControl] = useState();
  const [toggleInfoWindow1, setToggleInfoWindow1] = useState(false);
  const [toggleInfoWindow2, setToggleInfoWindow2] = useState(false);
  const [filterWidth, setFilterWidth] = useState(0);
  const [mapIsReady, setMapIsReady] = useState(false);
  const [selectedPlace, setSelectedPlace] = useState(null);

  const markers = [
    {
      locationName: "driver 1",
      lat: 41.22298668072267,
      lng: 19.767180306936034,
      address: `driver 1 location`,
    },
    {
      locationName: "driver 2",
      lat: 41.22198668075267,
      lng: 19.767180306936034,
      address: `driver 1 location`,
    },
    {
      locationName: "driver 3",
      lat: 40.887464185951565,
      lng: 19.838591431390252,
      address: `driver 2 location <br> driver 222`,
    },
    {
      locationName: "driver 4",
      lat: 40.56483888172677,
      lng: 19.85507092164892,
      address: `driver 3 location`,
    },
    {
      locationName: "driver 5",
      lat: 40.2249085042007,
      lng: 19.90333479903347,
      address: `driver 4 location`,
    },
    // Adding 20 more markers
    {
      locationName: "driver 6",
      lat: 41.223,
      lng: 19.767,
      address: `driver 6 location`,
    },
    {
      locationName: "driver 7",
      lat: 41.224,
      lng: 19.768,
      address: `driver 7 location`,
    },
    {
      locationName: "driver 8",
      lat: 41.225,
      lng: 19.769,
      address: `driver 8 location`,
    },
    {
      locationName: "driver 9",
      lat: 41.226,
      lng: 19.77,
      address: `driver 9 location`,
    },
    {
      locationName: "driver 10",
      lat: 41.227,
      lng: 19.771,
      address: `driver 10 location`,
    },
    {
      locationName: "driver 11",
      lat: 41.228,
      lng: 19.772,
      address: `driver 11 location`,
    },
    {
      locationName: "driver 12",
      lat: 41.229,
      lng: 19.773,
      address: `driver 12 location`,
    },
    {
      locationName: "driver 13",
      lat: 41.23,
      lng: 19.774,
      address: `driver 13 location`,
    },
    {
      locationName: "driver 14",
      lat: 41.231,
      lng: 19.775,
      address: `driver 14 location`,
    },
    {
      locationName: "driver 15",
      lat: 41.232,
      lng: 19.776,
      address: `driver 15 location`,
    },
    {
      locationName: "driver 16",
      lat: 41.233,
      lng: 19.777,
      address: `driver 16 location`,
    },
    {
      locationName: "driver 17",
      lat: 41.234,
      lng: 19.778,
      address: `driver 17 location`,
    },
    {
      locationName: "driver 18",
      lat: 41.235,
      lng: 19.779,
      address: `driver 18 location`,
    },
    {
      locationName: "driver 19",
      lat: 41.236,
      lng: 19.78,
      address: `driver 19 location`,
    },
    {
      locationName: "driver 20",
      lat: 41.237,
      lng: 19.781,
      address: `driver 20 location`,
    },
    {
      locationName: "driver 21",
      lat: 41.238,
      lng: 19.782,
      address: `driver 21 location`,
    },
    {
      locationName: "driver 22",
      lat: 41.239,
      lng: 19.783,
      address: `driver 22 location`,
    },
    {
      locationName: "driver 23",
      lat: 41.24,
      lng: 19.784,
      address: `driver 23 location`,
    },
    {
      locationName: "driver 24",
      lat: 41.241,
      lng: 19.785,
      address: `driver 24 location`,
    },
    {
      locationName: "driver 25",
      lat: 41.242,
      lng: 19.786,
      address: `driver 25 location`,
    },
  ];

  const onLoad = useCallback((map) => {
    mapRef.current = map;
  }, []);

  const handleTilesLoaded = useCallback(() => setMapIsReady(true), []);

  const toggleTrafficLayer = useCallback(() => {
    setIsTrafficVisible((prevState) => !prevState);
  }, []);

  const toggleFunction = (param) => {
    if (param === 1) {
      setToggleInfoWindow2(false);

      setToggleInfoWindow1(!toggleInfoWindow1);
    } else if (param === 2) {
      setToggleInfoWindow1(false);

      setToggleInfoWindow2(!toggleInfoWindow2);
    } else {
      toggleFilter();
    }
  };

  const toggleFilter = () => {
    if (filterWidth === 0) {
      setFilterWidth(350);
    } else {
      setFilterWidth(0);
    }
  };

  return (
    // w-[85%]
    <div className={`relative`}>
      <APIProvider
        // eslint-disable-next-line no-undef
        apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
        libraries={["places"]}
      >
        <Map
          onTilesLoaded={handleTilesLoaded}
          mapId={"bc6ecf2d30222d6d"}
          mapTypeId={mapType}
          style={{ width: "100vw", height: "100vh" }}
          defaultCenter={{ lat: 40.471483, lng: 19.701262 }}
          defaultZoom={width > 640 ? 10 : 9}
          gestureHandling={"greedy"}
          disableDefaultUI={true}
          onLoad={onLoad}
        >
          <Autocomplete
            onPlaceSelect={setSelectedPlace}
            filterWidth={filterWidth}
            place={selectedPlace}
          ></Autocomplete>

          <MapHandler place={selectedPlace}></MapHandler>
          <Markers points={markers} />
          {isTrafficVisible && <TrafficLayer />}
        </Map>
        {!mapIsReady && (
          <div className="loading-overlay">
            <CircularProgress style={{ color: "#E60000" }} />
          </div>
        )}
      </APIProvider>

      <div
        className="absolute top-0 left-0 bottom-0 bg-white"
        style={{
          width: filterWidth,
          paddingLeft: filterWidth === 0 ? 0 : "20px",
          paddingRight: filterWidth === 0 ? 0 : "20px",
          paddingTop: filterWidth === 0 ? 0 : "10px",
          paddingBottom: filterWidth === 0 ? 0 : "10px",
          transition: "width padding",
          transitionDelay: ".2s",
          transitionTimingFunction: "ease-in-out",
          transitionDuration: ".3s",
        }}
      >
        <MapFilter toggleFunction={() => toggleFunction(3)}></MapFilter>

        <div
          className="absolute top-10 flex flex-col gap-7 "
          style={{
            left: filterWidth + 28,
            transition: "left",
            transitionDelay: ".2s",
            transitionTimingFunction: "ease-in-out",
            transitionDuration: ".3s",
          }}
        >
          <CustomControl
            icon={
              selectedControl === "filter" && filterWidth === 350
                ? funnelIcon
                : funnelIconInactive
            }
            toggleFunction={() => toggleFunction(3)}
            setSelectedControl={() => setSelectedControl("filter")}
          ></CustomControl>

          <div className="flex flex-col gap-2 relative">
            <CustomControl
              toggleFunction={() => toggleFunction(1)}
              setSelectedControl={() => setSelectedControl("mapTypeControl")}
              icon={toggleInfoWindow1 ? mapTypeIconActive : mapTypeIconInactive}
            ></CustomControl>

            {toggleInfoWindow1 && (
              <CustomControlInfoWindow
                top={0}
                identifier={1}
                mapType={mapType}
                handleClick={setMapType}
              ></CustomControlInfoWindow>
            )}

            <CustomControl
              isTrafficVisible={isTrafficVisible}
              toggleFunction={() => toggleFunction(2)}
              setSelectedControl={() => setSelectedControl("layerControl")}
              icon={toggleInfoWindow2 ? layerIconActive : layerIconInactive}
            ></CustomControl>

            {toggleInfoWindow2 && (
              <CustomControlInfoWindow
                top={"48px"}
                identifier={2}
                handleClick={toggleTrafficLayer}
                mapType={mapType}
                isTrafficVisible={isTrafficVisible}
              ></CustomControlInfoWindow>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

// MapView.propTypes = {
//   openSideMenu: PropTypes.bool,
// };

export default MapView;
