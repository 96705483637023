import React from "react";
import SmallCalendar from "./components/SmallCalendar";
import TypeSection from "./components/TypeSection/TypeSection";

import { useSelector } from "react-redux";
import { useWindowDimensions } from "../../../../utils/GetDimensions";

const Sidebar = () => {
  const { width } = useWindowDimensions();

  const isSmallCalendarOpen = useSelector(
    (state) => state.calendar.isSmallCalendarOpen
  );

  const widthCondition = () => {
    if (width >= 768 && isSmallCalendarOpen) {
      return "280px";
    } else if (width >= 768 && !isSmallCalendarOpen) {
      return "0px";
    } else {
      return "100%";
    }
  };

  return (
    <div
      className="flex flex-col items-center gap-10 h-full md:overflow-hidden"
      style={{
        width: widthCondition(),
        padding: isSmallCalendarOpen && "8px",
        transition: "padding width",
        transitionDuration: "0.3s",
        transitionTimingFunction: "ease-in-out",
        transitionDelay: "0.1s",
      }}
    >
      <SmallCalendar></SmallCalendar>
      <TypeSection></TypeSection>
    </div>
  );
};

export default Sidebar;
