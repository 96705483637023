import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { IntlProvider } from "react-intl";
import { useSelector } from "react-redux";

import { languageObject } from "./language/index";

import Login from "./screens/Authentication/Login";
import DashboardLayout from "./components/Layout/DashboardLayout";
import Panel from "./screens/HomePanel/Panel";
import MapView from "./screens/Map/MapView";
import Network from "./screens/Network/Network";
import Itineraries from "./screens/Itineraries/Itineraries";
import Explore from "./screens/SmartWay/Explore";
import Raports from "./screens/Raports/Raports";
import Maintenance from "./screens/Maintenance/Maintenance";
import Assets from "./screens/Administration/Assets/Assets";
import AssetGroups from "./screens/Administration/Asset-Groups/AssetGroups";
import Settings from "./screens/Settings/Settings";
import Devices from "./screens/Administration/Devices/Devices";
import Notifications from "./screens/Administration/Notifications/Notifications";
import Users from "./screens/Administration/Users/Users";
import UserGroups from "./screens/Administration/User-Groups/UserGroups";
import Pois from "./screens/Pois/Pois";
import PoisGroups from "./screens/PoisGroups/PoisGroups";
import useDayjsLocale from "./utils/hooks/useDayjsLocale";

function App() {
  useDayjsLocale();

  const { currentLanguage } = useSelector((state) => state.language);

  return (
    <IntlProvider
      locale={currentLanguage}
      messages={languageObject[currentLanguage]}
      defaultLocale="en"
    >
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={<Login />} />

          <Route path="/" element={<DashboardLayout />}>
            <Route index element={<Panel />} />
            <Route path="location" element={<MapView />} />
            <Route path="network" element={<Network />} />
            <Route path="itineraries" element={<Itineraries />} />
            <Route path="explore" element={<Explore />} />
            <Route path="raports" element={<Raports />} />
            <Route path="maintenance" element={<Maintenance />} />
            <Route path="settings" element={<Settings />} />
            <Route path="admin/assets" element={<Assets />} />
            <Route path="admin/asset-groups" element={<AssetGroups />} />
            <Route path="admin/devices" element={<Devices />} />
            <Route path="admin/notifications" element={<Notifications />} />
            <Route path="admin/users" element={<Users />} />
            <Route path="admin/user-groups" element={<UserGroups />} />
            <Route path="admin/pois" element={<Pois />} />
            <Route path="admin/pois-groups" element={<PoisGroups />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </IntlProvider>
  );
}

export default App;
