/* eslint-disable indent */
/* eslint-disable no-nested-ternary */
/* eslint-disable complexity */
/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";

import Table from "../Components/table/Table";
import ReusableBtn from "../../../components/ReusableBtn/ReusableBtn";
import PageTitle from "../../../components/Layout/PageTitle";
import ViewAsset from "../Components/ViewAsset/ViewAsset";

import { NotificationsTable, DeviceDetailTitles } from "../../../constants";

import plusIcon from "../../../assets/icons/Panel/administration/Assets/plus-icon.svg";
import funnelIcon from "../../../assets/icons/Panel/administration/Assets/funnel-icon.svg";
import discardIcon from "../../../assets/icons/Panel/administration/Assets/discardIcon.svg";
import editIconWhite from "../../../assets/icons/Panel/administration/AssetGroup/editIconWhite.svg";
import CreateNewNotification from "./Componets/CreateNewNotification";

const Notifications = () => {
  const [formData, setFormData] = useState({
    name: "",
    description: "",
    assets: {
      applyTo: "",
      assetGroups: [],
    },
    alertTypes: { type: "", alert: [] },
    users: {
      applyTo: "",
      selectedUsers: [],
    },
    methodsAndMessage: {
      PopUp: true,
      Browser: true,
      SMS: false,
      Email: false,
      Push: false,
    },
  });
  const [view, setView] = useState("table");
  const [rowData, setRowData] = useState(null);

  const bodyData = [
    {
      id: 0,
      data: [
        {
          id: 0,
          row: "sddfbgbgn",
        },
        {
          id: 1,
          row: "sddfbfd",
        },
        {
          id: 2,
          row: "6",
        },
        {
          id: 3,
          row: "gghrthrrthr",
        },
        {
          id: 4,
          row: "13/02/21  14:46",
        },
        {
          id: 5,
          row: "lorela.parruca1@vodafone.com",
        },
      ],
    },
  ];

  const handleChange = (event) => {
    const { id } = event.target;

    if (id === "name" || id === "description") {
      setFormData((prevValues) => ({
        ...prevValues,
        [id]: event.target.value,
      }));
    }

    if (id === "All assets" || id === "Specific") {
      setFormData((prevValues) => ({
        ...prevValues,
        assets: {
          ...prevValues.assets,
          applyTo: id,
        },
      }));
    }

    if (id === "All Alert Types" || id === "Specific Alert Types") {
      setFormData((prevValues) => ({
        ...prevValues,
        alertTypes: { ...prevValues.alertTypes, type: id },
      }));
    }

    if (id === "All Users" || id === "Multiple Users" || id === "User Groups") {
      setFormData((prevValues) => ({
        ...prevValues,
        users: {
          ...prevValues.users,
          applyTo: id,
        },
      }));
    }

    if (
      id === "PopUp" ||
      id === "Browser" ||
      id === "SMS" ||
      id === "Email" ||
      id === "Push"
    ) {
      setFormData((prevValues) => ({
        ...prevValues,
        methodsAndMessage: {
          ...prevValues.methodsAndMessage,
          [id]: !prevValues.methodsAndMessage[id],
        },
      }));
    }
  };

  const onSubmit = () => {
    console.log("this is formData:", formData);
  };

  return (
    <div className="pageLayout">
      <div className="w-full flex flex-col gap-2 lg:gap-0 lg:flex-row lg:justify-between lg:items-center">
        <PageTitle
          titleLngId={
            view === "create"
              ? "notifications.createNewNotificationTitle"
              : view === "view"
              ? "notifications.viewNotificationDetails"
              : view === "edit"
              ? "notifications.notificationsDetailsEdit"
              : "notifications.pageTitle"
            // titleLngId
          }
          subTitleLngId={"notifications.subtitle1"}
          subTitle2LngId={"notifications.subtitle2"}
          state={view}
          setState={() => setView("table")}
        ></PageTitle>

        <div className=" flex items-center gap-2 mb-4 lg:mb-0">
          {view === "create" && (
            <ReusableBtn
              icon={discardIcon}
              text={
                <FormattedMessage id="assets.createAssetBtn1"></FormattedMessage>
              }
              textSize={"text-filterBtn"}
              textColor={"text-[#0D0D0D]"}
              width={"100px"}
              maxWidth={""}
              height={"40px"}
              background={"bg-transparent"}
              gap={7}
              paddingHorizontal={"px-1"}
              type={"button"}
              border={"1px solid #0D0D0D"}
              handleClick={() => setView("table")}
            ></ReusableBtn>
          )}

          <ReusableBtn
            icon={
              view === "create"
                ? ""
                : view === "view"
                ? editIconWhite
                : plusIcon
            }
            imgWidth={"14px"}
            text={
              <FormattedMessage
                id={
                  view === "create"
                    ? "notifications.btn2"
                    : view === "table"
                    ? "notifications.btn1"
                    : "notifications.notificationsEditBtn"
                }
              ></FormattedMessage>
            }
            textSize={"text-filterBtn"}
            textColor={"text-[#FFFFFF]"}
            width={"fit-content"}
            maxWidth={""}
            height={view === "create" ? "40px" : "45px"}
            background={"bg-redPrimary"}
            gap={10}
            paddingHorizontal={"px-4"}
            type={"button"}
            handleClick={
              view === "create"
                ? onSubmit
                : view === "view"
                ? () => console.log("edit page")
                : () => setView("create")
            }
          ></ReusableBtn>

          {view === "table" && (
            <ReusableBtn
              icon={funnelIcon}
              text={"(81)"}
              textColor={"text-textSecondary"}
              gap={4}
              height={"45px"}
              background={"bg-white"}
              paddingHorizontal={"px-3"}
              type={"button"}
              handleClick={() => console.log("")}
            ></ReusableBtn>
          )}
        </div>
      </div>

      {view === "create" && (
        <CreateNewNotification
          formData={formData}
          handleChange={handleChange}
          setFormData={setFormData}
        ></CreateNewNotification>
      )}

      {view === "view" && (
        <ViewAsset
          titleLngId={"notifications.notificationsDetailsText"}
          titles={DeviceDetailTitles}
          data={rowData}
        ></ViewAsset>
      )}

      {view === "edit" && <div>Edit</div>}

      {view === "table" && (
        <Table
          version={1}
          headerData={NotificationsTable}
          bodyData={bodyData}
          setState={setView}
          setRowData={setRowData}
        ></Table>
      )}
    </div>
  );
};

export default Notifications;
