import React from "react";
import PropTypes from "prop-types";

import { useDispatch, useSelector } from "react-redux";
import { setSelectedType } from "../../../../../../store/calendar/calendarSlice";

const ReservationType = ({ icon, type, text }) => {
  const dispatch = useDispatch();
  const selectedType = useSelector((state) => state.calendar.selectedType);

  const selectType = () => {
    dispatch(setSelectedType(type));
  };

  return (
    <div className="flex flex-col gap-1 cursor-pointer" onClick={selectType}>
      <span
        className={`rounded-md px-3 py-4 hover:bg-[#00B0CA14] ${
          selectedType === type ? "bg-[#00B0CA14]" : "bg-[#00B0CA09]"
        }`}
      >
        <img src={icon} alt="icon" className=" size-6 mx-auto"></img>
      </span>
      <h3 className="text-xxs mx-auto">{text}</h3>
    </div>
  );
};

ReservationType.propTypes = {
  icon: PropTypes.string,
  type: PropTypes.string,
  text: PropTypes.string,
};

export default ReservationType;
