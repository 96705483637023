import React from "react";
// import { PropTypes } from "prop-types";

import { FormattedMessage } from "react-intl";

const ViewUserDetails = () => {
  return (
    <div id="viewUserDetails" className="formContainer">
      <div className="groupContainer">
        <h1 className=" font-bold text-inputsGroupTitle text-pageTitle">
          <FormattedMessage id="users.userDetailsText"></FormattedMessage>
        </h1>

        <div className=" w-full flex flex-col gap-2 p-4 text-[#222222] text-[18px] bg-white rounded-md border border-[#EDEFF2] ">
          <div className="flex justify-between items-center">
            <FormattedMessage id="users.detailsField1"></FormattedMessage>
            <span>Ada</span>
          </div>
          <div className="flex justify-between items-center">
            <FormattedMessage id="users.detailsField2"></FormattedMessage>
            <span>Nakuci</span>
          </div>
          <div className="flex justify-between items-center">
            <FormattedMessage id="users.detailsField3"></FormattedMessage>
            <span>-</span>
          </div>
          <div className="flex justify-between items-center">
            <FormattedMessage id="users.detailsField4"></FormattedMessage>
            <span>+355692226650</span>
          </div>
          <div className="flex justify-between items-center">
            <FormattedMessage id="users.detailsField5"></FormattedMessage>
            <span>(ALB) Fleet Customer</span>
          </div>
          <div className="flex justify-between items-center">
            <FormattedMessage id="users.detailsField6"></FormattedMessage>
            <span>-</span>
          </div>
          <div className="flex justify-between items-center">
            <FormattedMessage id="users.detailsField7"></FormattedMessage>
            <span>-</span>
          </div>
          <div className="flex justify-between items-center">
            <FormattedMessage id="users.detailsField8"></FormattedMessage>
            <span>-</span>
          </div>
        </div>
      </div>

      <div className="groupContainer">
        <h1 className=" font-bold text-inputsGroupTitle text-pageTitle">
          <FormattedMessage id="users.userDetailsText2"></FormattedMessage>
        </h1>

        <div className=" w-full flex flex-col gap-2 p-4 text-[#222222] text-[18px] bg-white rounded-md border border-[#EDEFF2] ">
          <div className="flex justify-between items-center">
            <FormattedMessage id="users.detailsField9"></FormattedMessage>
            <span>ada.nakuci@adc.al</span>
          </div>

          <div className="flex justify-between items-center">
            <FormattedMessage id="users.detailsField10"></FormattedMessage>
            <span>23/08/22 09:11</span>
          </div>
        </div>
      </div>

      <div className="groupContainer">
        <h1 className=" font-bold text-inputsGroupTitle text-pageTitle">
          <FormattedMessage id="users.userDetailsText4"></FormattedMessage>
        </h1>

        <div className=" w-full flex flex-col gap-2 p-4 text-[#222222] text-[18px] bg-white rounded-md border border-[#EDEFF2] ">
          <div className="flex justify-between items-center">
            <FormattedMessage id="users.detailsField11"></FormattedMessage>
            <span>Europe/Tirane</span>
          </div>

          <div className="flex justify-between items-center">
            <FormattedMessage id="users.detailsField12"></FormattedMessage>
            <span>English - United Kingdom</span>
          </div>
          <div className="flex justify-between items-center">
            <FormattedMessage id="users.detailsField13"></FormattedMessage>
            <span>Albania</span>
          </div>
          <div className="flex justify-between items-center">
            <FormattedMessage id="users.detailsField14"></FormattedMessage>
            <span>Yes</span>
          </div>
        </div>
      </div>

      <div className="groupContainer">
        <h1 className=" font-bold text-inputsGroupTitle text-pageTitle">
          <FormattedMessage id="users.userDetailsText4"></FormattedMessage>
        </h1>

        <div className=" w-full flex flex-col gap-2 p-4 text-[#222222] text-[18px] bg-white rounded-md border border-[#EDEFF2] ">
          <div className="flex justify-between items-center">
            <FormattedMessage id="users.detailsField15"></FormattedMessage>
            <span>-</span>
          </div>

          <div className="flex justify-between items-center">
            <FormattedMessage id="users.detailsField16"></FormattedMessage>
            <span>-</span>
          </div>
          <div className="flex justify-between items-center">
            <FormattedMessage id="users.detailsField17"></FormattedMessage>
            <span>-</span>
          </div>
        </div>
      </div>
    </div>
  );
};

ViewUserDetails.propTypes = {};

export default ViewUserDetails;
