import React from "react";
import { FormattedMessage } from "react-intl";

import dashboardIcon from "../assets/icons/Panel/dashboard_icon.svg";
import locationIcon from "../assets/icons/Panel/location_icon.svg";
import radarIcon from "../assets/icons/Panel/radar_icon.svg";
import mapIcon from "../assets/icons/Panel/map_icon.svg";
import exploreIcon from "../assets/icons/Panel/explore_icon.svg";
import insertChartIcon from "../assets/icons/Panel/insert_chart_icon.svg";
import tuneIcon from "../assets/icons/Panel/tune_icon.svg";
import settingsIcon from "../assets/icons/Panel/settings_icon.svg";
import infoIcon from "../assets/icons/Panel/info_icon.svg";
import powerIcon from "../assets/icons/Panel/power_icon.svg";
import addIcon from "../assets/icons/Panel/add_icon.svg";
import forwardIcon from "../assets/icons/Panel/arrow_forward_icon.svg";

import assetsIcon from "../assets/icons/Panel/administration/icons/assetsIcon.svg";
import groupOfAssetsIcon from "../assets/icons/Panel/administration/icons/groupOfAssetsIcon.svg";
import locationsGroup from "../assets/icons/Panel/administration/icons/locationsGroup.svg";
import administrationNotificationIcon from "../assets/icons/Panel/administration/icons/administrationNotificationIcon.svg";
import timetablesIcon from "../assets/icons/Panel/administration/icons/timetablesIcon.svg";
import devicesIcon from "../assets/icons/Panel/administration/icons/devicesIcon.svg";
import userIcon from "../assets/icons/Panel/administration/icons/userIcon.svg";
import usersIcon from "../assets/icons/Panel/administration/icons/usersIcon.svg";

import card1_icon from "../assets/icons/Panel/Card/card1_icon.svg";
import card2_icon from "../assets/icons/Panel/Card/card2_alert_icon.svg";
import card3_icon from "../assets/icons/Panel/Card/card3_place_icon.svg";
import card4_icon from "../assets/icons/Panel/Card/card4_format_shapes_icon.svg";

import card2_icon1 from "../assets/icons/Panel/Card/onMove_icon.svg";
import card2_icon2 from "../assets/icons/Panel/Card/car_alert_icon.svg";
import card2_icon3 from "../assets/icons/Panel/Card/questionMark_icon.svg";
import card2_icon4 from "../assets/icons/Panel/Card/mailbox_icon.svg";
import card2_icon5 from "../assets/icons/Panel/Card/format_shapes_icon.svg";
import card2_icon6 from "../assets/icons/Panel/Card/power2_icon.svg";
import check_icon from "../assets/icons/Panel/Card/done_icon.svg";

import shieldIcon from "../assets/icons/Panel/Card/shieldIcon.svg";
import leavesIcon from "../assets/icons/Panel/Card/leavesIcon.svg";

import roadsIcon from "../assets/icons/Panel/map-icons/CustomControl-Icons/roads-icon.svg";
import terrainIcon from "../assets/icons/Panel/map-icons/CustomControl-Icons/terrain-icon.svg";
import hybridIcon from "../assets/icons/Panel/map-icons/CustomControl-Icons/hybrid-icon.svg";

import closingFilterIcon from "../assets/icons/Panel/map-icons/Filter-Icons/close-filter-icon.svg";

import maintenanceIcon from "../assets/icons/Panel/maintenance/maintenanceIcon.svg";
import examinationIcon from "../assets/icons/Panel/maintenance/examinationIcon.svg";
import insuranceIcon from "../assets/icons/Panel/maintenance/insuranceIcon.svg";
import ticketIcon from "../assets/icons/Panel/maintenance/ticketIcon.svg";
import allocationIcon from "../assets/icons/Panel/maintenance/allocationIcon.svg";
import contractIcon from "../assets/icons/Panel/maintenance/contractIcon.svg";

// MENU
const MenuItems = [
  {
    title: <FormattedMessage id="menu.btn1"></FormattedMessage>,
    icon: dashboardIcon,
    // pathname: "panel",
    // linkTo: "/panel",
    pathname: "",
    linkTo: "/",
  },
  {
    title: <FormattedMessage id="menu.btn2"></FormattedMessage>,
    icon: locationIcon,
    pathname: "location",
    linkTo: "/location",
  },
  {
    title: <FormattedMessage id="menu.btn3"></FormattedMessage>,
    icon: radarIcon,
    pathname: "network",
    linkTo: "network",
  },
  {
    title: <FormattedMessage id="menu.btn4"></FormattedMessage>,
    icon: mapIcon,
    pathname: "itineraries",
    linkTo: "itineraries",
  },
  {
    title: <FormattedMessage id="menu.btn5"></FormattedMessage>,
    icon: exploreIcon,
    pathname: "explore",
    linkTo: "explore",
  },
];

const MenuItems2 = [
  {
    title: <FormattedMessage id="menu.btn6"></FormattedMessage>,
    icon: insertChartIcon,
    pathname: "raports",
    linkTo: "raports",
    icon2: addIcon,
  },
];

const MenuItems3 = [
  {
    title: <FormattedMessage id="menu.btn7"></FormattedMessage>,
    icon: tuneIcon,
    pathname: "maintenance",
    linkTo: "maintenance",
  },
  {
    title: <FormattedMessage id="menu.btn8"></FormattedMessage>,
    icon: settingsIcon,
    pathname: "administration",
    // linkTo: "administration",
    icon2: forwardIcon,
  },
];

const MenuItems4 = [
  {
    title: <FormattedMessage id="menu.btn9"></FormattedMessage>,
    icon: infoIcon,
    pathname: "settings",
    linkTo: "settings",
  },
  {
    title: <FormattedMessage id="menu.btn10"></FormattedMessage>,
    icon: powerIcon,
    pathname: "logout",
    // linkTo: "panel",
  },
];

const AdministrationItems = [
  {
    title: <FormattedMessage id="administration_menu.btn1"></FormattedMessage>,
    icon: assetsIcon,
    pathname: "assets",
    linkTo: "/admin/assets",
  },
  {
    title: <FormattedMessage id="administration_menu.btn2"></FormattedMessage>,
    icon: groupOfAssetsIcon,
    pathname: "asset-groups",
    linkTo: "admin/asset-groups",
  },
  {
    title: <FormattedMessage id="administration_menu.btn3"></FormattedMessage>,
    icon: locationIcon,
    pathname: "pois",
    linkTo: "admin/pois",
  },
  {
    title: <FormattedMessage id="administration_menu.btn4"></FormattedMessage>,
    icon: locationsGroup,
    pathname: "pois-groups",
    linkTo: "admin/pois-groups",
  },
];

const AdministrationItems2 = [
  {
    title: <FormattedMessage id="administration_menu.btn5"></FormattedMessage>,
    icon: administrationNotificationIcon,
    pathname: "notifications",
    linkTo: "admin/notifications",
    icon2: addIcon,
  },
  {
    title: <FormattedMessage id="administration_menu.btn6"></FormattedMessage>,
    icon: timetablesIcon,
    pathname: "timetables",
    linkTo: "timetables",
  },
  // {
  //   title: <FormattedMessage id="administration_menu.btn7"></FormattedMessage>,
  //   icon: insertChartIcon,
  //   pathname: "uploads",
  //   linkTo: "uploads",
  // },
];

const AdministrationItems3 = [
  {
    title: <FormattedMessage id="administration_menu.btn8"></FormattedMessage>,
    icon: devicesIcon,
    pathname: "devices",
    linkTo: "admin/devices",
  },
];

const AdministrationItems4 = [
  {
    title: <FormattedMessage id="administration_menu.btn9"></FormattedMessage>,
    icon: userIcon,
    pathname: "users",
    linkTo: "admin/users",
  },
  {
    title: <FormattedMessage id="administration_menu.btn10"></FormattedMessage>,
    icon: usersIcon,
    pathname: "user-groups",
    linkTo: "admin/user-groups",
  },
];

// CARD
const CardItems = [
  {
    title: <FormattedMessage id="card1.title"></FormattedMessage>,
    pieChartText: <FormattedMessage id="card1.chartText"></FormattedMessage>,
    texts: [
      {
        text: <FormattedMessage id="card1.text1"></FormattedMessage>,
        color: "#66ce66 ",
        value: 8,
      },
      {
        text: <FormattedMessage id="card1.text2"></FormattedMessage>,
        color: "#ffb100",
        value: 1,
      },
      {
        text: <FormattedMessage id="card1.text3"></FormattedMessage>,
        color: "#e60000 ",
        value: 41,
      },
      {
        text: <FormattedMessage id="card1.text4"></FormattedMessage>,
        color: "#252931",
        value: 21,
      },
    ],
    subtitle: <FormattedMessage id="card1.subtitle"></FormattedMessage>,
    watchMoreText: <FormattedMessage id="card1.watchMore"></FormattedMessage>,
    watchMoreLinkTo: "",
    progressBarText1: (
      <FormattedMessage id="card1.progressBarText1"></FormattedMessage>
    ),
    progressBarText2: (
      <FormattedMessage id="card1.progressBarText2"></FormattedMessage>
    ),
    headerIcon: card1_icon,
    footerText: <FormattedMessage id="card.footerText"></FormattedMessage>,
    footerLinkTo: "",
  },

  {
    title: <FormattedMessage id="card2.title"></FormattedMessage>,
    pieChartText: <FormattedMessage id="card2.chartText"></FormattedMessage>,

    elements: [
      {
        text: <FormattedMessage id="card2.text1"></FormattedMessage>,
        icon: card2_icon1,
        color: "#EA6C68",
        value: 15,
      },
      {
        text: <FormattedMessage id="card2.text2"></FormattedMessage>,
        icon: card2_icon2,
        color: "#EA6C68",
        value: 15,
      },
      {
        text: <FormattedMessage id="card2.text3"></FormattedMessage>,
        icon: card2_icon3,
        color: "#8A98A8",
        value: 15,
      },
      {
        text: <FormattedMessage id="card2.text4"></FormattedMessage>,
        icon: card2_icon4,
        color: "#8A98A8",
        value: 15,
      },
      {
        text: <FormattedMessage id="card2.text5"></FormattedMessage>,
        icon: card2_icon5,
        color: "#8A98A8",
        value: 15,
      },
      {
        text: <FormattedMessage id="card2.text6"></FormattedMessage>,
        icon: card2_icon6,
        color: "#8A98A8",
        value: 15,
      },
    ],

    headerIcon: card2_icon,

    footerText: <FormattedMessage id="card.footerText"></FormattedMessage>,
    footerLinkTo: "",
  },

  {
    title: <FormattedMessage id="card3.title"></FormattedMessage>,
    pieChartText: <FormattedMessage id="card3.chartText"></FormattedMessage>,
    text: <FormattedMessage id="card3.text"></FormattedMessage>,
    value: 4,
    headerIcon: card3_icon,
    footerText: <FormattedMessage id="card.footerText"></FormattedMessage>,
    footerLinkTo: "",
  },

  {
    title: <FormattedMessage id="card4.title"></FormattedMessage>,
    pieChartText: <FormattedMessage id="card4.chartText"></FormattedMessage>,
    text1: <FormattedMessage id="card4.text1"></FormattedMessage>,
    text2: <FormattedMessage id="card4.text2"></FormattedMessage>,
    text3: <FormattedMessage id="card4.text3"></FormattedMessage>,
    contentIcon: check_icon,
    headerIcon: card4_icon,
    footerText: <FormattedMessage id="card.footerText"></FormattedMessage>,
    footerLinkTo: "",
  },

  {
    title: <FormattedMessage id="card5.title"></FormattedMessage>,
    pieChartText: <FormattedMessage id="card5.chartText"></FormattedMessage>,
    // pieChartIcon: <img src={shieldIcon} width={55}></img>,
    pieChartIcon: shieldIcon,
    footerText: <FormattedMessage id="card.footerText2"></FormattedMessage>,
    footerLinkTo: "",
  },
  {
    title: <FormattedMessage id="card6.title"></FormattedMessage>,
    pieChartText: <FormattedMessage id="card6.chartText"></FormattedMessage>,
    // pieChartIcon: <img src={leavesIcon} width={26}></img>,
    pieChartIcon: leavesIcon,
    footerText: <FormattedMessage id="card.footerText2"></FormattedMessage>,
    footerLinkTo: "",
  },
];

// MAP
const InfoWindowDetails = {
  1: [
    {
      text: <FormattedMessage id="customControl1.text1"></FormattedMessage>,
      icon: roadsIcon,
      type: "roadmap",
    },

    {
      text: <FormattedMessage id="customControl1.text2"></FormattedMessage>,
      icon: terrainIcon,
      type: "terrain",
    },

    {
      text: <FormattedMessage id="customControl1.text3"></FormattedMessage>,
      icon: roadsIcon,
      type: "satellite",
    },

    {
      text: <FormattedMessage id="customControl1.text4"></FormattedMessage>,
      icon: hybridIcon,
      type: "hybrid",
    },
  ],

  2: [
    {
      text: <FormattedMessage id="customControl1.text5"></FormattedMessage>,
      icon: roadsIcon,
      type: "traffic",
    },
  ],
};

// FILTER
const FilterItems = {
  title: <FormattedMessage id="filter.title"></FormattedMessage>,
  closingIcon: closingFilterIcon,
  // inputs: [
  //   {
  //     inputId: "assetId",
  //     type: "text",
  //     name: "asset",
  //     background: "transparent",
  //     paddingHorizontal: "px-5",
  //     labelLngId: "filter.asset",
  //     placeholderId: "filter.placeholder",
  //   },
  //   {
  //     label: <FormattedMessage id="filter.serial"></FormattedMessage>,
  //     placeholderId: "filter.placeholder",
  //   },
  //   {
  //     label: <FormattedMessage id="filter.pi"></FormattedMessage>,
  //     placeholderId: "filter.placeholder",
  //   },
  // ],

  accordationTitle1: (
    <FormattedMessage id="filter.accordion1"></FormattedMessage>
  ),
  accordationTitle2: (
    <FormattedMessage id="filter.accordion2"></FormattedMessage>
  ),
  accordationTitle3: (
    <FormattedMessage id="filter.accordion3"></FormattedMessage>
  ),
  accordationTitle4: (
    <FormattedMessage id="filter.accordion4"></FormattedMessage>
  ),

  btn1: "filter.btnText1",
  btn2: "filter.btnText2",
};

// ASSETS & ASSETS GROUP PAGE
const AssetPage = {
  subtitle1: <FormattedMessage id="assets.subtitle1"></FormattedMessage>,
  subtitle2: <FormattedMessage id="assets.subtitle2"></FormattedMessage>,
  title: <FormattedMessage id="assets.title"></FormattedMessage>,
};

const AssetGroupPage = {
  subtitle1: <FormattedMessage id="assets.subtitle1"></FormattedMessage>,
  subtitle2: <FormattedMessage id="assets.subtitle2"></FormattedMessage>,
  title: <FormattedMessage id="assets.title"></FormattedMessage>,
};

// TABLE
const AssetsTable = [
  {
    id: "table1.header1",
    title: <FormattedMessage id="table1.header1"></FormattedMessage>,
  },
  {
    id: "table1.header2",
    title: <FormattedMessage id="table1.header2"></FormattedMessage>,
  },
  {
    id: "table1.header3",
    title: <FormattedMessage id="table1.header3"></FormattedMessage>,
  },
  {
    id: "table1.header4",
    title: <FormattedMessage id="table1.header4"></FormattedMessage>,
  },
  {
    id: "table1.header5",
    title: <FormattedMessage id="table1.header5"></FormattedMessage>,
  },
  {
    id: "table1.header6",
    title: <FormattedMessage id="table1.header6"></FormattedMessage>,
  },
  {
    id: "table1.header7",
    title: <FormattedMessage id="table1.header7"></FormattedMessage>,
  },
];

const AssetsGroupTable = [
  {
    id: "table2.header1",
    title: <FormattedMessage id="table2.header1"></FormattedMessage>,
  },
  {
    id: "table2.header2",
    title: <FormattedMessage id="table2.header2"></FormattedMessage>,
  },
  {
    id: "table2.header3",
    title: <FormattedMessage id="table2.header3"></FormattedMessage>,
  },
  {
    id: "table2.header4",
    title: <FormattedMessage id="table2.header4"></FormattedMessage>,
  },
  {
    id: "table2.header5",
    title: <FormattedMessage id="table2.header5"></FormattedMessage>,
  },
  {
    id: "table2.header6",
    title: <FormattedMessage id="table2.header6"></FormattedMessage>,
  },
  {
    id: "table2.header7",
    title: <FormattedMessage id="table2.header7"></FormattedMessage>,
  },
];

const DevicesTable = [
  {
    id: "table3.header1",
    title: <FormattedMessage id="table3.header1"></FormattedMessage>,
  },
  {
    id: "table3.header2",
    title: <FormattedMessage id="table3.header2"></FormattedMessage>,
  },
  {
    id: "table3.header3",
    title: <FormattedMessage id="table3.header3"></FormattedMessage>,
  },
  {
    id: "table3.header4",
    title: <FormattedMessage id="table3.header4"></FormattedMessage>,
  },
  {
    id: "table3.header5",
    title: <FormattedMessage id="table3.header5"></FormattedMessage>,
  },
  {
    id: "table3.header6",
    title: <FormattedMessage id="table3.header6"></FormattedMessage>,
  },
  {
    id: "table3.header7",
    title: <FormattedMessage id="table3.header7"></FormattedMessage>,
  },
  {
    id: "table3.header8",
    title: <FormattedMessage id="table3.header8"></FormattedMessage>,
  },
  {
    id: "table3.header9",
    title: <FormattedMessage id="table3.header9"></FormattedMessage>,
  },
  {
    id: "table3.header10",
    title: <FormattedMessage id="table3.header10"></FormattedMessage>,
  },
  {
    id: "table3.header11",
    title: <FormattedMessage id="table3.header11"></FormattedMessage>,
  },
];

const NotificationsTable = [
  {
    id: "table4.header1",
    title: <FormattedMessage id="table4.header1"></FormattedMessage>,
  },
  {
    id: "table4.header2",
    title: <FormattedMessage id="table4.header2"></FormattedMessage>,
  },
  {
    id: "table4.header3",
    title: <FormattedMessage id="table4.header3"></FormattedMessage>,
  },
  {
    id: "table4.header4",
    title: <FormattedMessage id="table4.header4"></FormattedMessage>,
  },
  {
    id: "table5.header5",
    title: <FormattedMessage id="table4.header5"></FormattedMessage>,
  },
  {
    id: "table6.header6",
    title: <FormattedMessage id="table4.header6"></FormattedMessage>,
  },
  {
    id: "table7.header7",
    title: <FormattedMessage id="table4.header7"></FormattedMessage>,
  },
];

const UsersTable = [
  {
    id: "table5.header1",
    title: <FormattedMessage id="table5.header1"></FormattedMessage>,
  },
  {
    id: "table5.header2",
    title: <FormattedMessage id="table5.header2"></FormattedMessage>,
  },
  {
    id: "table5.header3",
    title: <FormattedMessage id="table5.header3"></FormattedMessage>,
  },
  {
    id: "table5.header4",
    title: <FormattedMessage id="table5.header4"></FormattedMessage>,
  },
  {
    id: "table5.header5",
    title: <FormattedMessage id="table5.header5"></FormattedMessage>,
  },
  {
    id: "table5.header6",
    title: "",
  },
];

const UserGroupTable = [
  {
    id: "table6.header1",
    title: <FormattedMessage id="table6.header1"></FormattedMessage>,
  },
  {
    id: "table6.header2",
    title: <FormattedMessage id="table6.header2"></FormattedMessage>,
  },
  {
    id: "table6.header3",
    title: <FormattedMessage id="table6.header3"></FormattedMessage>,
  },
  {
    id: "table6.header4",
    title: <FormattedMessage id="table6.header4"></FormattedMessage>,
  },
  {
    id: "table6.header5",
    title: <FormattedMessage id="table6.header5"></FormattedMessage>,
  },

  {
    id: "table6.header6",
    title: "",
  },
];

const RaportsTable = [
  {
    id: "table7.header1",
    title: <FormattedMessage id="table7.header1"></FormattedMessage>,
  },
  {
    id: "table7.header2",
    title: <FormattedMessage id="table7.header2"></FormattedMessage>,
  },
  {
    id: "table7.header3",
    title: <FormattedMessage id="table7.header3"></FormattedMessage>,
  },
  {
    id: "table7.header4",
    title: <FormattedMessage id="table7.header4"></FormattedMessage>,
  },
  {
    id: "table7.header5",
    title: <FormattedMessage id="table7.header5"></FormattedMessage>,
  },
  {
    id: "table7.header6",
    title: <FormattedMessage id="table7.header6"></FormattedMessage>,
  },
  {
    id: "table7.header7",
    title: <FormattedMessage id="table7.header7"></FormattedMessage>,
  },
  {
    id: "table7.header8",
    title: <FormattedMessage id="table7.header8"></FormattedMessage>,
  },
];

const GenerateRaportTable = [
  {
    id: "table8.header1",
    title: <FormattedMessage id="table8.header1"></FormattedMessage>,
  },
  {
    id: "table8.header2",
    title: <FormattedMessage id="table8.header2"></FormattedMessage>,
  },
  // {
  //   id: "empty1",
  //   title: "",
  // },
  // {
  //   id: "empty2",
  //   title: "",
  // },
  // {
  //   id: "empty3",
  //   title: "",
  // },
  // {
  //   id: "empty4",
  //   title: "",
  // },
  // {
  //   id: "empty5",
  //   title: "",
  // },
  // {
  //   id: "empty6",
  //   title: "",
  // },
  // {
  //   id: "empty7",
  //   title: "",
  // },
  // {
  //   id: "empty8",
  //   title: "",
  // },
  // {
  //   id: "empty9",
  //   title: "",
  // },
  // {
  //   id: "empty10",
  //   title: "",
  // },
  // {
  //   id: "empty11",
  //   title: "",
  // },
  // {
  //   id: "empty12",
  //   title: "",
  // },
  // {
  //   id: "empty13",
  //   title: "",
  // },
  // {
  //   id: "empty14",
  //   title: "",
  // },
  // {
  //   id: "empty15",
  //   title: "",
  // },
  // {
  //   id: "empty16",
  //   title: "",
  // },
  // {
  //   id: "empty17",
  //   title: "",
  // },
  // {
  //   id: "empty18",
  //   title: "",
  // },
  // {
  //   id: "empty19",
  //   title: "",
  // },
  // {
  //   id: "empty20",
  //   title: "",
  // },
  // {
  //   id: "empty21",
  //   title: "",
  // },
  // {
  //   id: "empty22",
  //   title: "",
  // },
  // {
  //   id: "empty23",
  //   title: "",
  // },
  // {
  //   id: "empty24",
  //   title: "",
  // },
  // {
  //   id: "empty25",
  //   title: "",
  // },
  // {
  //   id: "empty26",
  //   title: "",
  // },
  // {
  //   id: "empty27",
  //   title: "",
  // },
  // {
  //   id: "empty28",
  //   title: "",
  // },
  // {
  //   id: "empty29",
  //   title: "",
  // },
];

// VIEW ASSET DETAILS TEXT
const AssetDetailTitles = [
  <FormattedMessage
    id="asset.assetDetailsField1"
    key={"asset.assetDetailsField1"}
  ></FormattedMessage>,
  <FormattedMessage
    id="asset.assetDetailsField2"
    key={"asset.assetDetailsField2"}
  ></FormattedMessage>,
  <FormattedMessage
    id="asset.assetDetailsField3"
    key={"asset.assetDetailsField3"}
  ></FormattedMessage>,
  <FormattedMessage
    id="asset.assetDetailsField4"
    key={"asset.assetDetailsField4"}
  ></FormattedMessage>,
  <FormattedMessage
    id="asset.assetDetailsField5"
    key={"asset.assetDetailsField5"}
  ></FormattedMessage>,
  <FormattedMessage
    id="asset.assetDetailsField6"
    key={"asset.assetDetailsField6"}
  ></FormattedMessage>,
  <FormattedMessage
    id="asset.assetDetailsField7"
    key={"asset.assetDetailsField7"}
  ></FormattedMessage>,
];

// VIEW ASSET GROUP DETAILS TEXT
const AssetGroupDetailTitles = [
  <FormattedMessage
    id="assetGroup.assetGroupDetailsField1"
    key={"assetGroup.assetGroupDetailsField1"}
  ></FormattedMessage>,
  <FormattedMessage
    id="assetGroup.assetGroupDetailsField2"
    key={"assetGroup.assetGroupDetailsField2"}
  ></FormattedMessage>,
  <FormattedMessage
    id="assetGroup.assetGroupDetailsField3"
    key={"assetGroup.assetGroupDetailsField3"}
  ></FormattedMessage>,
  <FormattedMessage
    id="assetGroup.assetGroupDetailsField4"
    key={"assetGroup.assetGroupDetailsField4"}
  ></FormattedMessage>,
  <FormattedMessage
    id="assetGroup.assetGroupDetailsField5"
    key={"assetGroup.assetGroupDetailsField5"}
  ></FormattedMessage>,
  <FormattedMessage
    id="assetGroup.assetGroupDetailsField6"
    key={"assetGroup.assetGroupDetailsField6"}
  ></FormattedMessage>,
  <FormattedMessage
    id="assetGroup.assetGroupDetailsField7"
    key={"assetGroup.assetGroupDetailsField7"}
  ></FormattedMessage>,
  <FormattedMessage
    id="assetGroup.assetGroupDetailsField8"
    key={"assetGroup.assetGroupDetailsField8"}
  ></FormattedMessage>,
];

// VIEW ASSET GROUP DETAILS TEXT
const DeviceDetailTitles = [
  <FormattedMessage
    id="devices.deviceDetailsField1"
    key={"devices.deviceDetailsField1"}
  ></FormattedMessage>,
  <FormattedMessage
    id="devices.deviceDetailsField2"
    key={"devices.deviceDetailsField2"}
  ></FormattedMessage>,
  <FormattedMessage
    id="devices.deviceDetailsField3"
    key={"devices.deviceDetailsField3"}
  ></FormattedMessage>,
  <FormattedMessage
    id="devices.deviceDetailsField4"
    key={"devices.deviceDetailsField4"}
  ></FormattedMessage>,
  <FormattedMessage
    id="devices.deviceDetailsField5"
    key={"devices.deviceDetailsField5"}
  ></FormattedMessage>,
  <FormattedMessage
    id="devices.deviceDetailsField6"
    key={"devices.deviceDetailsField6"}
  ></FormattedMessage>,
  <FormattedMessage
    id="devices.deviceDetailsField7"
    key={"devices.deviceDetailsField7"}
  ></FormattedMessage>,
  <FormattedMessage
    id="devices.deviceDetailsField8"
    key={"devices.deviceDetailsField8"}
  ></FormattedMessage>,
  <FormattedMessage
    id="devices.deviceDetailsField9"
    key={"devices.deviceDetailsField9"}
  ></FormattedMessage>,
  <FormattedMessage
    id="devices.deviceDetailsField10"
    key={"devices.deviceDetailsField10"}
  ></FormattedMessage>,
  <FormattedMessage
    id="devices.deviceDetailsField11"
    key={"devices.deviceDetailsField11"}
  ></FormattedMessage>,
  <FormattedMessage
    id="devices.deviceDetailsField12"
    key={"devices.deviceDetailsField12"}
  ></FormattedMessage>,
];

// VIEW ASSET DETAILS TEXT
const UserGroupDetailTitles = [
  <FormattedMessage
    id="userGroup.viewDetailsField1"
    key={"userGroup.viewDetailsField1"}
  ></FormattedMessage>,
  <FormattedMessage
    id="userGroup.viewDetailsField2"
    key={"userGroup.viewDetailsField2"}
  ></FormattedMessage>,
  <FormattedMessage
    id="userGroup.viewDetailsField3"
    key={"userGroup.viewDetailsField3"}
  ></FormattedMessage>,
  <FormattedMessage
    id="userGroup.viewDetailsField4"
    key={"userGroup.viewDetailsField4"}
  ></FormattedMessage>,
  <FormattedMessage
    id="userGroup.viewDetailsField5"
    key={"userGroup.viewDetailsField5"}
  ></FormattedMessage>,
  <FormattedMessage
    id="userGroup.viewDetailsField6"
    key={"userGroup.viewDetailsField6"}
  ></FormattedMessage>,
  <FormattedMessage
    id="userGroup.viewDetailsField7"
    key={"userGroup.viewDetailsField7"}
  ></FormattedMessage>,
  <FormattedMessage
    id="userGroup.viewDetailsField8"
    key={"userGroup.viewDetailsField8"}
  ></FormattedMessage>,
];

// SELECT INPUT OPTIONS
const options1 = [
  { value: "Active", label: "Active", selected: false, disabled: false },
  {
    value: "Under installation",
    label: "Under installation",
    selected: false,
    disabled: false,
  },
  { value: "Testing", label: "Testing", selected: false, disabled: false },
  { value: "Damaged", label: "Damaged", selected: false, disabled: false },
  { value: "Deleted", label: "Deleted", selected: false, disabled: false },
  { value: "Blocked", label: "Blocked", selected: false, disabled: false },
  { value: "Repair", label: "Repair", selected: false, disabled: false },
];

const options2 = [
  { value: "", label: "Select Type/Subtype", selected: true, disabled: true },

  { value: "Ambulance", label: "Ambulance", selected: false, disabled: false },
  { value: "Beton", label: "Beton", selected: false, disabled: false },
  { value: "Bicycle", label: "Bicycle", selected: false, disabled: false },
  { value: "Boat", label: "Boat", selected: false, disabled: false },
  { value: "Bus", label: "Bus", selected: false, disabled: false },
  {
    value: "Flatbed truck",
    label: "Flatbed truck",
    selected: false,
    disabled: false,
  },
  {
    value: "Fridge trailer",
    label: "Fridge trailer",
    selected: false,
    disabled: false,
  },
  {
    value: "Fridge truck 3,5t",
    label: "Fridge truck 3,5t",
    selected: false,
    disabled: false,
  },
  {
    value: "Generic - RND",
    label: "Generic - RND",
    selected: false,
    disabled: false,
  },
  { value: "Moto", label: "Moto", selected: false, disabled: false },
  {
    value: "Passenger Car",
    label: "Passenger Car",
    selected: false,
    disabled: false,
  },
  {
    value: "School bus",
    label: "School bus",
    selected: false,
    disabled: false,
  },
  {
    value: "Tanker truck",
    label: "Tanker truck",
    selected: false,
    disabled: false,
  },
  { value: "Tracker", label: "Tracker", selected: false, disabled: false },
  { value: "Tractor", label: "Tractor", selected: false, disabled: false },
  { value: "Trailer", label: "Trailer", selected: false, disabled: false },
];

// CREATE NEW DEVICE INPUT OPTIONS
const options3 = [
  { value: "", label: "Select Type/Subtype", selected: true, disabled: true },

  { value: "Ambulance", label: "Ambulance", selected: false, disabled: false },
  { value: "Beton", label: "Beton", selected: false, disabled: false },
  { value: "Bicycle", label: "Bicycle", selected: false, disabled: false },
  { value: "Boat", label: "Boat", selected: false, disabled: false },
  { value: "Bus", label: "Bus", selected: false, disabled: false },
  {
    value: "Flatbed truck",
    label: "Flatbed truck",
    selected: false,
    disabled: false,
  },
  {
    value: "Fridge trailer",
    label: "Fridge trailer",
    selected: false,
    disabled: false,
  },
  {
    value: "Fridge truck 3,5t",
    label: "Fridge truck 3,5t",
    selected: false,
    disabled: false,
  },
  {
    value: "Generic - RND",
    label: "Generic - RND",
    selected: false,
    disabled: false,
  },
  { value: "Moto", label: "Moto", selected: false, disabled: false },
  {
    value: "Passenger Car",
    label: "Passenger Car",
    selected: false,
    disabled: false,
  },
  {
    value: "School bus",
    label: "School bus",
    selected: false,
    disabled: false,
  },
  {
    value: "Tanker truck",
    label: "Tanker truck",
    selected: false,
    disabled: false,
  },
  { value: "Tracker", label: "Tracker", selected: false, disabled: false },
  { value: "Tractor", label: "Tractor", selected: false, disabled: false },
  { value: "Trailer", label: "Trailer", selected: false, disabled: false },
];

// STRECHED CARD PERIOD OPTIONS

const options4 = [
  // { value: "Today", label: "Today", selected: true, disabled: false },
  // { value: "Yesterday", label: "Yesterday", selected: false, disabled: false },
  // { value: "Last Week", label: "Last Week", selected: false, disabled: false },
  // {
  //   value: "Last Month",
  //   label: "Last Month",
  //   selected: false,
  //   disabled: false,
  // },
  {
    value: "Today",
    label: <FormattedMessage id="strechedCard.today"></FormattedMessage>,
    selected: true,
    disabled: false,
  },
  {
    value: "Yesterday",
    label: <FormattedMessage id="strechedCard.yesterday"></FormattedMessage>,
    selected: false,
    disabled: false,
  },
  {
    value: "Last Week",
    label: <FormattedMessage id="strechedCard.lastWeek"></FormattedMessage>,
    selected: false,
    disabled: false,
  },
  {
    value: "Last Month",
    label: <FormattedMessage id="strechedCard.lastMonth"></FormattedMessage>,
    selected: false,
    disabled: false,
  },
];

// RAPORTS
const RaportTypes = [
  {
    id: "raports.element1Text",
    text: "raports.element1Text",
  },
  {
    id: "raports.element2Text",
    text: "raports.element2Text",
  },
  {
    id: "raports.element3Text",
    text: "raports.element3Text",
  },
  {
    id: "raports.element4Text",
    text: "raports.element4Text",
  },
  {
    id: "raports.element5Text",
    text: "raports.element5Text",
  },
  {
    id: "raports.element6Text",
    text: "raports.element6Text",
  },
  {
    id: "raports.element7Text",
    text: "raports.element7Text",
  },
  {
    id: "raports.element8Text",
    text: "raports.element8Text",
  },
  {
    id: "raports.element9Text",
    text: "raports.element9Text",
  },
];

// CALENDAR TYPES
const ScheduleTypes = [
  {
    icon: maintenanceIcon,
    text: <FormattedMessage id="maintenance"></FormattedMessage>,
    type: "maintenance",
    color: "#00FFFF",
  },
  {
    icon: examinationIcon,
    text: <FormattedMessage id="examination"></FormattedMessage>,
    type: "examination",
    color: "#FFD700",
  },
  {
    icon: insuranceIcon,
    text: <FormattedMessage id="insurance"></FormattedMessage>,
    type: "insurance",
    color: "#228B22",
  },
  {
    icon: ticketIcon,
    text: <FormattedMessage id="ticket"></FormattedMessage>,
    type: "ticket",
    color: "#DC143C",
  },
  {
    icon: allocationIcon,
    text: <FormattedMessage id="allocation"></FormattedMessage>,
    type: "allocation",
    color: "#0000FF",
  },
  {
    icon: contractIcon,
    text: <FormattedMessage id="contract"></FormattedMessage>,
    type: "contract",
    color: "#8A2BE2",
  },
];

// SCHEDULE MODAL

const CategoryOptions = [
  {
    value: "maintenance",
    label: <FormattedMessage id="maintenance"></FormattedMessage>,
    selected: true,
    disabled: false,
  },
  {
    value: "examination",
    label: <FormattedMessage id="examination"></FormattedMessage>,
    selected: false,
    disabled: false,
  },
  {
    value: "ticket",
    label: <FormattedMessage id="ticket"></FormattedMessage>,
    selected: false,
    disabled: false,
  },
  {
    value: "allocation",
    label: <FormattedMessage id="Allocation"></FormattedMessage>,
    selected: false,
    disabled: false,
  },
  {
    value: "contract",
    label: <FormattedMessage id="contract"></FormattedMessage>,
    selected: false,
    disabled: false,
  },
  {
    value: "insurance",
    label: <FormattedMessage id="insurance"></FormattedMessage>,
    selected: false,
    disabled: false,
  },
];

const TypeOfMaintenance = [
  {
    value: "Select one of them",
    label: (
      <FormattedMessage id="scheduleModal.input2Placeholder"></FormattedMessage>
    ),
    selected: true,
    disabled: true,
  },
  {
    value: "maintenance",
    label: <FormattedMessage id="maintenance"></FormattedMessage>,
    selected: false,
    disabled: false,
  },
  {
    value: "examination",
    label: <FormattedMessage id="examination"></FormattedMessage>,
    selected: false,
    disabled: false,
  },
  {
    value: "ticket",
    label: <FormattedMessage id="ticket"></FormattedMessage>,
    selected: false,
    disabled: false,
  },
  {
    value: "allocation",
    label: <FormattedMessage id="Allocation"></FormattedMessage>,
    selected: false,
    disabled: false,
  },
  {
    value: "contract",
    label: <FormattedMessage id="contract"></FormattedMessage>,
    selected: false,
    disabled: false,
  },
  {
    value: "insurance",
    label: <FormattedMessage id="insurance"></FormattedMessage>,
    selected: false,
    disabled: false,
  },
];

const Tyres = [
  {
    value: " ",
    label: <FormattedMessage id="app.emptyLabel"></FormattedMessage>,
    selected: false,
    disabled: true,
  },
  {
    value: "maintenance",
    label: <FormattedMessage id="maintenance"></FormattedMessage>,
    selected: false,
    disabled: false,
  },
  {
    value: "examination",
    label: <FormattedMessage id="examination"></FormattedMessage>,
    selected: false,
    disabled: false,
  },
  {
    value: "ticket",
    label: <FormattedMessage id="ticket"></FormattedMessage>,
    selected: false,
    disabled: false,
  },
  {
    value: "allocation",
    label: <FormattedMessage id="Allocation"></FormattedMessage>,
    selected: false,
    disabled: false,
  },
  {
    value: "contract",
    label: <FormattedMessage id="contract"></FormattedMessage>,
    selected: false,
    disabled: false,
  },
  {
    value: "insurance",
    label: <FormattedMessage id="insurance"></FormattedMessage>,
    selected: false,
    disabled: false,
  },
];

const ReminderType = [
  {
    value: "date",
    label: (
      <FormattedMessage id="scheduleModal.reminderType.date"></FormattedMessage>
    ),
    selected: true,
    disabled: false,
  },
  {
    value: "sms",
    label: (
      <FormattedMessage id="scheduleModal.reminderType.SMS"></FormattedMessage>
    ),
    selected: false,
    disabled: false,
  },
  {
    value: "email",
    label: (
      <FormattedMessage id="scheduleModal.reminderType.email"></FormattedMessage>
    ),
    selected: false,
    disabled: false,
  },
];

export {
  MenuItems,
  MenuItems2,
  MenuItems3,
  MenuItems4,
  AdministrationItems,
  AdministrationItems2,
  AdministrationItems3,
  AdministrationItems4,
  CardItems,
  AssetPage,
  AssetGroupPage,
  AssetsTable,
  AssetsGroupTable,
  DevicesTable,
  NotificationsTable,
  UsersTable,
  UserGroupTable,
  RaportsTable,
  GenerateRaportTable,
  AssetDetailTitles,
  AssetGroupDetailTitles,
  DeviceDetailTitles,
  UserGroupDetailTitles,
  InfoWindowDetails,
  FilterItems,
  options1,
  options2,
  options3,
  options4,
  RaportTypes,
  ScheduleTypes,
  CategoryOptions,
  TypeOfMaintenance,
  Tyres,
  ReminderType,
};
