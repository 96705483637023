import React from "react";
import { Link, useLocation } from "react-router-dom";
import PropTypes from "prop-types";

const MenuBtn = ({
  openSideMenu,
  title,
  icon,
  icon2,
  linkTo,
  pathname,
  toggleAdmMenu,
  toggleAdministrationMenu,
  toggleLogout,
}) => {
  const location = useLocation();

  let locationPathname;

  if (toggleAdministrationMenu) {
    locationPathname = location.pathname?.split("/")[2];
  } else {
    locationPathname = location.pathname?.split("/")[1];
  }

  const handleClick = () => {
    if (pathname === "administration") {
      toggleAdmMenu();
    }

    if (pathname === "logout") {
      toggleLogout();
    }
  };

  return (
    <Link
      onClick={handleClick}
      to={linkTo}
      className={`${
        openSideMenu ? "w-[230px]" : "w-full"
      } h-[42px] flex items-center active:opacity-70 menuItemLinearHover`}
    >
      {openSideMenu && (
        <div
          style={{ width: locationPathname === pathname ? "4px" : 0 }}
          className={` h-full bg-white `}
        ></div>
      )}

      <div
        className={`flex flex-1 items-center h-full px-5 gap-2 ${
          locationPathname === pathname && "menuItemLinear"
        }`}
      >
        <img src={icon} alt="dashboardIcon" className="min-w-4 min-h-4"></img>
        {openSideMenu && (
          <div className="flex flex-1 justify-between items-center ">
            <span className=" text-sm font-albertSansMedium text-white">
              {title}
            </span>
            {icon2 && (
              <img src={icon2} className="w-[10px] h-[10px]" alt=""></img>
            )}
          </div>
        )}
      </div>
    </Link>
  );
};

MenuBtn.propTypes = {
  openSideMenu: PropTypes.bool,
  title: PropTypes.object,
  linkTo: PropTypes.string,
  icon: PropTypes.string,
  icon2: PropTypes.string,
  pathname: PropTypes.string,
  toggleAdmMenu: PropTypes.func,
  toggleAdministrationMenu: PropTypes.bool,
  toggleLogout: PropTypes.func,
};

export default MenuBtn;
