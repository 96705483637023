import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import VFLogo from "../../assets/logo/VDF-logo.png";
import menuIcon from "../../assets/icons/Panel/menu_icon.svg";
import inboxIcon from "../../assets/icons/Panel/inbox.svg";
import notificationIcon from "../../assets/icons/Panel/notifications.svg";
import { Link } from "react-router-dom";

const TopBar = ({ setOpenSideMenu, openSideMenu }) => {
  return (
    <header className="topBarContainer">
      <div className="w-full  md:w-fit flex items-center justify-between md:justify-normal md:space-x-14 ">
        <Link to={"/"}>
          <img
            src={VFLogo}
            alt="vodafone-logo"
            className="w-[120px] cursor-pointer"
          ></img>
        </Link>

        <img
          src={menuIcon}
          className="topBar-icons active:opacity-50 cursor-pointer"
          alt="menu-icon"
          onClick={() => setOpenSideMenu(!openSideMenu)}
        ></img>
      </div>
      <div className="hidden  md:flex items-center gap-5">
        <div className="topBar-right-containers">
          <img src={inboxIcon} className="topBar-icons" alt="inbox-icon"></img>
          <span>
            <FormattedMessage id="topBar.messages"></FormattedMessage>
          </span>
        </div>

        <div className="divider"></div>

        <div className="topBar-right-containers">
          <img
            src={notificationIcon}
            className="topBar-icons"
            alt="notifications-icon"
          ></img>
          <span>
            <FormattedMessage id="topBar.notifications"></FormattedMessage>
          </span>
          <span className="w-5 h-5 rounded-full bg-redPrimary text-white flex justify-center items-center text-[12px]">
            6
          </span>
        </div>

        <div className="divider"></div>

        <div className="topBar-right-containers">
          <span className=" font-albertSansSemiBold">
            <FormattedMessage id="topBar.username"></FormattedMessage>
          </span>
          <img
            className="w-8 h-8 object-cover bg-black rounded-full"
            alt="username"
          ></img>
        </div>
      </div>
    </header>
  );
};

TopBar.propTypes = {
  setOpenSideMenu: PropTypes.func,
  openSideMenu: PropTypes.bool,
};

export default TopBar;
