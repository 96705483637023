import React, { useEffect } from "react";

import { PropTypes } from "prop-types";

import { FormattedMessage } from "react-intl";

import { options3 } from "../../../../constants";

import SelectInput from "../../../../components/Inputs/SelectInput";
import InputWithLabel from "../../../../components/Inputs/InputWithLabel";

const CreateNewDevice = ({ formData, setFormData }) => {
  useEffect(() => {
    return () => {
      setFormData({
        deviceSerial: "",
        deviceIMEI: "",
        deviceIMSI: "",
        deviceICCID: "",
        deviceMSISDN: "",
        deviceType: "",
      });
    };
  }, []);

  const handleChange = (event) => {
    const { name } = event.target;

    setFormData((prevValues) => ({
      ...prevValues,
      [name]: event.target.value,
    }));
  };

  return (
    <form id="createAssetForm" className="formContainer">
      <div className="groupContainer">
        <h1 className=" font-bold text-inputsGroupTitle text-pageTitle">
          <FormattedMessage id="devices.devicesDetailsText"></FormattedMessage>
        </h1>
        <InputWithLabel
          id={"deviceSerial"}
          name={"deviceSerial"}
          labelLngId={"devices.inputLabel1"}
          labelSize={"text-extraSmall"}
          labelColor={"text-textSecondary"}
          handleChange={handleChange}
          value={formData.assetName}
        ></InputWithLabel>
        <InputWithLabel
          id={"deviceIMEI"}
          name={"deviceIMEI"}
          labelLngId={"IMEI"}
          labelSize={"text-extraSmall"}
          labelColor={"text-textSecondary"}
          handleChange={handleChange}
          value={formData.serialNumber}
        ></InputWithLabel>
        <InputWithLabel
          id={"deviceIMSI"}
          name={"deviceIMSI"}
          labelLngId={"IMSI"}
          labelSize={"text-extraSmall"}
          labelColor={"text-textSecondary"}
          handleChange={handleChange}
          value={formData.serialNumber}
        ></InputWithLabel>
        <InputWithLabel
          id={"deviceICCID"}
          name={"deviceICCID"}
          labelLngId={"ICCID"}
          labelSize={"text-extraSmall"}
          labelColor={"text-textSecondary"}
          handleChange={handleChange}
          value={formData.serialNumber}
        ></InputWithLabel>
        <InputWithLabel
          id={"deviceMSISDN"}
          name={"deviceMSISDN"}
          labelLngId={"MSISDN"}
          labelSize={"text-extraSmall"}
          labelColor={"text-textSecondary"}
          handleChange={handleChange}
          value={formData.serialNumber}
        ></InputWithLabel>

        <div className="w-full bg-white rounded-md h-[55px] flex flex-col py-1 px-3">
          <label
            htmlFor={"deviceType"}
            className={"text-extraSmall text-textSecondary"}
          >
            <FormattedMessage id="devices.inputLabel2"></FormattedMessage>
          </label>
          <SelectInput
            id="deviceType"
            name="deviceType"
            className=" outline-none text-createAssetInputText text-blackPrimary"
            options={options3}
            onChange={handleChange}
          ></SelectInput>
        </div>
      </div>
    </form>
  );
};

CreateNewDevice.propTypes = {
  formData: PropTypes.Object,
  setFormData: PropTypes.Func,
};

export default CreateNewDevice;
