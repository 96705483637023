/* eslint-disable no-unused-vars */
import React, { useState } from "react";

import { PropTypes } from "prop-types";

const SimpleToggle = ({ label, formData, handleChange, id }) => {
  return (
    <div className="flex items-center gap-2 py-3">
      <label style={{ fontSize: "14px" }}>{label}</label>

      {/* <div  className="bg-black"> */}
      <div
        id={id}
        onClick={handleChange}
        className="w-7 h-[10px] rounded-lg flex justify-start items-center cursor-pointer"
        style={{
          background: formData.methodsAndMessage[id]
            ? "rgba(40, 67, 135,0.85)"
            : "#9aa8b3",
          justifyContent: formData.methodsAndMessage[id]
            ? "flex-end"
            : "flex-start",
        }}
      >
        <div
          className={`w-4 h-4 border pointer-events-none ${
            formData.methodsAndMessage[id]
              ? "border-[#284387d9]"
              : "border-[#9AA8B3]"
          } rounded-full bg-white`}
        ></div>
      </div>
      {/* </div> */}
    </div>
  );
};

SimpleToggle.propTypes = {
  label: PropTypes.string,
  id: PropTypes.string,
  //   data: PropTypes.string,
  //   value: PropTypes.string,
  formData: PropTypes.Object,
  handleChange: PropTypes.func,
};

export default SimpleToggle;
