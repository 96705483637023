import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

const Info = ({ icon, labelLngId, value }) => {
  return (
    <div className="w-full max-w-[250px] flex gap-5 items-center py-3 ">
      <img src={icon} alt="details about drivers"></img>

      <div className="flex flex-col">
        <span className="text-text16 text-textSecondary text-nowrap">
          <FormattedMessage id={labelLngId}></FormattedMessage>
        </span>
        <span>{value}</span>
      </div>
    </div>
  );
};

Info.propTypes = {
  icon: PropTypes.string,
  labelLngId: PropTypes.string,
  value: PropTypes.string,
};

export default Info;
