import dayjs from "dayjs";
import { useSelector } from "react-redux";

const useDayClassHook = (day) => {
  const daySelected = useSelector((state) => state.calendar.daySelected);

  const getCurrentDayClass = () => {
    const currentDayFormatted = day?.format("DD-MM-YY");
    const selectedDayFormatted = dayjs(daySelected).format("DD-MM-YY");

    if (currentDayFormatted === dayjs().format("DD-MM-YY")) {
      return "bg-redPrimary text-white rounded-full size-7";
    } else if (currentDayFormatted === selectedDayFormatted) {
      return "bg-red-400 text-white font-bold rounded-full size-7";
    } else {
      return "size-7";
    }
  };

  return getCurrentDayClass();
};

export default useDayClassHook;
