import React from "react";
import { PropTypes } from "prop-types";

import { FormattedMessage } from "react-intl";

import arrowIconBackward from "../../assets/icons/Panel/arrow_backward-red.svg";

const PageTitle = ({
  titleLngId,
  subTitleLngId,
  subTitle2LngId,
  state,
  setState,
}) => {
  return (
    <div className="my-3">
      <div className="flex items-center gap-6">
        {(state === "create" ||
          state === "view" ||
          state === "edit" ||
          state === true) && (
          <span
            onClick={setState}
            className=" cursor-pointer active:opacity-75 size-3 rounded-full"
          >
            <img src={arrowIconBackward} width={8}></img>
          </span>
        )}
        <div>
          <div className="pageSubtitle flex gap-2">
            <span>
              <FormattedMessage id={subTitleLngId}></FormattedMessage>
            </span>
            {(subTitle2LngId || state === true) && ">"}
            {(subTitle2LngId || state === true) && (
              <span>
                <FormattedMessage id={subTitle2LngId}></FormattedMessage>
              </span>
            )}
          </div>
          <h1 className="pageTitle">
            <FormattedMessage id={titleLngId}></FormattedMessage>
          </h1>
        </div>
      </div>
    </div>
  );
};

PageTitle.propTypes = {
  titleLngId: PropTypes.string,
  subTitleLngId: PropTypes.string,
  subTitle2LngId: PropTypes.string,
  state: PropTypes.bool,
  setState: PropTypes.func,
};

export default PageTitle;
