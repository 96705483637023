/*
Version 1 : simple table with 3 dots for : View, Edit, Delete. Ex: Assets, Asset Groups, Devices,Notifications.
Version 2 : table with selectable option and 3 dots for view, edit,add to group, move to role,
            assign assets, reset password, delete. Ex: UserGroup
Version 3 : simple table with a colorful status. Ex: Raports
Version 4 : simple table with checkbox. Ex: CreateNewRaport
*/

/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { PropTypes } from "prop-types";

import DotsWindow from "./DotsWindow";
import { FormattedMessage } from "react-intl";
import Checkbox from "../../../../components/Inputs/Checkbox";

import dotsIcon from "../../../../assets/icons/Panel/administration/TableIcons/dotsIcon.svg";
import mailIcon from "../../../../assets/icons/Panel/administration/TableIcons/mailIcon.svg";
import phoneIcon from "../../../../assets/icons/Panel/administration/TableIcons/phoneIcon.svg";
import excelIcon from "../../../../assets/icons/Panel/Raports/excelIcon.svg";
import pdfIcon from "../../../../assets/icons/Panel/Raports/pdfIcon.svg";
import arrowForwardIcon from "../../../../assets/icons/Panel/arrow_forward_icon_gray.svg";
import arrowBackwardIcon from "../../../../assets/icons/Panel/arrow_backward_icon_gray.svg";

const Table = ({
  headerData,
  bodyData,
  setState,
  setRowData,
  version,
  setSelectedRows,
  selectedRows,
  isChecked,
  setIsChecked,
  shadow,
}) => {
  const [rowIndex, setRowIndex] = useState(null);

  const openWindow = (index) => {
    if (index === rowIndex) {
      setRowIndex(null);
    } else {
      setRowIndex(index);
    }
  };

  const handleCheckboxChange = (item) => {
    const { name } = event.target;

    if (name === "all") {
      if (isChecked.all) {
        setIsChecked({
          all: false,
        });

        setSelectedRows([]);
      } else {
        setIsChecked({ all: true });

        setSelectedRows([...bodyData]);
      }
    } else {
      if (isChecked.all === true) {
        setIsChecked({
          all: false,
          [item.id]: true,
        });

        const filtered = bodyData.filter((element) => element.id === item.id);

        setSelectedRows([...filtered]);
      } else {
        if (isChecked[item.id] === true) {
          setIsChecked((prevValues) => ({
            ...prevValues,
            [item.id]: false,
          }));

          const filtered = selectedRows.filter(
            (element) => element.id !== item.id
          );

          setSelectedRows([...filtered]);
        } else {
          setIsChecked((prevValues) => ({
            ...prevValues,
            [item.id]: true,
          }));

          setSelectedRows((prevValues) => [...prevValues, item]);
        }
      }
    }
  };

  return (
    <div>
      <div className={`table-container ${!shadow ? "shadow-md" : "shadow-sm"}`}>
        <table className={`${version !== 4 && "table-auto"}`}>
          <thead className="assets-table-head ">
            <tr>
              {version === 2 && (
                <th>
                  <Checkbox
                    name={"all"}
                    id={"all"}
                    handleChange={() => handleCheckboxChange("all")}
                    checked={isChecked.all === true}
                    disabled={bodyData?.length > 0 ? false : true}
                  ></Checkbox>
                </th>
              )}
              {headerData.map((item, index) => (
                <th
                  scope="col"
                  key={item.id}
                  style={{
                    paddingLeft: version === 4 && index === 0 && "70px",
                  }}
                >
                  {item.title}
                </th>
              ))}
            </tr>
          </thead>

          <tbody className="table-body">
            {bodyData && bodyData.length > 0 ? (
              bodyData.map((item) => (
                <tr
                  key={item.id}
                  style={{
                    backgroundColor:
                      (version === 2 || version === 4) &&
                      (isChecked[item.id] === true || isChecked.all === true) &&
                      "rgba(0,0,0,0.01)",
                  }}
                >
                  {version === 2 && (
                    <td>
                      <Checkbox
                        id={"specific"}
                        name={"specific"}
                        handleChange={() => handleCheckboxChange(item)}
                        checked={
                          isChecked.all === true || isChecked[item.id] === true
                        }
                      ></Checkbox>
                    </td>
                  )}
                  {(version === 1 || version === 2) && (
                    <td>
                      <span
                        onClick={() => openWindow(item.id)}
                        className="img-container"
                      >
                        <img src={dotsIcon}></img>
                      </span>
                      {item.id === rowIndex && (
                        <DotsWindow
                          setState={setState}
                          setRowData={setRowData}
                          data={item.data}
                          version={version}
                        ></DotsWindow>
                      )}
                    </td>
                  )}
                  {item.data.map((element, index) => (
                    <td
                      key={element.id}
                      style={{
                        fontWeight: element.id === 0 && "bold",
                        color: element.id === 1 && "#9AA8B3",
                      }}
                    >
                      <div
                        style={{
                          display: version === 4 && "flex",
                          gap: version === 4 && "30px",
                          alignItems: version === 4 && "center",
                          width:
                            version === 3 &&
                            element.row === "Ready" &&
                            "fit-content",
                          padding:
                            version === 3 &&
                            element.row === "Ready" &&
                            "5px 10px",
                          backgroundColor:
                            version === 3 &&
                            element.row === "Ready" &&
                            "#66CE66",
                          color:
                            version === 3 && element.row === "Ready" && "white",
                          borderRadius:
                            version === 3 && element.row === "Ready" && "15px",
                        }}
                      >
                        {index === 0 && version === 4 && (
                          <Checkbox
                            id={"specific"}
                            name={"specific"}
                            handleChange={() => handleCheckboxChange(item)}
                            checked={
                              isChecked.all === true ||
                              isChecked[item.id] === true
                            }
                          ></Checkbox>
                        )}
                        {element.row}
                      </div>
                    </td>
                  ))}

                  {(version === 3 || version === 2) && (
                    <td className=" flex items-center gap-2">
                      <img
                        src={version === 2 ? mailIcon : excelIcon}
                        className="active:opacity-70 cursor-pointer"
                      ></img>
                      <img
                        src={version === 2 ? phoneIcon : pdfIcon}
                        className="active:opacity-70 cursor-pointer"
                      ></img>
                    </td>
                  )}
                </tr>
              ))
            ) : (
              <tr>
                <td
                  colSpan={
                    version === 2 ? headerData.length + 1 : headerData.length
                  }
                >
                  <FormattedMessage id="table.noResultText"></FormattedMessage>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

Table.propTypes = {
  headerData: PropTypes.Object,
  bodyData: PropTypes.Object,
  setState: PropTypes.Func,
  setRowData: PropTypes.Func,
  setSelectedRows: PropTypes.Func,
  selectedRows: PropTypes.Object,
  version: PropTypes.number,
  isChecked: PropTypes.Object,
  setIsChecked: PropTypes.Func,
  shadow: PropTypes.bool,
};

export default Table;
