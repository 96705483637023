/* eslint-disable no-unused-vars */
import React, { useState } from "react";

import SelectInput from "../Inputs/SelectInput";
import TextInput from "../Inputs/TextInput";
import Info from "./streched-card-elements/Info";

import { FormattedMessage } from "react-intl";

import { options4 } from "../../constants";

import drivingTimeIcon from "../../assets/icons/Panel/Card/drivingTimeIcon.svg";
import standingTimeIcon from "../../assets/icons/Panel/Card/standingTimeIcon.svg";
import avarageSpeedIcon from "../../assets/icons/Panel/Card/avarageSpeedIcon.svg";
import dayjs from "dayjs";

const StrechedCard = () => {
  const [formData, setFormData] = useState({
    selectedPeriod: "Today",
    searchValue: "",
  });

  const handleChange = (event) => {
    const { id, value } = event.target;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [id]: value,
    }));
  };

  return (
    <div className="flex flex-col gap-2 md:gap-4 w-full max-w-[1740px] min-w-[350px] bg-white min-h-[190px] p-4 text-blackPrimary text-regularSize">
      <span className="xl:hidden text-textSecondary">
        {/* 13 August 2024 00:00 - 13 August 2024 14:35 */}
        {dayjs().format("D MMMM YYYY HH:mm")}
      </span>

      <div className="flex justify-between items-center">
        <div className=" flex flex-wrap gap-2 lg:gap-6 items-center">
          <div className="w-[200px] border rounded-md border-[rgba(0,0,0,0.1)] pr-3 overflow-hidden">
            <SelectInput
              id="selectedPeriod"
              className=" outline-none text-regularSize w-full p-3 "
              options={options4}
              onChange={handleChange}
            ></SelectInput>
          </div>

          <TextInput
            inputClassName={
              "w-full min-w-[300px] sm:min-w-[380px] h-[45px] p-3 text-regularSize border border-[rgba(0,0,0,0.1)] rounded-md"
            }
            placeholderLngId={"strechedCard.searchPlaceholder"}
            inputId={"searchValue"}
            formData={formData.searchValue}
            handleChange={handleChange}
            marginHorizontal={""}
            labelStyle={""}
            labelLngId={""}
            icon={""}
          ></TextInput>

          <span>
            <FormattedMessage id="strechedCard.selected"></FormattedMessage>
          </span>
        </div>

        <span className="hidden xl:flex">
          {/* 13 August 2024 00:00 - 13 August 2024 14:35 */}
          {dayjs().format("D MMMM YYYY HH:mm")}
        </span>
      </div>

      <div className=" flex flex-wrap justify-between items-center">
        {/* lg:divide-x divide-solid */}
        <Info
          labelLngId={"strechedCard.drivingTime"}
          value={"1h 30m"}
          icon={drivingTimeIcon}
        ></Info>
        <div className="hidden xl:flex h-full w-[2px] bg-[#F4F4F4]"></div>
        <Info
          labelLngId={"strechedCard.standingTime"}
          value={"2d 9h 16m"}
          icon={standingTimeIcon}
        ></Info>

        <div className="hidden xl:flex  h-full w-[2px] bg-[#F4F4F4]"></div>
        <Info
          labelLngId={"strechedCard.averageSpeed"}
          value={"41km/h"}
          icon={avarageSpeedIcon}
        ></Info>

        <div className="hidden xl:flex  h-full w-[2px] bg-[#F4F4F4]"></div>
        <Info
          labelLngId={"strechedCard.maxSpeed"}
          value={"1h 30m"}
          icon={drivingTimeIcon}
        ></Info>
      </div>
    </div>
  );
};

export default StrechedCard;
