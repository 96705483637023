import React from "react";
import PropTypes from "prop-types";

const ReusableBtn = ({
  icon,
  gap,
  width,
  maxWidth,
  height,
  text,
  textSize,
  textColor,
  background,
  type,
  paddingHorizontal,
  marginHorizontal,
  margin,
  border,
  borderRadius,
  handleClick,
  rotate,
  imgWidth,
  version,
}) => {
  return (
    <button
      style={{
        width,
        maxWidth,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap,
        height,
        margin,
        border,
        borderRadius: borderRadius ? borderRadius : "6px",
      }}
      type={type ? type : "button"}
      onClick={handleClick}
      className={`hover:opacity-90 active:opacity-80 ${background} ${paddingHorizontal} ${marginHorizontal}`}
    >
      {icon && version !== 2 && (
        <img
          src={icon}
          width={imgWidth}
          style={{ transform: rotate && `rotate(${rotate})` }}
        ></img>
      )}
      {text && <h3 className={`${textColor} ${textSize}`}>{text}</h3>}

      {icon && version === 2 && (
        <img
          src={icon}
          width={imgWidth}
          style={{ transform: rotate && `rotate(${rotate})` }}
        ></img>
      )}
    </button>
  );
};

ReusableBtn.propTypes = {
  icon: PropTypes.string,
  gap: PropTypes.string,
  width: PropTypes.string,
  maxWidth: PropTypes.string,
  height: PropTypes.string,
  text: PropTypes.string,
  textSize: PropTypes.string,
  textColor: PropTypes.string,
  background: PropTypes.string,
  type: PropTypes.string,
  paddingHorizontal: PropTypes.string,
  marginHorizontal: PropTypes.string,
  margin: PropTypes.string,
  border: PropTypes.string,
  borderRadius: PropTypes.string,
  handleClick: PropTypes.func,
  rotate: PropTypes.string,
  imgWidth: PropTypes.string,
  version: PropTypes.number,
};

export default ReusableBtn;
