/* eslint-disable indent */
/* eslint-disable no-nested-ternary */
/* eslint-disable complexity */
/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";

import Table from "../Components/table/Table";
import ReusableBtn from "../../../components/ReusableBtn/ReusableBtn";
import PageTitle from "../../../components/Layout/PageTitle";
import ViewAsset from "../Components/ViewAsset/ViewAsset";

import { DevicesTable, DeviceDetailTitles } from "../../../constants";

import plusIcon from "../../../assets/icons/Panel/administration/Assets/plus-icon.svg";
import fileIcon from "../../../assets/icons/Panel/administration/Assets/file-icon.svg";
import funnelIcon from "../../../assets/icons/Panel/administration/Assets/funnel-icon.svg";
import discardIcon from "../../../assets/icons/Panel/administration/Assets/discardIcon.svg";
import editIconWhite from "../../../assets/icons/Panel/administration/AssetGroup/editIconWhite.svg";
import CreateNewDevice from "./Components/CreateNewDevice";

const Devices = () => {
  // const [createAsset, setCreateAsset] = useState(false);
  const [view, setView] = useState("table");
  const [formData, setFormData] = useState({
    deviceSerial: "",
    deviceIMEI: "",
    deviceIMSI: "",
    deviceICCID: "",
    deviceMSISDN: "",
    deviceType: "",
  });

  // eslint-disable-next-line no-unused-vars
  const [rowData, setRowData] = useState(null);

  // console.log("This is rowData:", rowData);

  const bodyData = [
    {
      id: 0,
      data: [
        {
          id: 0,
          row: "350424063920159",
        },
        {
          id: 1,
          row: "350424063920159",
        },
        {
          id: 2,
          row: "35569707072800",
        },
        {
          id: 3,
          row: "35569707072800",
        },
        {
          id: 4,
          row: "35569707072800",
        },
        {
          id: 5,
          row: "1",
        },
        {
          id: 6,
          row: "FMB130",
        },
        {
          id: 7,
          row: "(ALB)FMB!#) NO...",
        },
        {
          id: 8,
          row: "03/05/24  16:01",
        },
        {
          id: 9,
          row: "2 Years ago",
        },
      ],
    },
  ];

  const onSubmit = () => {
    console.log("this is formData:", formData);
  };

  return (
    <div className="pageLayout">
      <div className="w-full flex flex-col gap-2 lg:gap-0 lg:flex-row lg:justify-between lg:items-center">
        <PageTitle
          titleLngId={
            view === "create"
              ? "devices.createNewDeviceTitle"
              : view === "view"
              ? "devices.viewDeviceDetails"
              : view === "edit"
              ? "devices.devicesDetailsEdit"
              : "devices.pageTitle"
            // titleLngId
          }
          subTitleLngId={"devices.subtitle1"}
          subTitle2LngId={"devices.subtitle2"}
          state={view}
          setState={() => setView("table")}
        ></PageTitle>

        <div className=" flex items-center gap-2 mb-4 lg:mb-0">
          {view === "create" && (
            <ReusableBtn
              icon={discardIcon}
              text={
                <FormattedMessage id="assets.createAssetBtn1"></FormattedMessage>
              }
              textSize={"text-filterBtn"}
              textColor={"text-[#0D0D0D]"}
              width={"100px"}
              maxWidth={""}
              height={"40px"}
              background={"bg-transparent"}
              gap={7}
              paddingHorizontal={"px-1"}
              type={"button"}
              border={"1px solid #0D0D0D"}
              handleClick={() => setView("table")}
            ></ReusableBtn>
          )}

          <ReusableBtn
            icon={
              view === "create"
                ? ""
                : view === "view"
                ? editIconWhite
                : plusIcon
            }
            imgWidth={"14px"}
            text={
              <FormattedMessage
                id={
                  view === "create"
                    ? "devices.btn2"
                    : view === "table"
                    ? "devices.btn1"
                    : "devices.devicesEditBtn"
                }
              ></FormattedMessage>
            }
            textSize={"text-filterBtn"}
            textColor={"text-[#FFFFFF]"}
            width={"fit-content"}
            maxWidth={""}
            height={view === "create" ? "40px" : "45px"}
            background={"bg-redPrimary"}
            gap={10}
            paddingHorizontal={"px-4"}
            type={"button"}
            handleClick={
              view === "create"
                ? onSubmit
                : view === "view"
                ? () => console.log("edit page")
                : () => setView("create")
            }
          ></ReusableBtn>

          {view === "table" && (
            <ReusableBtn
              icon={fileIcon}
              height={"45px"}
              background={"bg-white"}
              paddingHorizontal={"px-3"}
              type={"button"}
              handleClick={() => console.log("")}
            ></ReusableBtn>
          )}

          {view === "table" && (
            <ReusableBtn
              icon={funnelIcon}
              text={"(81)"}
              textColor={"text-textSecondary"}
              gap={4}
              height={"45px"}
              background={"bg-white"}
              paddingHorizontal={"px-3"}
              type={"button"}
              handleClick={() => console.log("")}
            ></ReusableBtn>
          )}
        </div>
      </div>

      {view === "create" && (
        <CreateNewDevice
          formData={formData}
          setFormData={setFormData}
        ></CreateNewDevice>
      )}

      {view === "view" && (
        <ViewAsset
          titleLngId={"devices.devicesDetailsText"}
          titles={DeviceDetailTitles}
          data={rowData}
        ></ViewAsset>
      )}

      {view === "edit" && <div>Edit</div>}

      {view === "table" && (
        <Table
          version={1}
          headerData={DevicesTable}
          bodyData={bodyData}
          setState={setView}
          setRowData={setRowData}
        ></Table>
      )}
    </div>
  );
};

export default Devices;
