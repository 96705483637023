import React, { useEffect } from "react";
import { PropTypes } from "prop-types";

import { FormattedMessage } from "react-intl";

import { options1, options2 } from "../../../../constants";

import InputWithLabel from "../../../../components/Inputs/InputWithLabel";
import SelectInput from "../../../../components/Inputs/SelectInput";

import crownIcon from "../../../../assets/icons/Panel/administration/Assets/crownIcon.svg";

const CreateAsset = ({ formData, setFormData }) => {
  useEffect(() => {
    return () => {
      setFormData({
        assetName: "",
        serialNumber: "",
        assetStatus: "",
        assetGroup: "default",
        assetType: "",
      });
    };
  }, []);

  const handleChange = (event) => {
    const { id, name } = event.target;

    if (
      name === "assetName" ||
      name === "serialNumber" ||
      name === "assetStatus" ||
      name === "assetType"
    ) {
      setFormData((prevValues) => ({
        ...prevValues,
        [name]: event.target.value,
      }));
    }
    if (
      id === "default" ||
      id === "berat" ||
      id === "fier" ||
      id === "hellas-gramsh" ||
      id === "hellas-korce"
    ) {
      setFormData((prevValues) => ({
        ...prevValues,
        assetGroup: id,
      }));
    }
  };

  return (
    <form id="createAssetForm" className="formContainer">
      <div className="groupContainer">
        <h1 className=" font-bold text-inputsGroupTitle text-pageTitle">
          <FormattedMessage id="assets.group1Text"></FormattedMessage>
        </h1>
        <InputWithLabel
          id={"assetName"}
          name={"assetName"}
          labelLngId={"assets.inputLabel1"}
          labelSize={"text-extraSmall"}
          labelColor={"text-textSecondary"}
          handleChange={handleChange}
          value={formData.assetName}
        ></InputWithLabel>
        <InputWithLabel
          id={"serialNumber"}
          name={"serialNumber"}
          labelLngId={"assets.inputLabel2"}
          labelSize={"text-extraSmall"}
          labelColor={"text-textSecondary"}
          handleChange={handleChange}
          value={formData.serialNumber}
        ></InputWithLabel>
        <div className="w-full bg-white rounded-md h-[55px] flex flex-col py-1 px-3">
          <label
            htmlFor={"assetStatus"}
            className={"text-extraSmall text-textSecondary"}
          >
            <FormattedMessage id="assets.inputLabel3"></FormattedMessage>
          </label>
          <SelectInput
            id="assetStatus"
            name="assetStatus"
            className=" outline-none text-createAssetInputText text-blackPrimary"
            options={options1}
            onChange={handleChange}
          ></SelectInput>
        </div>
      </div>

      <div className="groupContainer">
        <h1 className=" font-bold text-inputsGroupTitle text-pageTitle">
          <FormattedMessage id="assets.group2Text"></FormattedMessage>
        </h1>

        <div className="p-3 bg-white rounded-md flex flex-col gap-3">
          <div className="assetGroupFields" onClick={handleChange} id="default">
            <div
              className="customRadioContainer"
              style={{
                borderColor:
                  formData.assetGroup === "default" ? "#007C92" : "#ACACAC",
              }}
            >
              {formData.assetGroup === "default" && <div></div>}
            </div>
            <div className="flex flex-col space-y-2">
              <label
                htmlFor={"assetStatus"}
                className={
                  "text-extraSmall text-textSecondary flex items-center gap-2"
                }
              >
                <img src={crownIcon} className=" size-4"></img>
                <span>
                  <FormattedMessage id="assets.inputLabel4"></FormattedMessage>
                </span>
                <span>
                  <FormattedMessage id="assets.inputLabel5"></FormattedMessage>
                </span>
              </label>
              <span>
                <FormattedMessage id="assets.inputSelectText"></FormattedMessage>
              </span>
            </div>
          </div>

          <div className="assetGroupFields" onClick={handleChange} id="berat">
            <div
              className="customRadioContainer"
              style={{
                borderColor:
                  formData.assetGroup === "berat" ? "#007C92" : "#ACACAC",
              }}
            >
              {formData.assetGroup === "berat" && <div></div>}
            </div>
            <div className="flex flex-col space-y-2">
              <label
                htmlFor={"assetStatus"}
                className={
                  "text-extraSmall text-textSecondary flex items-center gap-2"
                }
              >
                Techno-AL - Berat
                <span>
                  <FormattedMessage id="assets.inputLabel5"></FormattedMessage>
                </span>
              </label>
              <span>
                <FormattedMessage id="assets.inputSelectText"></FormattedMessage>
              </span>
            </div>
          </div>

          <div className="assetGroupFields" onClick={handleChange} id="fier">
            <div
              className="customRadioContainer"
              style={{
                borderColor:
                  formData.assetGroup === "fier" ? "#007C92" : "#ACACAC",
              }}
            >
              {formData.assetGroup === "fier" && <div></div>}
            </div>
            <div className="flex flex-col space-y-2">
              <label
                htmlFor={"assetStatus"}
                className={
                  "text-extraSmall text-textSecondary flex items-center gap-2"
                }
              >
                TechNet - Fier
                <span>
                  <FormattedMessage id="assets.inputLabel5"></FormattedMessage>
                </span>
              </label>
              <span>
                <FormattedMessage id="assets.inputSelectText"></FormattedMessage>
              </span>
            </div>
          </div>

          <div className="p-3 flex flex-col gap-4">
            <label
              htmlFor={"assetStatus"}
              className={
                "text-extraSmall text-textSecondary flex items-center gap-2"
              }
            >
              Hellas
              <span>
                <FormattedMessage id="assets.inputLabel5"></FormattedMessage>
              </span>
            </label>

            <div
              className="flex items-center gap-6 cursor-pointer"
              onClick={handleChange}
              id="hellas-gramsh"
            >
              <div
                className="customRadioContainer"
                style={{
                  borderColor:
                    formData.assetGroup === "hellas-gramsh"
                      ? "#007C92"
                      : "#ACACAC",
                }}
              >
                {formData.assetGroup === "hellas-gramsh" && <div></div>}
              </div>
              <div className="flex justify-between items-center text-createAssetInputText text-blackPrimary">
                <span>Gramsh - Instalime</span>
              </div>
            </div>

            <div
              id="hellas-korce"
              className="flex items-center gap-6 cursor-pointer"
              onClick={handleChange}
            >
              <div
                className="customRadioContainer"
                style={{
                  borderColor:
                    formData.assetGroup === "hellas-korce"
                      ? "#007C92"
                      : "#ACACAC",
                }}
              >
                {formData.assetGroup === "hellas-korce" && <div></div>}
              </div>
              <span>Korce - Instalime</span>
            </div>
          </div>
        </div>
      </div>

      <div className="groupContainer">
        <h1 className=" font-bold text-inputsGroupTitle text-pageTitle">
          <FormattedMessage id="assets.group3Text"></FormattedMessage>
        </h1>
        <div className="w-full bg-white rounded-md h-[50px] flex flex-col py-1 px-3">
          <label
            htmlFor={"assetStatus"}
            className={"text-extraSmall text-textSecondary"}
          >
            <FormattedMessage id="assets.group3Text"></FormattedMessage>
          </label>
          <SelectInput
            id="assetType"
            name="assetType"
            type="select"
            className=" outline-none text-createAssetInputText text-blackPrimary"
            onChange={handleChange}
            optgroup={"Vehicle"}
            options={options2}
          ></SelectInput>
        </div>
      </div>
    </form>
  );
};

CreateAsset.propTypes = {
  formData: PropTypes.Object,
  setFormData: PropTypes.Func,
};

export default CreateAsset;
