import React from "react";
import { PropTypes } from "prop-types";

const SelectInput = ({ id, name, className, options, optgroup, onChange }) => {
  return (
    <select
      id={id}
      name={name}
      type="select"
      className={className}
      onChange={onChange}
    >
      {optgroup ? (
        <optgroup label={optgroup} aria-placeholder={optgroup}>
          {options.map((item) => (
            <option
              key={item.label}
              value={item.value}
              disabled={item.disabled}
              selected={item.selected}
            >
              {item.label}
            </option>
          ))}
        </optgroup>
      ) : (
        options.map((item) => (
          <option
            key={item.label}
            value={item.value}
            disabled={item.disabled}
            selected={item.selected}
          >
            {item.label}
          </option>
        ))
      )}
    </select>
  );
};

SelectInput.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  className: PropTypes.string,
  options: PropTypes.Object,
  optgroup: PropTypes.string,
  onChange: PropTypes.func,
};

export default SelectInput;
