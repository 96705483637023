import React, { useEffect } from "react";
import { PropTypes } from "prop-types";
import { useMap } from "@vis.gl/react-google-maps";

const MapHandler = ({ place }) => {
  const map = useMap();

  useEffect(() => {
    if (!map || !place) return;

    if (place.geometry?.viewport) {
      map.panTo(place.geometry?.location);

      setTimeout(() => {
        map.setZoom(15);
      }, [1000]);

      //   map.fitBounds(place.geometry?.viewport);
    }
  }, [map, place]);

  return null;
};

MapHandler.propTypes = {
  place: PropTypes.Object,
};

export default React.memo(MapHandler);
