/* eslint-disable no-unused-vars */
import React, { useState } from "react";

import { PropTypes } from "prop-types";

const AssetGroupToggle = ({ icon, data, value, label }) => {
  const [toggle, setToggle] = useState(false);

  const handleToggleChange = () => {
    setToggle((prevValues) => !prevValues);
  };

  return (
    <div className="flex items-center gap-3 py-3 border-b-[1px] border-[#EAF1F4]">
      <div
        // id={toggleId}
        name={name}
        className="w-7 h-[10px] rounded-lg flex justify-start items-center cursor-pointer"
        style={{
          background: toggle ? "rgba(40, 67, 135,0.85)" : "#9aa8b3",
          justifyContent: toggle ? "flex-end" : "flex-start",
        }}
        onClick={handleToggleChange}
      >
        {/* <div className=" w-4 h-4 border border-[#9AA8B3] rounded-full bg-white"></div> */}

        <div
          className={`w-4 h-4 border ${
            toggle ? "border-[#284387d9]" : "border-[#9AA8B3]"
          } rounded-full bg-white`}
        ></div>
      </div>

      <div className="flex items-center gap-2">
        {icon && <img src={icon} alt="default-crown-icon"></img>}
        <label
          //   htmlFor={toggleId}
          style={{ color: "#9AA8B3", fontSize: "15px" }}
        >
          {label}
        </label>
      </div>
    </div>
  );
};

AssetGroupToggle.propTypes = {
  label: PropTypes.string,
  icon: PropTypes.string,
  data: PropTypes.string,
  value: PropTypes.string,
};

export default AssetGroupToggle;
