/* eslint-disable no-unused-vars */
import React from "react";
import { PropTypes } from "prop-types";

import { FormattedMessage } from "react-intl";

import checkIcon from "../../../assets/icons/Panel/Raports/checkMark.svg";

const RaportGroup = ({
  children,
  readyToGo,
  raportGroupIndex,
  index,
  contentTitleLngId,
}) => {
  return (
    <div className="raportGroupContainer">
      <div className="raportsGroupHeader">
        <div
          className="flex items-center justify-center w-5 h-5 rounded-full bg-[#E60000]"
          //   style={{ opacity: selectedElementIndex ? "" : "50%" }}
          style={{ opacity: raportGroupIndex === index ? "" : "50%" }}
        >
          {readyToGo && <img src={checkIcon}></img>}
        </div>
        <h3 className=" text-text16 text-darkGray3">
          <FormattedMessage id={contentTitleLngId}></FormattedMessage>
        </h3>
      </div>

      <div
        className="inside-container "
        style={{
          height: raportGroupIndex === index ? "fit-content" : 0,
          padding: raportGroupIndex === index && "30px 0",
          transition: "height padding",
          transitionDuration: "0.3s",
          transitionTimingFunction: "ease-in-out",
        }}
      >
        {children}
      </div>
    </div>
  );
};

RaportGroup.propTypes = {
  children: PropTypes.children,
  raportGroupIndex: PropTypes.number,
  index: PropTypes.number,
  contentTitleLngId: PropTypes.string,
  readyToGo: PropTypes.bool,
};

export default RaportGroup;
