/* eslint-disable indent */
/* eslint-disable no-unused-vars */
import React from "react";
import PropTypes from "prop-types";

import { ResponsiveContainer, Cell, Pie, PieChart, Tooltip } from "recharts";

function PieChartBox({ data, version }) {
  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 1.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <g>
        <circle
          cx={x}
          cy={y}
          r={18}
          fill="white"
          stroke="rgba(0,0,0,0.1)"
          strokeWidth={1}
        />
        <text
          x={x}
          y={y}
          fill="black"
          textAnchor="middle"
          dominantBaseline="central"
          fontSize={12}
          fontWeight={"bold"}
        >
          {`${(percent * 100).toFixed(0)}%`}
        </text>
      </g>
    );
  };

  return (
    <ResponsiveContainer width="99%" height={260} className="bg-transparent">
      {version === 1 && <div className="circle"></div>}
      <PieChart className="recharts-layer">
        <Pie
          data={data}
          innerRadius={version === 1 ? "50%" : "45%"}
          outerRadius={version === 1 ? "70%" : "85%"}
          fill="#8884d8"
          paddingAngle={0}
          dataKey="value"
          label={version === 1 && renderCustomizedLabel}
          legendType="circle"
        >
          {data?.map((item) => (
            <Cell
              key={item?.name}
              fill={item?.color}
              style={{ outline: "none" }}
            />
          ))}
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  );
}

PieChartBox.propTypes = {
  data: PropTypes.object,
  version: PropTypes.number,
};

export default PieChartBox;
