import React from "react";
import PropTypes from "prop-types";

import { FormattedMessage } from "react-intl";

import eyeIcon from "../../../../assets/icons/Panel/administration/DotWindow/eyeIcon.svg";
import editIcon from "../../../../assets/icons/Panel/administration/DotWindow/editIcon.svg";
import deleteIcon from "../../../../assets/icons/Panel/administration/DotWindow/deleteIcon.svg";
import addToGroupIcon from "../../../../assets/icons/Panel/administration/DotWindow/addToGroupIcon.svg";
import moveToRoleIcon from "../../../../assets/icons/Panel/administration/DotWindow/moveToRoleIcon.svg";
import assignAssetsIcon from "../../../../assets/icons/Panel/administration/DotWindow/assignAssetsIcon.svg";
import resetPasswordIcon from "../../../../assets/icons/Panel/administration/DotWindow/resetPasswordIcon.svg";

// eslint-disable-next-line no-unused-vars
const DotsWindow = ({ setState, setRowData, data, version, top, left }) => {
  const handleClick = (value) => {
    if (value === "view") {
      setRowData(data);

      setState(value);
    }
  };

  return (
    <div className="dotsWindow-container" style={{ top, left }}>
      {version !== 3 && (
        <div onClick={() => handleClick("view")}>
          <span>
            <img src={eyeIcon}></img>
          </span>
          <span>
            <FormattedMessage id="tableDotsWindow.text1"></FormattedMessage>
          </span>
        </div>
      )}

      {version !== 3 && (
        <div onClick={() => handleClick("edit")}>
          <span>
            <img src={editIcon}></img>
          </span>
          <span>
            <FormattedMessage id="tableDotsWindow.text2"></FormattedMessage>
          </span>
        </div>
      )}

      {(version === 2 || version === 3) && (
        <div onClick={() => handleClick("addToGroup")}>
          <span>
            <img src={addToGroupIcon}></img>
          </span>
          <span>
            <FormattedMessage id="tableDotsWindow.text3"></FormattedMessage>
          </span>
        </div>
      )}

      {(version === 2 || version === 3) && (
        <div onClick={() => handleClick("moveToRole")}>
          <span>
            <img src={moveToRoleIcon}></img>
          </span>
          <span>
            <FormattedMessage id="tableDotsWindow.text4"></FormattedMessage>
          </span>
        </div>
      )}

      {version === 2 && (
        <div onClick={() => handleClick("assignAssets")}>
          <span>
            <img src={assignAssetsIcon}></img>
          </span>
          <span>
            <FormattedMessage id="tableDotsWindow.text5"></FormattedMessage>
          </span>
        </div>
      )}

      {version === 2 && (
        <div onClick={() => handleClick("resetPassword")}>
          <span>
            <img src={resetPasswordIcon}></img>
          </span>
          <span>
            <FormattedMessage id="tableDotsWindow.text6"></FormattedMessage>
          </span>
        </div>
      )}

      <div onClick={() => handleClick("delete")}>
        <span>
          <img src={deleteIcon}></img>
        </span>
        <span>
          <FormattedMessage id="tableDotsWindow.text7"></FormattedMessage>
        </span>
      </div>
    </div>
  );
};

DotsWindow.propTypes = {
  setState: PropTypes.Func,
  setRowData: PropTypes.Func,
  data: PropTypes.object,
  version: PropTypes.number,
  top: PropTypes.number,
  left: PropTypes.number,
};

export default DotsWindow;
