import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Outlet, Navigate, useLocation } from "react-router-dom";
import TopBar from "../Navbar/TopBar";
import Menu from "../Menu/Menu";
import Logout from "../Logout/Logout";

const DashboardLayout = () => {
  const { pathname } = useLocation();

  const { currentUser } = useSelector((state) => state.user);
  const [openSideMenu, setOpenSideMenu] = useState(false);
  const [showLogoutPopup, setShowLogoutPopup] = useState(false);

  const toggleLogout = () => {
    setShowLogoutPopup((prevValue) => !prevValue);
  };

  // Close the menu whenever the location changes
  useEffect(() => {
    setOpenSideMenu(false);
  }, [pathname]);

  return (
    <main className="w-full h-screen flex flex-col overflow-hidden ">
      <TopBar
        setOpenSideMenu={setOpenSideMenu}
        openSideMenu={openSideMenu}
      ></TopBar>

      <div className="w-full h-full max-h-[calc(100vh-60px)] flex relative">
        {showLogoutPopup && <Logout toggleLogout={toggleLogout}></Logout>}
        <Menu openSideMenu={openSideMenu} toggleLogout={toggleLogout}></Menu>
        {!currentUser ? <Outlet></Outlet> : <Navigate to={"/login"}></Navigate>}
      </div>
    </main>
  );
};

export default DashboardLayout;
